import { combineReducers, configureStore } from "@reduxjs/toolkit";
import throttleMiddleware from "./throttleMiddleware";

import { devicesReducer as devices } from "./devices";
import { driversReducer as drivers } from "./drivers";
import { errorsReducer as errors } from "./errors";
import { dataReducer as data } from "./data";
import { eventsReducer as events } from "./events";
import { filterReducer as filters } from "./filter";
import { selectorReducer as selectors } from "./selectors";
import { geofencesReducer as geofences } from "./geofences";
import { groupsReducer as groups } from "./groups";
import { sensorsReducer as sensors } from "./peripheralsensors";
import { maintenancesReducer as maintenances } from "./maintenances";
import { positionsReducer as positions } from "./positions";
import { sessionReducer as session } from "./session";
import { settingsReducer as settings } from "./settings";
import { reportsReducer as reports } from "../Pages/Reports/store/reports";
import { subscriptionReducer as subscriptions } from "./subscriptions";

const reducer = combineReducers({
  errors,
  session,
  devices,
  positions,
  subscriptions,
  events,
  geofences,
  groups,
  drivers,
  maintenances,
  filters,
  selectors,
  settings,
  reports,
  sensors,
  data,
});

export { devicesActions } from "./devices";
export { driversActions } from "./drivers";
export { errorsActions } from "./errors";
export { dataActions } from "./data";
export { eventsActions } from "./events";
export { filterActions } from "./filter";
export { selectorActions } from "./selectors";
export { geofencesActions } from "./geofences";
export { groupsActions } from "./groups";
export { maintenancesActions } from "./maintenances";
export { positionsActions } from "./positions";
export { sessionActions } from "./session";
export { settingsActions } from "./settings";
export { reportsActions } from "../Pages/Reports/store/reports";
export { sensorsActions } from "./peripheralsensors";
export { subscriptionActions } from "./subscriptions";

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(throttleMiddleware),
});
