const Web = {};

let socketRef;

async function handleResponse(response) {
  if (response.ok) {
    // If the response doesn't have any json, we'll return an empty
    // string so as not to fail the call after a status 200.
    try {
      return await response.json();
    } catch (e) {
      return "";
    }
  } else {
    throw Error(await response.text());
  }
}

Web.get = async (path) => {
  const response = await fetch(path, {
    timeout: 300000,
    headers: { Accept: "application/json" },
  });

  return await handleResponse(response);
};

Web.post = async (path, options, callback) => {
  const config = {
    headers: {
      Accept: "application/json"
    },
    method: "POST",
  };

  if (options && options.body) {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    config.body = options.body;
  }
  else if (options && options.data) {
    config.headers["Content-Type"] = "application/json";
    config.body = options.data? JSON.stringify(options.data) : "";

    // Override any headres and content. 
    if (options.headers) {
      for (const prop in options.headers) {
        config.headers[prop] = options.headers[prop];
        // If prop is of type "multipart form data"
        // then the config.body above will need to be reassigned using FormData
        if (prop === "Content-Type" 
          &&  options.headers[prop] === "multipart/form-data" 
          && options.data) {
          const formData = new FormData();
          formData.append("manualEventsFile", options.data);
          config.body = formData;
        }
      }
    }
  }

  const response = await fetch(path, config);

  if (callback) {
    return callback(response);
  }
  return await handleResponse(response);
};

Web.put = async (path, options) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PUT",
  };

  if (options && options.body) {
    config.body = JSON.stringify(options.body);
  }
  if (options && options.data) {
    config.body = JSON.stringify(options.data);
  }

  const response = await fetch(path, config);
  return await handleResponse(response);
};

Web.delete = async (path, options) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "DELETE",
  };

  if (options && options.body) {
    config.body = JSON.stringify(options.body);
  }
  if (options && options.data) {
    config.body = JSON.stringify(options.data);
  }

  const response = await fetch(path, config);
  return await handleResponse(response);
};

Web.Websocket = async (path, connectOptions) => {
  const socketProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
  let socketUrl = `${socketProtocol}//${window.location.host}${path}`;
  const socket = new WebSocket(socketUrl);

  if (connectOptions.onopen) {
    socket.onopen = connectOptions.onopen;
  }

  if (connectOptions.onerror) {
    socket.onerror = connectOptions.onerror;
  }

  if (connectOptions.onmessage) {
    socket.onmessage = connectOptions.onmessage;
  }

  socketRef = socket;
};

Web.WebsocketClose = async () => {
  if (socketRef) {
    socketRef.close();
  }
};

export default Web;
