import { t } from "i18next";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FeatureFlags from "../../../Components/FeatureFlags/FeatureFlags";
import RadioButton from "../../../common/components/RadioButton";
import useWindowSize from "../../../common/hooks/useWindowSize";
import { devicesActions, selectorActions } from "../../../store";

const Item = ({ onClick, text, checked, className }) => {
  const { pathname } = useLocation();

  return (
    <div
      onClick={onClick}
      className={`${className} flex justify-between my-4 cursor-pointer`}
    >
      <span>{text}</span>
      <RadioButton
        type="radio"
        checked={pathname.includes(checked)}
        readOnly={true}
      />
    </div>
  );
};

const SelectToViewSection = () => {
  const { width } = useWindowSize();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const selectedId = useSelector(
    (state) => state.devices.selectedId,
    shallowEqual
  );
  const user = useSelector((state) => state.session.user, shallowEqual);

  const currentLocationHasMultiSelect =
    pathname &&
    (pathname.includes("fuelAnalysis") ||
      pathname.includes("advanced_fuel") ||
      pathname.includes("VehicleVSVehicle"));

  const multiSelection = useSelector((state) => state.devices.ids);
  const localIds = JSON.stringify(multiSelection);

  const subscriptions = useSelector((state) => state.subscriptions.items);
  const plans = useSelector((state) => state.subscriptions.plans);
  let fleetLevelDetails = user && user.administrator === true;
  let advancedAnalysis = user && user.administrator === true;
  let hasFuel = user && user.administrator === true;

  if (!fleetLevelDetails || !advancedAnalysis || !hasFuel) {
    Object.keys(subscriptions).forEach((subsId) => {
      if (!fleetLevelDetails) {
        fleetLevelDetails = FeatureFlags.includes(
          "fleet_fuel_report",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }

      if (!advancedAnalysis) {
        advancedAnalysis = FeatureFlags.includes(
          "advanced_fuel_analysis",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }

      if (!hasFuel) {
        hasFuel = FeatureFlags.includes(
          "fuel_events",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }
    });
  }

  const adjustWidth = () => {
    width < 1023 &&
      dispatch(
        selectorActions.setSidebarsState({
          name: "reportsMenuRightSidebar",
          value: false,
        })
      );
  };

  const navigateToPath = (path) => {
    if (currentLocationHasMultiSelect) {
      const selectLast =
        multiSelection?.length > 0
          ? multiSelection?.[multiSelection?.length - 1]
          : null;
      dispatch(devicesActions.unselect());
      dispatch(devicesActions.select(selectLast));
      dispatch(devicesActions.selectMultipleIds(selectLast));
      navigate(`${path}/${null}`);
    } else {
      navigate(`${path}/${null}`);
    }
  };

  const navigateToMultiSelectPath = (path) => {
    if (multiSelection.length === 0) {
      dispatch(devicesActions.selectMultipleIds(selectedId));
      const arrIds = JSON.stringify(selectedId ? [selectedId] : []);
      navigate(`${path}/${null}`);
    } else {
      navigate(`${path}/${null}`);
    }
  };

  const handleInfo = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    adjustWidth();
    navigateToPath("/reports/info");
  };

  const handleAdvancedFuel = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    adjustWidth();
    navigateToMultiSelectPath("/reports/advanced_fuel");
  };

  const handleFuel = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    adjustWidth();
    navigateToPath("/reports/basicFuel");
  };

  const handleEvents = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    adjustWidth();
    navigateToPath("/reports/events");
  };

  const handleUserManualEntries = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    adjustWidth();
    navigateToPath("/reports/user_Manual_Entries");
  };
  const handleTracking = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    adjustWidth();
    navigateToPath("/reports/tracking_live");
  };

  const handleFuelAnalysis = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    adjustWidth();
    navigateToMultiSelectPath("/reports/fuelAnalysis");
  };

  const handleFleetSummary = () => {
    dispatch(devicesActions.unselect());
    navigate(`/reports/fleetSummary`);
    adjustWidth();
  };

  return (
    <div>
      {fleetLevelDetails && (
        <Item
          text={t("fleetSummary")}
          onClick={handleFleetSummary}
          checked={
            pathname.includes("reports/fleetSummary")
              ? "reports/fleetSummary"
              : "/reports/fleetStatus"
          }
          className="fleetStep-8"
        />
      )}
      {hasFuel && (
        <Item
          text={t("fuelSummary")}
          onClick={handleFuel}
          checked={"reports/basicFuel"}
          className="fleetStep-9"
        />
      )}

      {advancedAnalysis && (
        <Item
          text={t("advancedFuelSummary")}
          onClick={handleAdvancedFuel}
          checked={"advanced_fuel"}
          className="fleetStep-10"
        />
      )}
      {advancedAnalysis && (
        <Item
          text={t("analysis")}
          onClick={handleFuelAnalysis}
          checked={
            pathname.includes("configuration")
              ? "configuration"
              : "fuelAnalysis"
          }
          className="fleetStep-11"
        />
      )}
      <Item
        text={t("notificationsHistory")}
        onClick={handleEvents}
        checked={"events"}
        className="fleetStep-12"
      />

      <Item
        text={t("deviceInformation")}
        onClick={handleInfo}
        checked={"info"}
        className="fleetStep-13"
      />

      {hasFuel && (
        <Item
          text={t("userManualEntries")}
          onClick={handleUserManualEntries}
          checked={"user_Manual_Entries"}
          className="fleetStep-14"
        />
      )}

      <Item
        text={t("track")}
        onClick={handleTracking}
        checked={
          pathname.includes("tracking_live") ? "tracking_live" : "tracking_old"
        }
        className="fleetStep-15"
      />
    </div>
  );
};

export default SelectToViewSection;
