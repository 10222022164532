import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "groups",
  initialState: {
    items: {},
  },
  reducers: {
    reset(state, action) {
      state.items = {};
    },
    update(state, action) {
      state.items = {};
  
      if (!Array.isArray(action.payload)) {
        return;
      }
      action.payload?.forEach((item) => (state.items[item.id] = item));
    },
  },
});

export { actions as groupsActions };
export { reducer as groupsReducer };
