import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "devices",
  initialState: {
    active: true,
    setByAllButton: true, // Initial state = all devices must be visible
    items: {},
    count: 0,
    mainDevicesMap: {},
    deviceLoadComplete: false,
    selectedId: null,
    analsisDeviceSelected: null,
    isOpen: false,
    visibleitems: {},
    ids: [],
    previousIds: null,
    updatedItem: {},
    tankcapacities: {},
    fleetStatusData: [],
    fleetStatusTotals: {
      allOK: 0,
      noData: 0,
      sensorDisconnect: 0,
      powerDisconnect: 0,
      networkIssue: 0,
      cleaningRequired: 0,
      unknown: 0,
    },
  },
  reducers: {
    reset(state, action) {
      state.active = true;
      state.setByAllButton = true; // Initial state = all devices must be visible
      state.items = {};
      state.mainDevicesMap = {};
      state.selectedId = null;
      state.analsisDeviceSelected = null;
      state.isOpen = false;
      state.visibleitems = {};
      state.selectMultipleIds = [];
      state.previousIds = null;
      state.updatedItem = {};
      state.tankcapacities = {};
      state.fleetStatusData = [];
      state.fleetStatusTotals = {
        allOK: 0,
        noData: 0,
        sensorDisconnect: 0,
        powerDisconnect: 0,
        networkIssue: 0,
        cleaningRequired: 0,
        unknown: 0,
      };
    },
    idsreset(state, action) {
      state.selectMultipleIds = action.payload;
    },
    refresh(state, action) {
      if (Array.isArray(action.payload)) {
        state.items = {};
        state.mainDevicesMap = {};
        action.payload?.forEach((item) => {
          state.items[item.id] = item;
          state.mainDevicesMap[item.id] = item;
          state.visibleitems[item.id] = true; // Initial state = all devices must be visible
        });
        state.count = action.payload.length;
      }
    },
    setDeviceLoadComplete(state, action) {
      state.deviceLoadComplete = action.payload;
    },
    update(state, action) {
      if (!Array.isArray(action.payload)) {
        return;
      }
      action.payload?.forEach((item) => {
        state.items[item.id] = item;
        if (state.visibleitems[item.id] === undefined) {
          // If a new device shows up, make it visible by default.
          state.visibleitems[item.id] = true;
        }
      });
    },
    select(state, action) {
      if (action.payload?.deviceId) {
        state.selectedId = action.payload?.deviceId;
        state.isOpen = action.payload?.isOpen;
      } else {
        state.isOpen = action.payload?.isOpen;
      }
    },
    unselect(state, action) {
      state.selectedId = null;
      state.ids = [];
      state.previousIds = null;
      state.analsisDeviceSelected = null;
      state.isOpen = false;
    },
    analysisDeviceSelect(state, action) {
      if (action.payload > 0) {
        state.analsisDeviceSelected = action.payload;
      }
    },
    setDataLoadedForIds(state, action) {
      state.previousIds = action.payload;
    },
    selectMultipleIds(state, action) {
      if (action.payload) {
        if (state.ids?.includes(action.payload)) {
          state.ids = state.ids.filter((id) => id !== action.payload);
        } else {
          state.ids = [...state.ids, action.payload];
        }
      }
    },
    remove(state, action) {
      delete state.items[action.payload];
    },
    setVisibility(state, action) {
      if (state.visibleitems[action.payload.deviceId] === undefined) {
        state.visibleitems[action.payload.deviceId] = action.payload;
      } else {
        state.visibleitems[action.payload.deviceId] = action.payload.visible;
      }

      if (action.payload.visible === false) {
        state.active = action.payload = false;
      }

      state.setByAllButton = false;

      var hides = 0;
      for (var deviceId in state.visibleitems) {
        if (state.visibleitems[deviceId] === false) {
          hides++;
        }
      }

      if (hides === 0) {
        state.active = true;
        state.setByAllButton = true;
      }
    },
    toggleSwitch(state, action) {
      state.active = action.payload;

      state.setByAllButton = true;

      for (var deviceId in state.visibleitems) {
        state.visibleitems[deviceId] = action.payload;
      }
    },
    setTankCapacities(state, action) {
      state.tankcapacities = action.payload;
    },
    setFleetStatusData(state, action) {
      state.fleetStatusData = action.payload;

      if (!Array.isArray(action.payload)) {
        return;
      }

      action.payload?.forEach((statusItem) => {
        if (statusItem.statusInfo && statusItem.statusInfo.status) {
          if (statusItem.statusInfo.status === "All ok") {
            state.fleetStatusTotals.allOK += 1;
          } else if (
            statusItem.statusInfo.status.includes("No data in date range")
          ) {
            state.fleetStatusTotals.noData += 1;
          } else if (
            statusItem.statusInfo.status.includes("Sensor disconnected")
          ) {
            state.fleetStatusTotals.sensorDisconnect += 1;
          } else if (
            statusItem.statusInfo.status.includes("Power disconnected")
          ) {
            state.fleetStatusTotals.powerDisconnect += 1;
          } else if (statusItem.statusInfo.status.includes("Network issue")) {
            state.fleetStatusTotals.networkIssue += 1;
          } else if (
            statusItem.statusInfo.status.includes("cleaning required")
          ) {
            state.fleetStatusTotals.cleaningRequired += 1;
          } else {
            state.fleetStatusTotals.unknown += 1;
          }
        } else {
          state.fleetStatusTotals.unknown += 1;
        }
      });
    },
    clearFleetStatus(state, action) {
      state.fleetStatusTotals = {
        allOK: 0,
        noData: 0,
        sensorDisconnect: 0,
        powerDisconnect: 0,
        networkIssue: 0,
        cleaningRequired: 0,
        unknown: 0,
      };
    },
  },
});

export { actions as devicesActions };
export { reducer as devicesReducer };
