import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { t } from "i18next";

const SelectedInput = ({
  testId,
  value,
  name,
  onChange,
  MenuItemValue,
  label,
  multiple,
  displayEmpty,
  required = false,
  fullWidth,
  className,
  children,
  defaultValue,
  disabled,
  error,
}) => {
  return (
    <FormControl
      error={error}
      required={required}
      className={className}
      data-testid={testId}
      fullWidth={fullWidth}
      variant="filled"
      size="small"
      sx={{
        minWidth: "150px",
        boxShadow: "3px 3px 5px rgba(0,0,0,0.1)",
      }}
    >
      <InputLabel size="small">{label}</InputLabel>
      <Select
        error={error}
        size="small"
        defaultValue={defaultValue}
        required={required}
        displayEmpty={displayEmpty}
        multiple={multiple}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        MenuProps={{
          style: { zIndex: 9999999 },
        }}
      >
        {children
          ? children
          : Object.keys(MenuItemValue).map((item) => (
              <MenuItem key={item} value={item}>
                {t(item)}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};

export default SelectedInput;
