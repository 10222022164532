export const reportHeaders = {
  fleet_summary: [
    "Start Time",
    "End Time",
    "Fuel filled(L)",
    "Fuel drained(L)",
    "Fuel consumed(L)",
    "KMs covered(KMs)",
    "Hours run(H)",
  ],
  fleet_summary_individual: [
    "#",
    "Start Time",
    "End Time",
    "Reg Number",
    "Fuel filled(L)",
    "Fuel drained(L)",
    "Fuel consumed(L)",
    "KMs covered(KMs)",
    "Mileage(KM/L)",
    "Hours run(H)",
    "Hourly consumption(L/H)",
  ],

  Events: [
    "S.no",
    "Reg Number",
    "Start Time",
    "End Time",
    "Event time",
    "Type",
    "Volume (L) / Duration",
    "Location",
  ],
  Basic_Summary: [
    "Reg Number",
    "Start Time",
    "End Time",
    "OPENING",
    "FILL",
    "DRAIN",
    "CLOSING",
    "CONSUMED",
    "KMS",
    "KM / LITER",
    "HOURS",
    "LITERS / HOUR",
  ],
  Advanced_Summary: [
    "S.NO",
    "Reg Number",
    "Start Time",
    "End Time",
    "Event Time",
    "Opening Fuel(L)",
    "Event Type",
    "Event Volume(L)",
    "Closing Fuel(L)",
    "Fuel Consumed(L)",
    "Kms Covered",
    "Mileage(KM/L)",
    "Hours Run",
    "Idle Hours",
    "Liters Per Hour(L/H)",
    "Location",
  ],
};
