import { Capacitor } from "@capacitor/core";
import {
  Document,
  Image,
  Link,
  Page,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import { t } from "i18next";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RangrLogo } from "../../../assets/images";
import { PdfStyles } from "../../../assets/styles";
import ButtonMUI from "../../../common/common/ButtonMUI";
import { eventTypes } from "../../../common/dropdownStrings";
import { reportHeaders } from "../../../common/reportHeaders";
import { DecimalNumber } from "../../../common/util/formatter";
import { LocalTime } from "../../../common/util/time";

const fuelEventTypes = ["fuelFill", "fuelDrain"];

const MyTable = ({ t, data, report, devices, fromDate, endDate }) => (
  <div style={PdfStyles.body}>
    <div style={PdfStyles.tabelHeader}>
      <div style={PdfStyles.leftPart}>
        <Image
          src={RangrLogo}
          style={{ width: 20, height: 20 }}
          fit="contain"
        />
      </div>

      <div style={PdfStyles.rightPart}>
        <div style={PdfStyles.header}>
          <Text>ORANGECELLS LAB</Text>
        </div>
        <div style={PdfStyles.header}>
          <Text>PO CHIRKUNDA, DIST DHANBAD, JHARKHAND 828202</Text>
        </div>
        <div style={PdfStyles.header}>
          <Text>VEHICLE REPORT</Text>
        </div>
      </div>
    </div>

    <table style={PdfStyles.table}>
      {report === "fleet_summary" && (
        <div
          style={{
            marginTop: "30px",
            paddingBottom: "30px",
            fontSize: "20px",
            font: "bold",
            borderBottom: "2px solid black",
          }}
        >
          <Text>Fleet summary</Text>
        </div>
      )}
      <tr style={PdfStyles.tableRow}>
        {reportHeaders[report]?.map((header, i) => (
          <th key={i} style={PdfStyles.tableCol}>
            <Text>{header}</Text>
          </th>
        ))}
      </tr>

      {report === "fleet_summary" && (
        <tr style={PdfStyles.tableRow}>
          <td style={PdfStyles.tableCol}>
            <Text>{LocalTime(data?.summary?.fromDate || fromDate)}</Text>
          </td>
          <td style={PdfStyles.tableCol}>
            <Text>{LocalTime(data?.summary?.toDate || endDate)}</Text>
          </td>

          <td style={PdfStyles.tableCol}>
            <DecimalNumber
              isDownloadPdf
              value={data?.summary?.fuelFilled}
              count={2}
            />
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber
              isDownloadPdf
              value={data?.summary?.fuelDrained}
              count={2}
            />
          </td>

          <td style={PdfStyles.tableCol}>
            <DecimalNumber
              isDownloadPdf
              value={data?.summary?.fuelConsumed}
              count={2}
            />
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber
              isDownloadPdf
              value={data?.summary?.kmsCovered}
              count={2}
            />
          </td>

          <td style={PdfStyles.tableCol}>
            <DecimalNumber
              isDownloadPdf
              value={data?.summary?.hoursRun}
              count={2}
            />
          </td>
        </tr>
      )}

      {report === "fleet_summary" && (
        <>
          <div
            style={{
              marginTop: "30px",
              paddingBottom: "30px",
              borderBottom: "2px solid black",
              fontSize: "20px",
              font: "bold",
            }}
          >
            <Text> Breakdown by device</Text>
          </div>
          <tr style={PdfStyles.tableRow}>
            {reportHeaders["fleet_summary_individual"]?.map((header, i) => (
              <th key={i} style={PdfStyles.tableCol}>
                <Text>{header}</Text>
              </th>
            ))}
          </tr>
        </>
      )}
      {report === "fleet_summary" &&
        data?.fleetSummaryData?.map((row, i) => (
          <tr key={i} style={PdfStyles.tableRow}>
            <td style={PdfStyles.tableCol}>
              <Text> {i + 1}</Text>
            </td>

            <td style={PdfStyles.tableCol}>
              <Text>{LocalTime(row?.fromDate)}</Text>
            </td>

            <td style={PdfStyles.tableCol}>
              <Text>{LocalTime(row?.toDate)}</Text>
            </td>

            <td style={PdfStyles.tableCol}>
              <Text> {devices[row?.deviceId].registrationNumber}</Text>
            </td>

            <td style={PdfStyles.tableCol}>
              <DecimalNumber isDownloadPdf value={row?.fuelFilled} count={2} />
            </td>
            <td style={PdfStyles.tableCol}>
              <DecimalNumber isDownloadPdf value={row?.fuelDrained} count={2} />
            </td>

            <td style={PdfStyles.tableCol}>
              <DecimalNumber
                isDownloadPdf
                value={row?.fuelConsumed}
                count={2}
              />
            </td>

            <td style={PdfStyles.tableCol}>
              <DecimalNumber isDownloadPdf value={row?.kmsCovered} count={2} />
            </td>

            <td style={PdfStyles.tableCol}>
              <DecimalNumber isDownloadPdf value={row?.kmsPerLiter} count={2} />
            </td>

            <td style={PdfStyles.tableCol}>
              <DecimalNumber isDownloadPdf value={row?.hoursRun} count={2} />
            </td>
            <td style={PdfStyles.tableCol}>
              <DecimalNumber
                isDownloadPdf
                value={row?.litersPerHour}
                count={2}
              />
            </td>
          </tr>
        ))}

      {report === "Basic_Summary" && (
        <tr style={PdfStyles.tableRow}>
          <td style={PdfStyles.tableCol}>
            <Text>{devices?.registrationNumber}</Text>
          </td>
          <td style={PdfStyles.tableCol}>
            <Text>{LocalTime(fromDate)}</Text>
          </td>
          <td style={PdfStyles.tableCol}>
            <Text>{LocalTime(endDate)}</Text>
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber isDownloadPdf value={data?.openingFuel} count={2} />
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber isDownloadPdf value={data?.fuelFilled} count={2} />
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber isDownloadPdf value={data?.fuelDrained} count={2} />
          </td>

          <td style={PdfStyles.tableCol}>
            <DecimalNumber isDownloadPdf value={data?.closingFuel} count={2} />
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber isDownloadPdf value={data?.fuelConsumed} count={2} />
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber isDownloadPdf value={data?.kmsCovered} count={2} />
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber isDownloadPdf value={data?.kmsPerLiter} count={2} />
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber isDownloadPdf value={data?.hoursRun} count={2} />
          </td>
          <td style={PdfStyles.tableCol}>
            <DecimalNumber
              isDownloadPdf
              value={data?.litersPerHour}
              count={2}
            />
          </td>
        </tr>
      )}
      {report === "Advanced_Summary" &&
        Object.values(data)?.map((d, i) => (
          <tr style={PdfStyles.tableRow} key={i}>
            <td style={PdfStyles.tableCol}>
              <Text>{i + 1}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>{d?.registrationNumber}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>{d?.reportStartTime}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>{d?.reportEndTime}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                {d?.eventEndTimestamp ? LocalTime(d?.eventTimestamp) : ""}
              </Text>
            </td>

            <td style={PdfStyles.tableCol}>
              <Text>
                <DecimalNumber
                  isDownloadPdf
                  value={d?.openingFuelStock}
                  count={2}
                />
              </Text>
            </td>

            <td style={PdfStyles.tableCol}>
              <Text>{d?.type}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                {d?.eventVolume ? (
                  <DecimalNumber
                    isDownloadPdf
                    value={d?.eventVolume}
                    count={2}
                  />
                ) : (
                  ""
                )}
              </Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                <DecimalNumber
                  isDownloadPdf
                  value={d?.closingFuelStock}
                  count={2}
                />
              </Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                <DecimalNumber
                  isDownloadPdf
                  value={d?.actualFuelConsumed}
                  count={2}
                />
              </Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                <DecimalNumber isDownloadPdf value={d?.totalKMS} count={2} />
              </Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                <DecimalNumber isDownloadPdf value={d?.mileage} count={2} />
              </Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                <DecimalNumber
                  isDownloadPdf
                  value={d?.totalHoursRun}
                  count={2}
                />
              </Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                <DecimalNumber
                  isDownloadPdf
                  value={d?.totalHoursIdle}
                  count={2}
                />
              </Text>
            </td>

            <td style={PdfStyles.tableCol}>
              <Text>
                <DecimalNumber
                  isDownloadPdf
                  value={d?.hourlyConsumption}
                  count={2}
                />
              </Text>
            </td>

            <td style={PdfStyles.tableCol}>
              <Text>
                {d?.eventLocation && (
                  <Link
                    src={`https://maps.google.com/maps?q=${d?.eventLocation
                      .split(",")
                      .map((l) => l.trim())
                      .join(",")
                      .replace("[", "")
                      .replace("]", "")}`}
                  >
                    <Text> Click here</Text>
                  </Link>
                )}
              </Text>
            </td>
          </tr>
        ))}

      {report === "Events" &&
        data?.map((event, i) => (
          <tr style={PdfStyles.tableRow} key={i}>
            <td style={PdfStyles.tableCol}>
              <Text>{i + 1}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>{devices?.registrationNumber}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>{LocalTime(fromDate)}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>{LocalTime(endDate)}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>{LocalTime(event?.time)}</Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                {eventTypes[event?.type]
                  ? eventTypes[event?.type]
                  : event?.type}
              </Text>
            </td>
            <td style={PdfStyles.tableCol}>
              <Text>
                {fuelEventTypes.includes(event?.type)
                  ? event?.volume?.toFixed(1)
                  : event?.duration}
              </Text>
            </td>

            <td style={PdfStyles.tableCol}>
              {event?.lat && (
                <Link
                  src={`https://maps.google.com/maps?q=${[
                    event?.lat,
                    event?.lng,
                  ]}`}
                >
                  <Text> Click here</Text>
                </Link>
              )}
            </td>
          </tr>
        ))}
    </table>
  </div>
);

export const MyDocument = ({
  isFuelAnalysis,
  t,
  data,
  report,
  devices,
  endDate,
  fromDate,
  user,
}) => (
  <Document>
    <Page size="A4" style={PdfStyles.page} orientation={"landscape"}>
      <View style={PdfStyles.section}>
        <MyTable
          isFuelAnalysis={isFuelAnalysis}
          t={t}
          data={data}
          user={user}
          report={report}
          devices={devices}
          fromDate={fromDate}
          endDate={endDate}
        />
      </View>
    </Page>
  </Document>
);

const DownloadPdf = ({
  fullWidth,
  isFuelAnalysis,
  data,
  devices,
  report,
  endDate,
  fromDate,
}) => {
  const user = useSelector((state) => state.session.user, shallowEqual);
  const platform = useSelector((state) => state.drivers.platform, shallowEqual);

  const generatePDF = async () => {
    const blob = await pdf(
      <MyDocument
        isFuelAnalysis={isFuelAnalysis}
        user={user}
        data={data}
        report={report}
        devices={devices}
        fromDate={fromDate}
        endDate={endDate}
        t={t}
      />
    ).toBlob();

    if (Capacitor.isNativePlatform()) {
      // TODO: Uncomment the following when / if capacitor is upgarded. currently download of pdf id not supported in mobile.
      // try {
      //   const path = devices?.registrationNumber
      //     ? devices?.registrationNumber
      //     : "" + "_" + moment().valueOf() + ".pdf";
      //   // Convert the blob to base64
      //   const reader = new FileReader();
      //   reader.readAsDataURL(blob);
      //   reader.onloadend = async () => {
      //     const base64data = reader.result;
      //     await Filesystem.writeFile({
      //       path: path,
      //       data: base64data,
      //       directory: "DOCUMENTS",
      //       recursive: true,
      //     });
      //     alert("PDF downloaded successfully!");
      //   };
      // } catch (error) {
      //   alert("Error downloading and saving PDF");
      // }
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${
        devices?.registrationNumber ? devices?.registrationNumber + "_" : ""
      }${t(report)}_${LocalTime(fromDate)}-${LocalTime(endDate)}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    platform === "web" && (
      <ButtonMUI
        fullWidth={fullWidth}
        onClick={generatePDF}
        type="button"
        size="small"
      >
        {t("downloadPdf")}
      </ButtonMUI>
    )
  );
};

export default DownloadPdf;
