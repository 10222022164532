import { Http } from "@capacitor-community/http";

const Native = {
  hostName: "traccar.orangecells.com",
  wsProtocol: "wss",
  httpProtocol: "https",
};

let socketRef;

const handleResponse = (response, path) => {
  if (response.status >= 200 && response.data && typeof response.data === "object") {
      return response.data;
  } else {
    throw Error("Error calling native " + path + " " + response.status);
  }
};

Native.get = async (path, credentialOption) => {
  
  let url;
  if (path.includes("http")) {
    url = path;
  }
  else {
    url = `${Native.httpProtocol}://${Native.hostName}${path}`;
  }
  
  const config = {
    url: url,
    headers: {
      Accept: "application/json",
    },
    webFetchExtra: {
      credentials: credentialOption,
    },
  };

  const response = await Http.get(config);
  return handleResponse(response, path);
};

Native.post = async (path, options) => {
  const url = `${Native.httpProtocol}://${Native.hostName}${path}`;

  const config = {
    url: url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  if (options && options.credentialOption) {
    config.webFetchExtra = {
      credentials: options.credentialOption,
    };
  }

  if (options && options.data) {
    config.headers["Content-Type"] = "application/json";
    config.data = options.data ? options.data : "";

    // Override any headres and content.
    if (options.headers) {
      for (const prop in options.headers) {
        config.headers[prop] = options.headers[prop];
        // If prop is of type "multipart form data"
        // then the config.body above will need to be reassigned using FormData
        if (
          prop === "Content-Type" &&
          options.headers[prop] === "multipart/form-data" &&
          options.data
        ) {
          const formData = new FormData();
          formData.append("manualEventsFile", options.data);
          config.data = formData;
        }
      }
    }
  }

  const response = await Http.post(config);
  return handleResponse(response, path);
};

Native.put = async (path, options) => {
  const url = `${Native.httpProtocol}://${Native.hostName}${path}`;
  const config = {
    url: url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  if (options && options.credentialOption) {
    config.webFetchExtra = {
      credentials: options.credentialOption,
    };
  }
  if (options && options.body) {
    config.body = options.body;
  }

  if (options && options.data) {
    config.data = options.data;
  }

  const response = await Http.put(config);
  return handleResponse(response, path);
};

Native.delete = async (path, options) => {
  const url = `${Native.httpProtocol}://${Native.hostName}${path}`;
  const config = {
    url: url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  if (options && options.credentialOption) {
    config.webFetchExtra = {
      credentials: options.credentialOption,
    };
  }

  if (options && options.body) {
    config.body = options.body;
  }

  if (options && options.data) {
    config.data = options.data;
  }

  const response = await Http.del(config);
  return handleResponse(response, path);
};

Native.Websocket = async (path, connectOptions) => {
  try {
    if (window.WebSocket) {
      const WebSocket = window.WebSocket;
      const origin = `${Native.wsProtocol}://${Native.hostName}`;
      const socketUrl = `${origin}${path}`;
      const options = {
        origin: origin,
        maxConnectTime: 10000,
        override: true,
      };

      WebSocket.pluginOptions = options;
      const socket = new WebSocket(socketUrl);
      if (socket) {
        socketRef = socket;

        if (connectOptions.onopen) {
          socket.onopen = connectOptions.onopen;
        }

        if (connectOptions.onerror) {
          socket.onError = connectOptions.onerror;
          socket.onerror = connectOptions.onerror;
        }

        if (connectOptions.onmessage) {
          socket.onmessage = connectOptions.onmessage;
        }
      } else {
        console.error("socket is not created");
      }
    } else {
      console.error("Nothing called WebSocket");
    }
  } catch (error) {
    console.error("caught error native socket: ", error);
  }
};

Native.WebsocketClose = async () => {
  if (socketRef) {
    socketRef.close();
  }
};

export default Native;
