import React from "react";
import { useTranslation } from "react-i18next";

const Footer = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${className} w-full h-fit z-[9999] bg-gray-100 p-1
     flex flex-wrap justify-center text-[0.7em] items-center`}
    >
      <span className="p-0 pl-4 pr-4 border-r-2 border-[#F07023] text-center">
        <a href="https://orangecells.com/privacy" target="_blank">
          {t("Privacy")}
        </a>
      </span>
      <span className="p-0 pl-4 pr-4 border-r-2 border-[#F07023]">
        <a href="https://orangecells.com/terms" target="_blank">
          {t("Terms")}
        </a>
      </span>
      <span className="p-0 pl-4">
        {t("© 2022-present, Orangecells Innovation Lab Pvt. Ltd.")}
      </span>
    </div>
  );
};

export default Footer;
