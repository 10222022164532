import MapIcon from "@mui/icons-material/Map";
import Drawer from "../../../common/common/Drawer";
import MapSettingsSection from "./MapSettingsSection";

const MapSettings = () => {
  return (
    <Drawer classTour="step-3" icon={<MapIcon sx={{ color: "white" }} />}>
      <MapSettingsSection />
    </Drawer>
  );
};

export default MapSettings;
