import { Tooltip } from "@mui/material";
import { t } from "i18next";
import React, { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import truckSvg from "../../../../assets/icon/truck.svg";
import { WarningIcon } from "../../../../assets/images";
import SwitchTogle from "../../../../common/components/SwitchTogle";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import {
  NumberFormat,
  getAvatarColor,
  getStatusColor,
} from "../../../../common/util/formatter";
import {
  Before5Mins,
  FromNow,
  LocalTime,
  TimeConverter,
} from "../../../../common/util/time";
import { devicesActions, selectorActions } from "../../../../store";
import LinksSection from "./LinksSection";

const DeviceListCommon = ({ id, onClick }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const device = useSelector((state) => state.devices.items[id], shallowEqual);
  const position = useSelector(
    (state) => state.positions.items[id],
    shallowEqual
  );
  const selectedId = useSelector(
    (state) => state.devices.selectedId,
    shallowEqual
  );
  const filterKey = useSelector(
    (state) => state.filters.filterKey,
    shallowEqual
  );
  const distance = useSelector(
    (state) => state.filters.distance[id],
    shallowEqual
  );
  const visibleDevice = useSelector(
    (state) => state.devices.visibleitems[id],
    shallowEqual
  );

  const DeviceListMemo = useMemo(
    () => (
      <div
        className={` ${
          device?.id === selectedId
            ? " bg-white shadow-md shadow-[#F07023] border-b-4 border-r-4 border-[#F07023]"
            : " bg-white bg-opacity-50 shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]"
        } w-full p-2 rounded-md hover:bg-orange-200 mb-2 relative`}
      >
        {device?.attributes &&
          (Object.keys(device?.attributes)?.includes("maintenanceRequired") ||
            Object.keys(device?.attributes)?.includes("documentsExpiring")) &&
          (device?.attributes?.maintenanceRequired?.length > 0 ||
            device?.attributes?.documentsExpiring?.length > 0) && (
            <Link
              to={`/reports/info/${device?.id}`}
              className="text-red-600 absolute top-1 w-fit h-fit right-2"
            >
              <Tooltip title={t("maintenance") + " / " + t("documentExpiry")}>
                <WarningIcon />
              </Tooltip>
            </Link>
          )}

        <div
          onClick={onClick}
          className={`${getStatusColor(
            TimeConverter(device?.lastUpdate) >= Before5Mins
              ? position?.attributes?.ignition
                ? position?.speed === 0
                  ? "orange"
                  : "online"
                : "red"
              : "offline"
          )} flex`}
        >
          <div
            data-testid="deviceListFalse"
            onClick={() => {
              dispatch(
                devicesActions.select({
                  deviceId: device?.id,
                  isOpen: true,
                })
              );
              width < 1023 &&
                dispatch(
                  selectorActions.setSidebarsState({
                    name: "deviceListSidebar",
                    value: false,
                  })
                );
            }}
            className=" w-full cursor-pointer h-fit "
          >
            <div>
              <div className="flex gap-2 relative items-center">
                <div
                  className={`rounded-full p-1 ${getAvatarColor(
                    TimeConverter(device?.lastUpdate) >= Before5Mins
                      ? position?.attributes?.ignition
                        ? position?.speed === 0
                          ? "orange"
                          : "online"
                        : "red"
                      : "offline"
                  )}`}
                >
                  <img
                    src={truckSvg}
                    alt={`${device?.category}`}
                    className="h-5 w-5"
                  />
                </div>
                <div>
                  <div>{device?.registrationNumber}</div>
                  <div className="text-[12px]">
                    {TimeConverter(device?.lastUpdate) >= Before5Mins
                      ? position?.attributes?.ignition
                        ? position?.speed === 0
                          ? t("ignitionOn")
                          : t("moving")
                        : t("ignitionOff")
                      : t("offline")}{" "}
                    <div>Updated: {FromNow(device?.lastUpdate)}</div>
                    <div>{device?.name}</div>
                  </div>
                </div>
              </div>
              <div>
                {filterKey === "DistanceFromSelectedLocation" && (
                  <>
                    {t("distance")}:{" "}
                    {!distance
                      ? t("pleaseSelectALocation")
                      : distance === Number.MAX_VALUE
                      ? t("UNKNOWNKM")
                      : NumberFormat(distance / 1000, 2, " KM")}
                  </>
                )}
                {filterKey === "lastUpdate" && (
                  <>
                    {t("lastUpdate")}: {LocalTime(device?.lastUpdate)}
                  </>
                )}
              </div>
            </div>
          </div>
          <SwitchTogle
            testId="visibleDevice"
            key={device?.id}
            checked={visibleDevice}
            onChange={() =>
              dispatch(
                devicesActions.setVisibility({
                  deviceId: device?.id,
                  visible: !visibleDevice,
                })
              )
            }
          />
        </div>
        <LinksSection id={device?.id} />
      </div>
    ),
    [
      visibleDevice,
      device?.time,
      device?.attributes,
      filterKey,
      distance,
      selectedId,
      position?.fixTime,
    ]
  );

  return DeviceListMemo;
};

export default DeviceListCommon;
