import moment from "moment";
import React from "react";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import RadioButton from "../../../common/components/RadioButton";
import { selectorActions } from "../../../store";
import FeatureFlags from "../../../Components/FeatureFlags/FeatureFlags";

const TimeDuraionSection = () => {
  const dispatch = useDispatch();
  
  const user = useSelector((state) => state.session.user);
  const subscriptions = useSelector((state) => state.subscriptions.items);
  const plans = useSelector((state) => state.subscriptions.plans);
  let hasFuel = user && user.administrator === true;
  if (!hasFuel) {
    Object.keys(subscriptions).forEach((subsId) => {
      if (!hasFuel) {
        hasFuel = FeatureFlags.includes(
          "fuel_events",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }
    });
  }
  
  const isLastFill = useSelector((state) => state.selectors.isLastFill);
  const activeDate = useSelector((state) => state.selectors.activeDuration);

  return (
    <div>
      <div className="text-end my-5 text-[calc(0.6vw+16px)]">
        {t("duration")}
      </div>

      <div className="flex justify-between mb-4">
        <div className="flex gap-2">
          <span>{t("yesterday")}</span>
        </div>

        <RadioButton
          type="radio"
          checked={!isLastFill && activeDate === "yesterday"}
          onChange={() =>
            dispatch(
              selectorActions.selectStartDate({
                startDate: moment()
                  .subtract(1, "days")
                  .startOf("day")
                  .toString(),
              })
            )
          }
          onClick={() => {
            dispatch(selectorActions.setActiveDuration("yesterday"));
            dispatch(selectorActions.selectIsLastFill(false));
          }}
        />
      </div>

      <div className="flex justify-between mb-4">
        <div className="flex gap-2">
          <span>{t("pastWeek")}</span>
        </div>

        <RadioButton
          type="radio"
          checked={!isLastFill && activeDate === "pastWeek"}
          onChange={() =>
            dispatch(
              selectorActions.selectStartDate({
                startDate: moment()
                  .subtract(7, "days")
                  .startOf("day")
                  .toString(),
              })
            )
          }
          onClick={() => {
            dispatch(selectorActions.setActiveDuration("pastWeek"));
            dispatch(selectorActions.selectIsLastFill(false));
          }}
        />
      </div>
  
      {hasFuel &&
        <div className="flex justify-between mb-4">
          <div className="flex gap-2">
            <span>{t("sinceLastFill")}</span>
          </div>
          <RadioButton
            readOnly={true}
            type="radio"
            checked={isLastFill && activeDate === "sinceLastFill"}
            onClick={() => {
              dispatch(selectorActions.setActiveDuration("sinceLastFill"));
              dispatch(selectorActions.selectIsLastFill(true));
            }}
          />
        </div>
      }
    </div>
  );
};

export default TimeDuraionSection;
