import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "positions",
  initialState: {
    items: {},
    lastKnownPositions: {},
    routePath: [],
    liveRoutePath: [],
    counter: -1,
    play: false,
    pause: false,
    interval: null,
    centerAndZoom: null,
    singleRenderPositions: {},
    selectedPosition: null,
    newPosition: null,
    animateDuration: 1000,
    playSpeed: 1,
  },
  reducers: {
    reset(state, action) {
      state.items = {};
      state.lastKnownPositions = {};
      state.routePath = [];
      state.liveRoutePath = [];
      state.play = false;
      state.centerAndZoom = null;
      state.singleRenderPositions = {};
      state.selectedPosition = null;
      state.newPosition = null;
    },
    trackingReset(state, action) {
      state.routePath = [];
      state.liveRoutePath = [];
      state.play = false;
      state.selectedPosition = null;
      state.newPosition = null;
    },
    refresh(state, action) {
      state.items = {};
      state.singleRenderPositions = {};
      if (!Array.isArray(action.payload)) {
        return;
      }
      action.payload?.forEach((item) => {
        state.items[item.deviceId] = item;
        state.singleRenderPositions[item.deviceId] = item;
      });
    },
    update(state, action) {
      if (!Array.isArray(action.payload)) {
        return;
      }
      action.payload?.forEach((item) => (state.items[item.deviceId] = item));
    },
    setPlay(state, action) {
      state.play = action.payload;
    },
    play(state, action) {
      if (!state.interval) {
        state.play = true;
      }
    },
    pause(state, action) {
      clearInterval(state.interval);
      state.play = false;
      state.interval = null;
    },
    fastForward(state, action) {
      clearInterval(state.interval);
      if (state.playSpeed >= 8) {
        state.playSpeed = 1;
        state.animateDuration = 1000;
      } else {
        state.playSpeed = state.playSpeed * 2;
        state.animateDuration = state.animateDuration / state.playSpeed;
      }
    },
    stop(state, action) {
      clearInterval(state.interval);
      state.play = false;
      state.interval = null;
      state.routePath = [];
      state.counter = -1;
      state.playSpeed = 1;
      state.animateDuration = 1000;
    },
    resetCounter(state, action) {
      state.counter = -1;
    },
    incrementCounter(state, action) {
      state.counter = state.counter + 1;
    },
    setInterval(state, action) {
      state.interval = action.payload;
    },
    clearInterval(state, action) {
      clearInterval(state.interval);
      state.interval = null;
    },
    resetRoutePath(state, action) {
      state.routePath = [];
    },
    updateRoutePath(state, action) {
      if (state.play === true) {
        state.routePath.push(action.payload);
      }
    },
    setLiveRoutePath(state, action) {
      state.liveRoutePath = action.payload;
    },
    updateLiveRoutePath(state, action) {
      state.liveRoutePath.push(action.payload);
    },
    setCenterAndZoomChanged(state, action) {
      state.centerAndZoom = action.payload;
    },
    selectPosition(state, action) {
      state.selectedPosition = action.payload;
    },
    setNewPosition(state, action) {
      state.newPosition = action.payload;
    },
  },
});

export { actions as positionsActions };
export { reducer as positionsReducer };
