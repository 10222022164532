import { Tooltip } from "@mui/material";
import { t } from "i18next";
import moment from "moment";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import truckSvg from "../../../assets/icon/truck.svg";
import { WarningIcon } from "../../../assets/images";
import { getAvatarColor, getStatusColor } from "../../../common/util/formatter";
import { Before5Mins, FromNow, TimeConverter } from "../../../common/util/time";
import {
  devicesActions,
  positionsActions,
  selectorActions,
} from "../../../store";
import { Id } from "./Formatters";

const SidebarMenuItem = ({
  index,
  selected,
  lastUpdate,
  filterSort,
  deviceEventVolume,
  eventCount,
}) => {
  const { pathname } = useLocation();

  const allowMultiSelect =
    pathname &&
    (pathname.includes("fuelAnalysis") ||
      pathname.includes("advanced_fuel") ||
      pathname.includes("VehicleVSVehicle"));

  const id = Id(pathname);

  const dispatch = useDispatch();

  const position = useSelector(
    (state) => state.positions.items[index],
    shallowEqual
  );
  const device = useSelector(
    (state) => state.devices.items[index],
    shallowEqual
  );

  return (
    <div
      className={` ${getStatusColor(
        TimeConverter(device?.lastUpdate) >= Before5Mins
          ? position?.attributes?.ignition
            ? position?.speed === 0
              ? "orange"
              : "online"
            : "red"
          : "offline"
      )} relative `}
    >
      {device &&
        (Object.keys(device?.attributes)?.includes("maintenanceRequired") ||
          Object.keys(device?.attributes)?.includes("documentsExpiring")) &&
        (device?.attributes?.maintenanceRequired?.length > 0 ||
          device?.attributes?.documentsExpiring?.length > 0) && (
          <Link
            to={`/reports/info/${device?.id}`}
            className="text-red-600 absolute top-1 w-fit h-fit right-2"
          >
            <Tooltip title={t("maintenance") + " / " + t("documentExpiry")}>
              <WarningIcon />
            </Tooltip>
          </Link>
        )}
      <div
        onClick={() => {
          dispatch(selectorActions.resetReportState());
          dispatch(selectorActions.setGoButton(false));
          dispatch(positionsActions.trackingReset([]));

          if (allowMultiSelect) {
            dispatch(devicesActions.selectMultipleIds(device.id));
          } else {
            dispatch(
              devicesActions.select({
                deviceId: device.id,
                isOpen: true,
              })
            );
          }
        }}
        key={index}
        className={`${
          id?.includes(device?.id)
            ? " bg-white shadow-md shadow-[#F07023] border-b-4 border-r-4 border-[#F07023]"
            : " bg-white bg-opacity-50 shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]"
        } w-full p-2 rounded-md hover:bg-orange-200 mb-2 cursor-pointer`}
      >
        <div className="flex gap-2 items-center">
          <div
            className={`rounded-full p-1 ${getAvatarColor(
              TimeConverter(device?.lastUpdate) >= Before5Mins
                ? position?.attributes?.ignition
                  ? position?.speed === 0
                    ? "orange"
                    : "online"
                  : "red"
                : "offline"
            )}`}
          >
            <img
              src={truckSvg}
              alt={`${device?.category}`}
              className="h-5 w-5"
            />
          </div>

          <div>
            <div>{device?.registrationNumber}</div>
            <div className="text-[12px]">
              {TimeConverter(device?.lastUpdate) >= Before5Mins
                ? position?.attributes?.ignition
                  ? position?.speed === 0
                    ? t("ignitionOn")
                    : t("moving")
                  : t("ignitionOff")
                : t("offline")}{" "}
              <div>Updated: {FromNow(device?.lastUpdate)}</div>
            </div>
            <div className="text-[10px]">
              {filterSort === "fuelFillCount" ? (
                <div>
                  {t("fuelFillCount")}: {eventCount}
                </div>
              ) : filterSort === "fuelDrainCount" ? (
                <div>
                  {t("fuelDrainCount")}: {eventCount}
                </div>
              ) : filterSort === "fuelFillVolume" ? (
                <div>
                  {t("fuelFillVolume")}: {deviceEventVolume}
                  {t("liters")}
                </div>
              ) : filterSort === "fuelDrainVolume" ? (
                <div>
                  {t("fuelDrainVolume")}: {deviceEventVolume}
                  {t("liters")}
                </div>
              ) : filterSort === "lastUpdate" ? (
                <div>
                  {t("lastUpdate")}:{" "}
                  {moment(lastUpdate).format("DD MMM YYYY LT")}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div
          className={`${
            selected ? "text-[#F07023]" : "text-[#808080]"
          } my-auto text-[12px] break-words  pl-2 break-all`}
        >
          {device?.name}
        </div>
      </div>
    </div>
  );
};

export default SidebarMenuItem;
