import React from "react";

const RadioButton = ({
  testId,
  checked,
  readOnly,
  onClick,
  onChange,
  type,
  disabled,
}) => {
  return (
    <div className="form-check">
      <input
        data-testid={testId}
        onClick={onClick}
        onChange={onChange}
        checked={checked}
        className="form-check-input appearance-none h-4 w-4 border-2 border-[#1A1A1A]  bg-white checked:bg-[#F07023]  focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
        type={`${type}`}
        id="flexCheckDefault"
        readOnly={readOnly}
        disabled={disabled}
      />
      <label
        className="form-check-label inline-block text-white md:text-[#1A1A1A]"
        htmlFor="flexCheckDefault"
      ></label>
    </div>
  );
};

export default RadioButton;
