import moment from "moment";
import "moment/min/locales.min";

export const currentTime = moment();

const currentDate = moment();

const StartOfTheDay = moment().startOf("day").utc().format();

const EndOfTheDay = moment().endOf("day").utc().format();

// Subtract 1 day to get yesterday's date
const yesterdayDate = currentDate.subtract(1, "day");

// Set the start time to 00:00:00
const startTimeOfYesterday = yesterdayDate.startOf("day").toString();

// Set the end time to 23:59:59
const endTimeOfYesterday = yesterdayDate.endOf("day").toString();

export { StartOfTheDay, EndOfTheDay, startTimeOfYesterday, endTimeOfYesterday };

export const Before5Mins = currentDate.subtract(15, "minutes").valueOf();

export const TimeConverter = (time) => moment(time).valueOf();

export const LocalTime = (time) =>
  time ? moment(time).format("DD-MM-YY LT") : "";

export const FromNow = (time) => moment(time).fromNow();

export const InputDateAndTimeFormat = (time) =>
  moment(time).format("YYYY-MM-DDTHH:mm");

export const DayFormat = (time) => (time ? moment(time).format("ddd") : "");

export const IST = (time) => moment(time).local().toString();

export const ISTValueOf = (time) => moment(time).local().toString().valueOf();

export const Date = (time) => (time ? moment(time).format("DD-MM-YY") : "");

export const AddDay = (startDate, day) =>
  moment.utc(startDate).add(day, "days").toString();

export const InputDateFormat = (time) =>
  time ? moment(time).format("YYYY-MM-DD") : "";

export const ValueOf = (time) => (time ? moment(time).valueOf() : "");
