import { Close, Delete } from "@mui/icons-material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import APIWrapper from "../../../APIWrapper/APIWrapper";
import ModalMUI from "../../../Components/Common/Modal";
import CustomeBackdrop from "../../../common/common/Backdrop";
import ButtonMUI from "../../../common/common/ButtonMUI";
import RoundedButton from "../../../common/common/RoundedButton";
import SwitchTogle from "../../../common/components/SwitchTogle";
import usePersistedState from "../../../common/util/usePersistedState";
import { geofencesActions } from "../../../store";
import CreateGeofence from "./CreateGeofence";
import { useLocation } from "react-router-dom";

const MapSettingsSection = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const user = useSelector((state) => state.session.user, shallowEqual);
  const geofences = useSelector((state) => state.geofences.items, shallowEqual);
  const visible = useSelector(
    (state) => state.geofences.visibleitems,
    shallowEqual
  );
  const isShowAll = useSelector(
    (state) => state.geofences.active,
    shallowEqual
  );

  const admin =
    user.attributes?.customerOwner === true ||
    user.attributes?.customerOwner === "true" ||
    user.administrator === true ||
    user.administrator === "true";

  const [loading, setLoading] = useState(false);
  const [mapTypeId, setMapTypeId] = usePersistedState("mapTypeId", false);

  useEffect(() => {
    dispatch(geofencesActions.setShowSatelliteView(mapTypeId));
  }, [mapTypeId, dispatch]);

  const handleMapTypeIdChange = (e) => {
    setMapTypeId(e.target.checked);
  };

  const handleShowAllChange = () => {
    dispatch(geofencesActions.setShowAll(!isShowAll));
  };

  const handleGeofenceVisibilityChange = (key) => {
    dispatch(
      geofencesActions.setVisibility({ deviceId: key, visible: !visible[key] })
    );
  };

  const handleGeofenceSelect = (key) => {
    dispatch(geofencesActions.selectGeofenceId(key));
  };

  const [isOpen, setIsOpen] = useState(false);

  const deleteGeofence = async (id) => {
    setLoading(true);
    const payload = { userId: user?.id, geofenceId: id };
    try {
      await APIWrapper.HttpModule().delete(`api/geofences/${id}`, {
        data: payload,
      });
      setLoading(false);
      setIsOpen(false);
      toast.success(t("deletedSuccessfully"));
      dispatch(geofencesActions.deleteGeofence(id));
    } catch (err) {
      toast.error(t("somethingWentWrong"));
      setLoading(false);
      setIsOpen(false);
    }
  };

  return (
    <>
      <CustomeBackdrop open={loading} />
      <section className="w-full">
        <header>
          <h1 className="text-2xl">{t("mapSettings")}</h1>
          <p>{t("enableSwitchToShowOnMap")}</p>
        </header>

        <div className="flex border-2 w-full border-gray-400 justify-between items-center my-4 p-2 rounded-md">
          <span>{t("sateliteView")}</span>
          <SwitchTogle
            testId="mapTypeId"
            checked={mapTypeId}
            onChange={handleMapTypeIdChange}
          />
        </div>
        <div className="hidden lg:block">
          {admin && pathname === "/map" && (
            <ButtonMUI
              onClick={() => setIsOpen(true)}
              size="small"
              type="button"
              fullWidth
            >
              {t("createGeofence")}
            </ButtonMUI>
          )}
        </div>
        {Object.keys(geofences)?.length > 0 && (
          <div className="border-2 mt-4 w-full border-gray-400 justify-between items-center p-2 rounded-md">
            <div className="w-full pb-2 flex justify-between border-gray-400 border-b-2">
              <span>{t("showGeofences")}</span>
              <SwitchTogle
                testId="showGeofence"
                checked={isShowAll}
                onChange={handleShowAllChange}
              />
            </div>
            {Object.keys(geofences).map((key, i) => (
              <div
                onClick={() => handleGeofenceSelect(key)}
                key={i}
                className="w-full cursor-pointer flex justify-between py-2"
              >
                <span>{geofences[key]?.name}</span>
                <span className="flex gap-2 items-center">
                  {admin && pathname === "/map" && (
                    <span
                      onClick={() => setIsOpen({ type: "delete", value: key })}
                    >
                      <Delete color="warning" />
                    </span>
                  )}
                  <SwitchTogle
                    testId="showGeofence"
                    checked={visible[key]}
                    onChange={() => handleGeofenceVisibilityChange(key)}
                  />
                </span>
              </div>
            ))}
          </div>
        )}

        {admin && (
          <ModalMUI
            isOpen={isOpen}
            width={isOpen?.type === "delete" ? 400 : "90vw"}
            height={isOpen?.type === "delete" ? 150 : "90vh"}
            p={0}
          >
            {isOpen?.type === "delete" ? (
              <div className="w-full h-full flex flex-col gap-y-4 justify-center items-center bg-white text-lg p-4">
                {t("Confirm delete geofence?")}
                <div className="w-full flex gap-2">
                  <ButtonMUI
                    fullWidth
                    onClick={() => deleteGeofence(isOpen?.value)}
                  >
                    {t("yes")}
                  </ButtonMUI>
                  <ButtonMUI fullWidth onClick={() => setIsOpen(false)}>
                    {t("no")}
                  </ButtonMUI>
                </div>
              </div>
            ) : (
              <>
                <div className="absolute z-50 top-3 right-3">
                  <RoundedButton onClick={() => setIsOpen(false)}>
                    <Close />
                  </RoundedButton>
                </div>
                <CreateGeofence setIsOpen={setIsOpen} />
              </>
            )}
          </ModalMUI>
        )}
      </section>
    </>
  );
};

export default MapSettingsSection;
