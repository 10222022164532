import { Text } from "@react-pdf/renderer";

export const getStatusColor = (status) => {
  switch (status) {
    case "online":
      return "text-green-500";
    case "offline":
      return "text-gray-500";
    case "red":
      return "text-red-500";
    case "orange":
      return "text-orange-500";
  }
};

export const getAvatarColor = (status) => {
  switch (status) {
    case "online":
      return "bg-green-500";
    case "offline":
      return "bg-gray-500";
    case "red":
      return "bg-red-500";
    case "orange":
      return "bg-orange-500";
  }
};

export const getBatteryStatus = (batteryLevel) => {
  if (batteryLevel >= 70) {
    return "positive";
  }
  if (batteryLevel > 30) {
    return "medium";
  }
  return "negative";
};

export const NumberFormat = (value, count, metric) =>
  value ? value?.toFixed(count) + metric : 0;

export const SummaryNumberFormat = (value, count) =>
  value ? value?.toFixed(count) : 0;

export const DecimalNumber = ({ isDownloadPdf, value, count = 0, metric }) => {
  return isDownloadPdf ? (
    <>
      <Text>{value && value !== 0 ? value?.toFixed(count) : 0}</Text>
      <Text>{metric}</Text>
    </>
  ) : (
    <>
      <span>{value && value !== 0 ? value?.toFixed(count) : 0}</span>
      <span>{metric}</span>
    </>
  );
};

export const getColor = (status) => {
  switch (status) {
    case "fuelFill":
      return "bg-green-600";

    case "fuelDrain":
      return "bg-red-500";

    case "ignitionOn":
      return "bg-orange-500";

    case "ignitionOff":
      return "bg-pink-500";

    case "manualfuelDrain":
      return "bg-rose-500";

    case "STOPPED":
      return "bg-fuchsia-600";

    case "manualfuelFill":
      return "bg-teal-500";

    case "INTERNAL_BATTERY_LOW":
      return "bg-cyan-500";

    case "EXTERNAL_BATTERY_DISCONNECTED":
      return "bg-violet-500";

    case "FUEL_SENSOR_DISCONNECTED":
      return "bg-indigo-500";

    case "geofenceEnter":
      return "bg-emerald-500";

    case "geofenceExit":
      return "bg-yellow-500";

    default:
      return "bg-purple-500";
  }
};
