import React, { useEffect, useState } from "react";
import { t } from "i18next";
import ReactPaginate from "react-paginate";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deviceId, path } from "../../../Reports/components/Formatters";
import SidebarMenuItem from "../../../Reports/components/ReportsMenu";
import DeviceListCommon from "./DeviceListCommon";

const itemsPerPage = 10;

const DevicesList = ({ onClick, open }) => {
  const pathname = useLocation();

  const navigate = useNavigate();

  const devices = useSelector(
    (state) => state.filters.filteredDevices,
    shallowEqual
  );

  const filterKey = useSelector(
    (state) => state.filters.filterKey,
    shallowEqual
  );
  const mappedResponse = useSelector(
    (state) => state.filters.mappedResponse,
    shallowEqual
  );

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const pageCount = Math.ceil(devices.length / itemsPerPage);
  const displayedData = devices?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const deviceFromUrl = deviceId(pathname);
  const ids = useSelector((state) => state.devices.ids);

  const useDeviceIds = () => {
    if (ids && ids.length > 0) {
      return JSON.stringify(ids);
    }

    try {
      const fromURL = deviceFromUrl ? JSON.parse(deviceFromUrl) : null;
      if (deviceFromUrl && fromURL && fromURL.length > 0) {
        return deviceFromUrl;
      }
    } catch (e) {
      // Json didn't parse or other exception; return null from line below.
    }

    return null;
  };

  const localIds = useDeviceIds();

  useEffect(() => {
    (pathname.pathname.includes("advanced_fuel") ||
      pathname.pathname.includes("fuelAnalysis") ||
      pathname.pathname.includes("VehicleVSVehicle")) &&
      navigate(path(pathname.pathname, localIds));
  }, [pathname.pathname, localIds]);

  return (
    <div
      className={`step-6 ${
        open ? "fuelStep-1" : ""
      }  min-h-[50vh] relative pt-2`}
    >
      {displayedData.map((device, i) =>
        !pathname.pathname.includes("/reports") &&
        !pathname.pathname.includes("/advancedAnalytics") ? (
          <DeviceListCommon id={device.id} key={i} onClick={onClick} />
        ) : pathname.pathname.includes("advanced_fuel") ||
          pathname.pathname.includes("fuelAnalysis") ||
          pathname.pathname.includes("VehicleVSVehicle") ? (
          <SidebarMenuItem
            key={device.id}
            index={device.id}
            filterSort={filterKey}
            eventCount={mappedResponse[device.id]?.count?.toFixed(1)}
            deviceEventVolume={mappedResponse[device.id]?.volume?.toFixed(1)}
          />
        ) : (
          <Link key={i} to={path(pathname.pathname, device?.id)}>
            <SidebarMenuItem
              key={device.id}
              index={device.id}
              filterSort={filterKey}
              eventCount={mappedResponse[device.id]?.count?.toFixed(1)}
              deviceEventVolume={mappedResponse[device.id]?.volume?.toFixed(1)}
            />
          </Link>
        )
      )}
      <ReactPaginate
        data-testid="pagination"
        previousLabel={t("prev")}
        nextLabel={t("next")}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        className="flex gap-4 w-fit text-[10px] mx-auto mt-4"
        activeLinkClassName="active"
      />
    </div>
  );
};

export default DevicesList;
