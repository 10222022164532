import React, { lazy, Suspense } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./common/components/Spinner";
import Layout from "./layout";

const AdvancedAnalytics = lazy(() => import("./Pages/AdvancedAnalytics"));
const PeriodOverPeriod = lazy(() =>
  import("./Pages/AdvancedAnalytics/PeriodOverPeriod")
);
const AddAndEditDevice = lazy(() =>
  import("./Pages/Settings/DevicesAndSensors/AddAndEditDevice")
);
const AddAndEditSensor = lazy(() =>
  import("./Pages/Settings/DevicesAndSensors/AddAndEditSensor")
);
const DevicesAndSensors = lazy(() =>
  import("./Pages/Settings/DevicesAndSensors/DevicesAndSensors")
);
const VehicleVSVehicle = lazy(() =>
  import("./Pages/AdvancedAnalytics/VehicleVSVehicle")
);
const UserAccount = lazy(() => import("./Pages/login/UserAccount"));

const App = lazy(() => import("./App"));

const LoginPage = lazy(() => import("./Pages/login/LoginPage"));
const RegisterPage = lazy(() => import("./Pages/login/RegisterPage"));
const ResetPasswordPage = lazy(() => import("./Pages/login/ResetPasswordPage"));

const Dashboard = lazy(() => import("./Pages/Dashboard/index"));
/*HomePage*/

const HomePage = lazy(() => import("./Pages/Home/HomePage"));

/*reports*/

const FleetOverviewPage = lazy(() =>
  import("./Pages/Reports/FleetSummaryPage/index")
);
const FuelReportPage = lazy(() =>
  import("./Pages/Reports/FuelReportPage/FuelReportPage")
);
const EventsReportPage = lazy(() =>
  import("./Pages/Reports/EventsReportPage/EventsReportPage")
);
const InfoReportPage = lazy(() =>
  import("./Pages/Reports/InfoReportPage/InfoReportPage")
);
const UserManualEntriesPage = lazy(() =>
  import("./Pages/Reports/UserManualEntriesPage/UserManualEntriesPage")
);
const TrackingPage = lazy(() =>
  import("./Pages/Reports/TrackingPage/TrackingPage")
);
const AdvancedFuelReportPage = lazy(() =>
  import("./Pages/Reports/AdvancedFuelReportPage")
);
const FuelAnalysisPage = lazy(() =>
  import("./Pages/Reports/FuelAnalysisPage/FuelAnalysisPage")
);

/*Settings*/

const MyAccount = lazy(() => import("./Pages/Settings/MyAccount/MyAccount"));
const UserAndGroups = lazy(() => import("./Pages/Settings/UserAndGroups"));
const AddUser = lazy(() => import("./Pages/Settings/Users/AddUser"));
const NotificationsSettings = lazy(() =>
  import("./Pages/Settings/NotificationsSettings/NotificationsSettings")
);
const LanguageSettings = lazy(() =>
  import("./Pages/Settings/LanguageSettings")
);

/*Notifications*/

const Notifications = lazy(() => import("./Pages/Notifications/Notifications"));

const Subscriptions = lazy(() => import("./Pages/Settings/Subscriptions"));

/*NotFound*/

const NotFound = lazy(() => import("./Pages/NotFound/NotFound"));

const Navigation = () => {
  const platform = useSelector((state) => state.drivers.platform, shallowEqual);

  return (
    <Layout>
      <ToastContainer className="toast-position" />
      <Suspense fallback={platform === "web" && <Spinner />}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/user-account" element={<UserAccount />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />

          <Route path="/" element={<App />}>
            <Route index element={<Dashboard />} />

            <Route path="map" element={<HomePage />} />

            <Route path="notifications">
              <Route path="" element={<Notifications />} />
            </Route>

            <Route path="settings">
              <Route path="my_account" element={<MyAccount />} />
              <Route
                path="notificationSettings"
                element={<NotificationsSettings />}
              />

              <Route path="languageSettings" element={<LanguageSettings />} />

              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="usersAndGroups/:id" element={<UserAndGroups />} />
              <Route path="usersAndGroups/addUser" element={<AddUser />} />
              <Route path="usersAndGroups/editUser/:id" element={<AddUser />} />

              <Route
                path="devicesAndSensors/:id"
                element={<DevicesAndSensors />}
              />
              <Route
                path="devicesAndSensors/addDevice"
                element={<AddAndEditDevice />}
              />

              <Route
                path="devicesAndSensors/editDevice/:id"
                element={<AddAndEditDevice />}
              />
              <Route
                path="devicesAndSensors/addSensor"
                element={<AddAndEditSensor />}
              />

              <Route
                path="devicesAndSensors/editSensor/:id"
                element={<AddAndEditSensor />}
              />
            </Route>

            <Route path="reports">
              <Route path="fleetSummary" element={<FleetOverviewPage />} />
              <Route path="fleetStatus" element={<FleetOverviewPage />} />
              <Route path="basicFuel/:id" element={<FuelReportPage />} />
              <Route
                path="advanced_fuel/:id"
                element={<AdvancedFuelReportPage />}
              />
              <Route path="fuelAnalysis/:id" element={<FuelAnalysisPage />} />
              <Route path="configuration/:id" element={<FuelAnalysisPage />} />
              <Route path="events/:id" element={<EventsReportPage />} />
              <Route path="info/:id" element={<InfoReportPage />} />
              <Route
                path="user_Manual_Entries/:id"
                element={<UserManualEntriesPage />}
              />
              <Route path="tracking_live/:id" element={<TrackingPage />} />
              <Route path="tracking_old/:id" element={<TrackingPage />} />
            </Route>

            <Route path="advancedAnalytics">
              <Route
                path="dailyFuelAnalytics/:id"
                element={<AdvancedAnalytics />}
              />
              <Route
                path="periodOverPeriod/:id"
                element={<PeriodOverPeriod />}
              />

              <Route
                path="VehicleVSVehicle/:id"
                element={<VehicleVSVehicle />}
              />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default Navigation;
