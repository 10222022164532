import { Box, styled } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { t } from "i18next";
import { useMemo } from "react";
import DevicesList from "../../Pages/Home/Components/DeviceList/DevicesList";
import SortingSection from "../../Pages/Home/Components/DeviceList/SortingSection";
import FilteredDevices from "../../Pages/Home/Components/DeviceList/filteredDevices";
import RightSidebarHeader from "../../common/components/RightSidebarHeader";
import useWindowSize from "../../common/hooks/useWindowSize";
import CommonLayout from "./CommonLayout";

export default function PersistentDrawerRight({ children, setOpen, open }) {
  const { width } = useWindowSize();

  const drawerWidth = width > 768 ? 350 : width;

  const Main = useMemo(
    () =>
      styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
        ({ theme, open }) => ({
          flexGrow: 1,
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginRight: -drawerWidth,
          display: "block",
          ...(open && {
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
            display: width > 768 ? "block" : "none",
          }),
        })
      ),
    []
  );

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Main
        sx={{
          height: "100%",
          minWidth: drawerWidth,
          position: "relative",
          zIndex: 2,
        }}
        open={open}
      >
        {children}
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          height: "100%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: "rgb(212 212 212)",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <CommonLayout className="pb-24 lg:pb-10">
          <RightSidebarHeader
            testId="deviceListFalse"
            onClick={() => setOpen(false)}
            Title={t("myFleet")}
          />
          <FilteredDevices />
          <SortingSection isClose={false} />

          <DevicesList
            open={open}
            onClick={() => width < 1023 && setOpen(false)}
          />
        </CommonLayout>
      </Drawer>
    </Box>
  );
}
