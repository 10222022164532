import { MenuItem } from "@mui/material";
import { Circle, DrawingManager, Polygon } from "@react-google-maps/api";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import APIWrapper from "../../../APIWrapper/APIWrapper";
import GoogleMapWrapper from "../../../common/Map/GoogleMapWrapper";
import CustomeBackdrop from "../../../common/common/Backdrop";
import ButtonMUI from "../../../common/common/ButtonMUI";
import InputMUI from "../../../common/common/InputMUI";
import SelectedInput from "../../../common/components/SelectedInput";
import useForm from "../../../common/hooks/useForm";
import { geofencesActions } from "../../../store";

const geofenceOptions = {
  drawingControl: true,
  drawingControlOptions: {
    drawingModes: [""],
  },
  polygonOptions: {
    fillColor: "#FF0000",
    fillOpacity: 0.5,
    strokeWeight: 2,
    clickable: true,
    editable: true,
    zIndex: 1,
  },
  circleOptions: {
    fillColor: "#00CCFF",
    fillOpacity: 0.5,
    strokeWeight: 2,
    clickable: true,
    editable: true,
    zIndex: 1,
  },
};

const CreateGeofence = ({ setIsOpen }) => {
  const dispatch = useDispatch();

  const mapRef = useRef(null);
  const drawingManagerRef = useRef(null);
  const polRef = useRef(null);
  const cirRef = useRef(null);

  const geofence = useSelector(
    (state) => state.geofences.geofence,
    shallowEqual
  );

  const [shapes, setShapes] = useState([]);
  const [loading, setLoading] = useState(false);

  const { values, handleChange } = useForm({
    geofenceName: "",
    geofenceType: "select",
  });

  const handlePolygonComplete = (polygon) => {
    const path = polygon.getPath();
    const coordinates = [];
    for (let i = 0; i < path.getLength(); i++) {
      const latLng = path.getAt(i);
      coordinates.push(`${latLng.lat()} ${latLng.lng()}`);
    }
    const polygonData = `POLYGON((${coordinates.join(", ")}))`;

    dispatch(geofencesActions.setGeofence(polygonData));
    setShapes((prevShapes) => [...prevShapes, polygon]);

    const fileInput = document.getElementById("fileInput");
    fileInput.value = "";
  };

  const handleCircleComplete = (circle) => {
    const center = circle.getCenter();
    const radius = circle.getRadius();
    const circleData = `CIRCLE (${center.lat()} ${center.lng()}, ${radius})`;
    dispatch(geofencesActions.setGeofence(circleData));

    setShapes((prevShapes) => [...prevShapes, circle]);

    const fileInput = document.getElementById("fileInput");
    fileInput.value = "";
  };

  const clearShapes = () => {
    shapes.forEach((shape) => shape.setMap(null));
    setShapes([]);
    dispatch(geofencesActions.setGeofence(null));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const payload = {
      name: values?.geofenceName,
      description: values?.geofenceType,
      area: geofence?.area,
      attributes: {
        color: values?.geofenceType === "polygon" ? "#FF0000" : "#00CCFF",
      },
    };

    try {
      const url = `/api/geofences`;
      const geofence = await APIWrapper.HttpModule().post(url, {
        data: payload,
      });
      dispatch(geofencesActions.update([geofence]));
      setIsOpen(false);
      toast.success(t("savedSuccessfully"));
      dispatch(geofencesActions.setGeofence(null));
    } catch (error) {
      toast.error(t("somethingWentWrong"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (drawingManagerRef.current) {
      const drawingMode =
        values.geofenceType === "polygon" ? "polygon" : "circle";
      drawingManagerRef.current.setOptions({ drawingMode });
    }
  }, [values.geofenceType, drawingManagerRef.current, mapRef]);

  useEffect(() => {
    if (polRef?.current || cirRef.current) {
      polRef.current?.setOptions({ path: geofence?.polygon });

      cirRef.current?.setOptions({
        center: geofence?.center,
        radius: geofence?.radius,
      });
    }
    calculateZoomLevel();
  }, [geofence, cirRef, polRef, drawingManagerRef.current, mapRef]);

  const calculateZoomLevel = async () => {
    const map = mapRef.current;

    if (geofence?.polygon?.length > 0 && map) {
      let bounds = new window.google.maps.LatLngBounds();

      await geofence?.polygon?.forEach((marker) => {
        if (marker?.lat && marker?.lng) {
          const position = new window.google.maps.LatLng(
            marker.lat,
            marker.lng
          );
          bounds.extend(position);
        }
        if (bounds) {
          map.fitBounds(bounds);
        }
      });
    }

    if (geofence?.center && geofence?.radius) {
      if (map) {
        let bounds = new window.google.maps.LatLngBounds();

        // Calculate the bounds using the center and radius
        const EARTH_RADIUS = 6378137; // Earth’s radius in meters
        const latDelta = (geofence?.radius / EARTH_RADIUS) * (180 / Math.PI);
        const lngDelta =
          (geofence?.radius /
            (EARTH_RADIUS *
              Math.cos((Math.PI * geofence?.center?.lat) / 180))) *
          (180 / Math.PI);

        // Extend the bounds to include the corners defined by the center and deltas
        bounds.extend(
          new window.google.maps.LatLng(
            geofence?.center?.lat + latDelta,
            geofence?.center?.lng + lngDelta
          )
        );
        bounds.extend(
          new window.google.maps.LatLng(
            geofence?.center?.lat - latDelta,
            geofence?.center?.lng - lngDelta
          )
        );

        // Apply the bounds to the map
        map.fitBounds(bounds);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      clearShapes();
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        dispatch(geofencesActions.setGeofence(text));
      };
      reader.onerror = (e) => {
        toast.error("Failed to read file");
      };
      reader.readAsText(file);
    }
  };
  const removeSelectedFile = () => {
    dispatch(geofencesActions.setGeofence(null));
    const fileInput = document.getElementById("fileInput");
    fileInput.value = "";
  };

  return (
    <div className="flex flex-row w-full h-full">
      <CustomeBackdrop open={loading} />
      <form
        onSubmit={handleSubmit}
        className="w-60 p-2 h-full flex flex-col gap-y-4"
      >
        <InputMUI
          type="text"
          required
          name="geofenceName"
          value={values?.geofenceName}
          onChange={handleChange}
          label={t("Name of Geofence")}
          fullWidth
        />
        <SelectedInput
          required
          name="geofenceType"
          onChange={handleChange}
          value={values?.geofenceType}
          fullWidth
          label={t("Type of Geofence")}
        >
          <MenuItem value="select">{t("Select geofence type")}</MenuItem>
          <MenuItem value="circle">{t("Circle")}</MenuItem>
          <MenuItem value="polygon">{t("Polygon")}</MenuItem>
        </SelectedInput>
        <ButtonMUI
          disabled={shapes?.length === 0}
          type="button"
          size="small"
          fullWidth
          onClick={clearShapes}
        >
          {t("Clear")}
        </ButtonMUI>
        <p className="text-center">{t("or")}</p>
        <span>
          {t("uploadManually")}
          <Link
            to={`/files/${values?.geofenceType}SampleFormat.txt`}
            target="_blank"
            download
          >
            <ButtonMUI
              disabled={values?.geofenceType === "select"}
              type="button"
              size="small"
              fullWidth
            >
              {t("Download template")}
            </ButtonMUI>
          </Link>
        </span>

        <input
          id="fileInput"
          type="file"
          accept=".txt,.wkt"
          onChange={handleFileChange}
        />
        <ButtonMUI
          type="button"
          size="small"
          fullWidth
          disabled={!geofence?.area || shapes?.length > 0}
          onClick={removeSelectedFile}
        >
          {t("remove")}
        </ButtonMUI>
        <ButtonMUI type="submit" size="small" fullWidth>
          {t("save")}
        </ButtonMUI>
      </form>
      <div className="flex-1">
        <GoogleMapWrapper mapRef={mapRef}>
          <DrawingManager
            onPolygonComplete={handlePolygonComplete}
            onCircleComplete={handleCircleComplete}
            options={geofenceOptions}
            onLoad={(drawingManager) =>
              (drawingManagerRef.current = drawingManager)
            }
          />

          <Circle ref={cirRef} onLoad={(circle) => (cirRef.current = circle)} />

          <Polygon ref={polRef} onLoad={(pol) => (polRef.current = pol)} />
        </GoogleMapWrapper>
      </div>
    </div>
  );
};

export default CreateGeofence;
