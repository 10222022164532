import React from "react";
import { t } from "i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import RadioButton from "../../../common/components/RadioButton";
import useWindowSize from "../../../common/hooks/useWindowSize";
import { devicesActions, selectorActions } from "../../../store";
import FeatureFlags from "../../../Components/FeatureFlags/FeatureFlags";

const Item = ({ onClick, text, checked, className }) => {
  return (
    <div
      onClick={onClick}
      className={`${className} flex justify-between my-4 cursor-pointer`}
    >
      <span>{text}</span>
      <RadioButton
        type="radio"
        checked={location.pathname.includes(checked)}
        readOnly={true}
      />
    </div>
  );
};

const AnalyticsSelectToViewSection = () => {
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedId = useSelector(
    (state) => state.devices.selectedId,
    shallowEqual
  );
  const multiSelection = useSelector((state) => state.devices.ids);
  const user = useSelector((state) => state.session.user, shallowEqual);
  const subscriptions = useSelector((state) => state.subscriptions.items);
  const plans = useSelector((state) => state.subscriptions.plans);
  let advancedAnalysis = user && user.administrator === true;

  if (!advancedAnalysis) {
    Object.keys(subscriptions).forEach((subsId) => {
      if (!advancedAnalysis) {
        advancedAnalysis = FeatureFlags.includes(
          "advanced_fuel_analysis",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }
    });
  }

  const adjustWidth = () => {
    width < 1023 &&
      dispatch(
        selectorActions.setSidebarsState({
          name: "reportsMenuRightSidebar",
          value: false,
        })
      );
  };

  const currentLocationHasMultiSelect =
    location &&
    location.pathname &&
    (location.pathname.includes("fuelAnalysis") ||
      location.pathname.includes("advanced_fuel") ||
      location.pathname.includes("VehicleVSVehicle"));

  const navigateToPath = (path) => {
    if (currentLocationHasMultiSelect) {
      const selectLast =
        multiSelection?.length > 0
          ? multiSelection?.[multiSelection?.length - 1]
          : null;
      dispatch(devicesActions.unselect());
      dispatch(devicesActions.select(selectLast));
      dispatch(devicesActions.selectMultipleIds(selectLast));
      navigate(`${path}/${null}`);
    } else {
      navigate(`${path}/${null}`);
    }
  };

  const navigateToMultiSelectPath = (path) => {
    if (multiSelection.length === 0) {
      dispatch(devicesActions.selectMultipleIds(selectedId));
      const arrIds = JSON.stringify(selectedId ? [selectedId] : []);
      navigate(`${path}/${null}`);
    } else {
      navigate(`${path}/${null}`);
    }
  };

  const handleDailyAnalytics = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    navigateToPath(`/advancedAnalytics/dailyFuelAnalytics`);
    adjustWidth();
  };

  const handlePeriodAnalytics = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    navigateToPath(`/advancedAnalytics/periodOverPeriod`);
    adjustWidth();
  };

  const handleVehicleVSVehicle = () => {
    dispatch(devicesActions.unselect());
    dispatch(selectorActions.resetReportState({}));
    navigateToMultiSelectPath(`/advancedAnalytics/VehicleVSVehicle`);
    adjustWidth();
  };

  return (
    advancedAnalysis && (
      <div>
        <Item
          text={t("Daily fuel analytics")}
          onClick={handleDailyAnalytics}
          checked={"dailyFuelAnalytics"}
          className="analyticsStep-2"
        />
        <Item
          text={t("Compare period vs. period")}
          onClick={handlePeriodAnalytics}
          checked={"periodOverPeriod"}
          className="analyticsStep-3"
        />

        <Item
          text={t("Compare Vehicle vs. Vehicle")}
          onClick={handleVehicleVSVehicle}
          checked={"VehicleVSVehicle"}
          className="analyticsStep-3_4"
        />
      </div>
    )
  );
};

export default AnalyticsSelectToViewSection;
