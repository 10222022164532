import React from "react";
import { SummaryNumberFormat } from "../../common/util/formatter";

const SummaryItem = ({ title, value, units, background = "", cost }) => {
  return (
    <div
      className={`bg-[#f07023] h-fit w-full p-2 rounded-[5px] flex flex-col items-center justify-center text-center min-w-[180px] text-white`}
      style={{ background: background }}
    >
      <div>{title}</div>
      <div className="h-10 flex items-center justify-center">
        <span className="text-[24px] font-[700]">
          {`${units ? SummaryNumberFormat(value, 1) : value}  ${
            units ? units : ""
          }`}
        </span>
      </div>
      <div>{cost}</div>
      {!cost && <br />}
    </div>
  );
};

export default SummaryItem;
