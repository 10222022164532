import React, { useMemo } from "react";
import { Cross } from "../../assets/svgs";

const RightSidebarHeader = ({ testId, onClick, Title, SubText }) => {
  const RightSidebarHeaderMemo = useMemo(
    () => (
      <div className="flex justify-between items-center w-full">
        <div>
          <div className="text-[calc(0.6vw+16px)] text-bold">{Title}</div>
          <div className="text-black">{SubText}</div>
        </div>
        <div data-testid={testId} className="cursor-pointer" onClick={onClick}>
          {Cross}
        </div>
      </div>
    ),
    []
  );
  return RightSidebarHeaderMemo;
};

export default RightSidebarHeader;
