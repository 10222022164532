import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import APIWrapper from "./APIWrapper/APIWrapper";
import { useEffectAsync } from "./reactHelper";
import { subscriptionActions } from "./store";

const SubscriptionController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authenticated = useSelector((state) => !!state.session.user);
  const user = useSelector((state) => state.session.user);

  const getSubscriptionData = async () => {
    try {
      if (user && user.administrator === true) {
        dispatch(subscriptionActions.setActive(true));
        navigate("/");
      } else if (user) {
        const plans = await APIWrapper.HttpModule().get("/api/plans?all=true");
        dispatch(subscriptionActions.refreshPlans(plans));

        const subscriptions = await APIWrapper.HttpModule().get(
          "/api/subscriptions/usersubscription"
        );
        dispatch(subscriptionActions.refresh(subscriptions));

        if (subscriptions && subscriptions.status === "paid") {
          dispatch(subscriptionActions.setActive(true));
          navigate("/");
        } else {
          dispatch(subscriptionActions.setActive(false));

          if (
            (user.attributes && user.attributes["customerOwner"] === true) ||
            user.attributes["customerOwner"] === "true"
          ) {
            navigate("/settings/subscriptions");
          } else {
            navigate("/settings/subscriptions");
          }
        }
      }
    } catch (e) {
      dispatch(subscriptionActions.setActive(false));
      navigate("/settings/subscriptions");
    }
  };

  useEffectAsync(async () => {
    await getSubscriptionData();
  }, [authenticated]);
};

export default connect()(SubscriptionController);
