import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "sensors",
  initialState: {
    items: {},
    sensorTypes: {},
    sensorManufacturers: {},
    sensorsConnectionTypes: {},
  },
  reducers: {
    reset(state, action) {
      state.items = {};
    },
    update(state, action) {
      state.items = {};
  
      if (!Array.isArray(action.payload)) {
        return;
      }
      action.payload?.forEach((item) => (state.items[item.id] = item));
    },

    updateSensorTypes(state, action) {
      action.payload?.forEach((item) => (state.sensorTypes[item.id] = item));
    },

    updateSensorManufacturers(state, action) {
      action.payload?.forEach(
        (item) => (state.sensorManufacturers[item.id] = item)
      );
    },

    updateSensorsConnectionTypes(state, action) {
      if (!Array.isArray(action.payload)) {
        return;
      }
      action.payload?.forEach(
        (item) => (state.sensorsConnectionTypes[item.id] = item)
      );
    },
  },
});

export { actions as sensorsActions };
export { reducer as sensorsReducer };
