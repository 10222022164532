import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "reports",
  initialState: {
    items: {},
    data: {},
    radioValue: "overall",
  },
  reducers: {
    reset(state, action) {
      state.items = {};
      state.data = {};
    },
    add(state, action) {
      state.items[action.payload.key] = action.payload.value;
    },
    setData(state, action) {
      state.data[action.payload.key] = action.payload.value;
    },
    setRadioValue(state, action) {
      state.radioValue = action.payload;
    },
  },
});

export { actions as reportsActions };
export { reducer as reportsReducer };
