import { IconButton } from "@mui/material";
import { orange } from "@mui/material/colors";
import { styled } from "@mui/styles";
import React from "react";

const ColorButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.getContrastText(orange[500]),
  backgroundColor: orange[500],
  "&:hover": {
    backgroundColor: orange[700],
  },
}));

const RoundedButton = ({ onClick, children }) => {
  return (
    <ColorButton
      sx={{
        boxShadow: "3px 3px 5px rgba(0,0,0,0.4)",
        zIndex: 9999,
      }}
      onClick={onClick}
    >
      {children}
    </ColorButton>
  );
};

export default RoundedButton;
