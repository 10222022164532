import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import { useLocation } from "react-router-dom";
import Drawer from "../../../common/common/Drawer";
import AnalyticsSelectToViewSection from "../../AdvancedAnalytics/components/SelectToViewSection";
import SelectToViewSection from "./SelectToViewSection";
import TimeDuraionSection from "./TimeDuraionSection";

const ReportsSidebar = () => {
  const pathname = useLocation();

  return (
    <Drawer icon={<InfoIcon className="fleetStep-7" sx={{ color: "white" }} />}>
      {pathname.pathname.includes("advancedAnalytics") ? (
        <AnalyticsSelectToViewSection />
      ) : (
        <SelectToViewSection />
      )}
      {!pathname.pathname.includes("tracking_live") &&
        !pathname.pathname.includes("user_Manual_Entries") &&
        !pathname.pathname.includes("info") &&
        !pathname.pathname.includes("configuration") && <TimeDuraionSection />}
    </Drawer>
  );
};

export default ReportsSidebar;
