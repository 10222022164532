import TuneIcon from "@mui/icons-material/Tune";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
} from "@mui/material";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FeatureFlags from "../../../../Components/FeatureFlags/FeatureFlags";
import { useStyles } from "../../../../assets/styles";
import ButtonMUI from "../../../../common/common/ButtonMUI";
import RightSidebarHeader from "../../../../common/components/RightSidebarHeader";
import SelectedInput from "../../../../common/components/SelectedInput";
import SwitchTogle from "../../../../common/components/SwitchTogle";
import { loads, statuses } from "../../../../common/dropdownStrings";
import {
  devicesActions,
  filterActions,
  positionsActions,
  selectorActions,
} from "../../../../store";
import { removeDeviceIdFromPath } from "../../../Reports/components/Formatters";

const SortingSection = ({ isClose = true }) => {
  const classes = useStyles();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const geofences = useSelector((state) => state.geofences.items, shallowEqual);
  const user = useSelector((state) => state.session.user, shallowEqual);
  const groups = useSelector((state) => state.groups.items, shallowEqual);
  const status = useSelector((state) => state.filters.status, shallowEqual);
  const switchValue = useSelector(
    (state) => state.devices.active,
    shallowEqual
  );
  const filterKeyword = useSelector(
    (state) => state.filters.filterKeyword,
    shallowEqual
  );
  const filterKey = useSelector(
    (state) => state.filters.filterKey,
    shallowEqual
  );
  const filterGroups = useSelector(
    (state) => state.filters.filterGroups,
    shallowEqual
  );
  const filterActive = useSelector(
    (state) => state.filters.filterActive,
    shallowEqual
  );
  const loadfilter = useSelector(
    (state) => state.filters.loadfilter,
    shallowEqual
  );
  const loadCapacity = useSelector(
    (state) => state.filters.loadCapacity,
    shallowEqual
  );
  const geofencesFilter = useSelector(
    (state) => state.filters.geofencesFilter,
    shallowEqual
  );
  // const wheelsFilter = useSelector((state) => state.filters.wheels, shallowEqual);
  const filterMap = useSelector(
    (state) => state.filters.filterMap,
    shallowEqual
  );

  const subscriptions = useSelector((state) => state.subscriptions.items);
  const plans = useSelector((state) => state.subscriptions.plans);
  let fleetLevelDetails = user && user.administrator === true;

  if (!fleetLevelDetails) {
    Object.keys(subscriptions).forEach((subsId) => {
      if (!fleetLevelDetails) {
        fleetLevelDetails = FeatureFlags.includes(
          "fleet_fuel_report",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }
    });
  }

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const filterRef = useRef();

  return (
    <>
      <div className="w-full z-[1]">
        <div className="w-full ">
          {isClose && (
            <RightSidebarHeader
              testId="deviceListFalse"
              onClick={() => {
                dispatch(
                  selectorActions.setSidebarsState(
                    !pathname.includes("/reports")
                      ? {
                          name: "deviceListSidebar",
                          value: false,
                        }
                      : {
                          name: "reportsDeviceListSidebar",
                          value: false,
                        }
                  )
                );
              }}
              Title={t("myFleet")}
            />
          )}
          <div className="w-full mt-2">
            <OutlinedInput
              ref={filterRef}
              placeholder={t("selectDevices")}
              value={filterKeyword}
              onChange={(e) =>
                dispatch(filterActions.setFilterKeyword(e.target.value))
              }
              endAdornment={
                <InputAdornment className="step-9" position="end">
                  <IconButton
                    size="small"
                    edge="end"
                    onClick={() => setFilterAnchorEl(filterRef.current)}
                  >
                    <TuneIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
              size="small"
              fullWidth
            />
            <Popover
              open={!!filterAnchorEl}
              anchorEl={filterAnchorEl}
              onClose={() => setFilterAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className={classes.filterPanel}>
                <SelectedInput
                  label={t("status")}
                  value={status}
                  onChange={(e) =>
                    dispatch(filterActions.setStatus(e.target.value))
                  }
                  MenuItemValue={statuses}
                  multiple
                />

                <SelectedInput
                  className="w-full step-10"
                  label={t("sortBy")}
                  value={filterKey}
                  onChange={(e) =>
                    dispatch(filterActions.setFilterKey(e.target.value))
                  }
                  displayEmpty
                >
                  <MenuItem value="registrationNumber">
                    {t("registrationNumber")}
                  </MenuItem>
                  <MenuItem value="lastUpdate">{t("lastUpdate")}</MenuItem>
                  {!pathname.includes("/reports") && (
                    <MenuItem value="DistanceFromSelectedLocation">
                      {t("distanceFromSelectedLocation")}
                    </MenuItem>
                  )}

                  {fleetLevelDetails &&
                    (pathname.includes("/reports") ||
                      pathname.includes("/advancedAnalytics")) && (
                      <MenuItem value="fuelFillCount">
                        {t("fuelFillCount")}
                      </MenuItem>
                    )}
                  {fleetLevelDetails &&
                    (pathname.includes("/reports") ||
                      pathname.includes("/advancedAnalytics")) && (
                      <MenuItem value="fuelDrainCount">
                        {t("fuelDrainCount")}
                      </MenuItem>
                    )}
                  {fleetLevelDetails &&
                    (pathname.includes("/reports") ||
                      pathname.includes("/advancedAnalytics")) && (
                      <MenuItem value="fuelFillVolume">
                        {t("fuelFillVolume")}
                      </MenuItem>
                    )}
                  {fleetLevelDetails &&
                    (pathname.includes("/reports") ||
                      pathname.includes("/advancedAnalytics")) && (
                      <MenuItem value="fuelDrainVolume">
                        {t("fuelDrainVolume")}
                      </MenuItem>
                    )}
                </SelectedInput>

                <SelectedInput
                  label={t("groups")}
                  value={filterGroups}
                  onChange={(e) =>
                    dispatch(filterActions.setFilterGroups(e.target.value))
                  }
                  multiple
                >
                  {Object.values(groups)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                </SelectedInput>

                <SelectedInput
                  label={t("active")}
                  value={filterActive}
                  onChange={(e) =>
                    dispatch(filterActions.setFilterActive(e.target.value))
                  }
                  multiple
                >
                  <MenuItem value={true}>{`${t("active")} `}</MenuItem>
                  <MenuItem value={false}>{`${t("inActive")} `}</MenuItem>
                </SelectedInput>

                <SelectedInput
                  label={t("loaded")}
                  value={loadfilter}
                  onChange={(e) =>
                    dispatch(filterActions.setLoadfilter(e.target.value))
                  }
                  multiple
                >
                  <MenuItem value={true}>{`${t("loaded")} `}</MenuItem>
                  <MenuItem value={false}>{`${t("unLoaded")} `}</MenuItem>
                </SelectedInput>

                <SelectedInput
                  label={t("loadCapacity")}
                  value={loadCapacity}
                  onChange={(e) =>
                    dispatch(filterActions.setLoadCapacity(e.target.value))
                  }
                  MenuItemValue={loads}
                  multiple
                />

                <SelectedInput
                  label={t("geofences")}
                  value={geofencesFilter}
                  onChange={(e) =>
                    dispatch(filterActions.setGeofencesFilter(e.target.value))
                  }
                  multiple
                >
                  {Object.values(geofences)?.map((geofence, i) => (
                    <MenuItem key={i} value={geofence?.id}>
                      {geofence?.name}
                    </MenuItem>
                  ))}
                </SelectedInput>

                {!pathname.includes("/reports") &&
                  !pathname.includes("/advancedAnalytics") && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterMap}
                            onChange={(e) =>
                              dispatch(
                                filterActions.setFilterMap(e.target.checked)
                              )
                            }
                          />
                        }
                        label={t("filterOnMap")}
                      />
                    </FormGroup>
                  )}
              </div>
            </Popover>
          </div>
        </div>
        {(pathname.includes("/map") || pathname === "/") && (
          <div className="flex justify-between items-center border-2 border-gray-400 my-2 p-2 rounded-md">
            <div>{t("showAll")}</div>
            <SwitchTogle
              checked={switchValue}
              onChange={() =>
                dispatch(devicesActions.toggleSwitch(!switchValue))
              }
            />
          </div>
        )}
      </div>

      <div className={"mt-2"}>
        <ButtonMUI
          fullWidth
          onClick={() => {
            dispatch(devicesActions.unselect());
            dispatch(selectorActions.clearEventTimes());
            dispatch(selectorActions.setPrevAnalysisEventId(null));
            dispatch(selectorActions.analysisEventSelect(null));
            dispatch(selectorActions.setAnalysisRowIndexSelect(0));
            dispatch(selectorActions.resetReportState());
            dispatch(devicesActions.idsreset([]));
            dispatch(selectorActions.setGoButton(false));
            dispatch(filterActions.fliterReset());
            dispatch(
              devicesActions.select({
                deviceId: null,
                isOpen: true,
              })
            );
            dispatch(positionsActions.trackingReset([]));
            if (
              pathname &&
              pathname &&
              (pathname.includes("report") ||
                pathname.includes("advancedAnalytics"))
            ) {
              let navigateTo = removeDeviceIdFromPath(pathname);

              !pathname.includes("fleetSummary") && navigate(navigateTo);
            }
          }}
        >
          {t("clearDeviceSelection")}
        </ButtonMUI>
      </div>
    </>
  );
};

export default SortingSection;
