import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "filters",
  initialState: {
    mappedResponse: [],
    filteredDevices: [],
    filteredPositions: [],
    filterKeyword: "",
    filterStatus: [],
    filterKey: "registrationNumber",
    reportsFilterKey: "registrationNumber",
    filterGroups: [],
    filterActive: [],
    loadfilter: [],
    loadCapacity: [],
    geofencesFilter: [],
    wheels: [],
    filterMap: false,
    selectedLocation: null,
    distance: {},
    status: [],
  },

  reducers: {
    reset(state, action) {
      state.mappedResponse = [];
      state.filteredDevices = [];
      state.filteredPositions = [];
      state.filterKeyword = "";
      state.filterStatus = [];
      state.filterKey = "registrationNumber";
      state.reportsFilterKey = "registrationNumber";
      state.filterGroups = [];
      state.filterActive = [];
      state.loadfilter = [];
      state.loadCapacity = [];
      state.wheels = [];
      state.filterMap = false;
      state.selectedLocation = null;
      state.distance = {};
      state.status = [];
      state.geofencesFilter = [];
    },
    select(state, action) {
      if (state.filterKey === "DistanceFromSelectedLocation") {
        if (Array.isArray(action.payload)) {
          state.selectedLocation = action.payload[0];
        } else {
          state.selectedLocation = action.payload;
        }
      } else {
        state.selectedLocation = null;
      }
    },
    setMainDeviceDistance(state, action) {
      state.distance = action.payload;
    },
    setFilterKeyword(state, action) {
      state.filterKeyword = action.payload;
    },
    setFilterStatus(state, action) {
      state.filterStatus = action.payload;
    },

    setStatus(state, action) {
      state.status = action.payload;
    },
    setFilterGroups(state, action) {
      state.filterGroups = action.payload;
    },
    setFilterKey(state, action) {
      state.filterKey = action.payload;
      if (action.payload !== "DistanceFromSelectedLocation") {
        state.selectedLocation = null;
      }
    },
    setReportsFilterKey(state, action) {
      state.reportsFilterKey = action.payload;
    },
    setFilterActive(state, action) {
      state.filterActive = action.payload;
    },
    setLoadfilter(state, action) {
      state.loadfilter = action.payload;
    },
    setLoadCapacity(state, action) {
      state.loadCapacity = action.payload;
    },
    setGeofencesFilter(state, action) {
      state.geofencesFilter = action.payload;
    },
    setWheels(state, action) {
      state.wheels = action.payload;
    },

    setFilteredPositions(state, action) {
      state.filteredPositions = [];
      if (!Array.isArray(action.payload)) {
        return;
      }
      action.payload?.forEach((item, i) => {
        state.filteredPositions[i] = {
          id: item.deviceId,
          lat: item.latitude,
          lng: item.longitude,
          time: item.fixTime,
          speed: item.speed,
          ignition: item.attributes.ignition,
        };
      });
    },
    setFilteredDevices(state, action) {
      state.filteredDevices = action.payload;
    },
    setMappedResponse(state, action) {
      state.mappedResponse = action.payload;
    },
    setFilterMap(state, action) {
      state.filterMap = action.payload;
    },

    fliterReset(state, action) {
      state.filterKeyword = "";
      state.filterStatus = [];
      state.filterKey = "registrationNumber";
      state.reportsFilterKey = "registrationNumber";
      state.filterGroups = [];
      state.filterActive = [];
      state.loadfilter = [];
      state.loadCapacity = [];
      state.wheels = [];
      state.filterMap = false;
      state.selectedLocation = null;
      state.status = [];
      state.geofencesFilter = [];
    },
  },
});

export { actions as filterActions };
export { reducer as filterReducer };
