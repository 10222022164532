export const Cross = (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const Truck = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="white"
  >
    <path
      d="M 3 3 C 2.45 3 2 3.45 2 4 L 2 17 C 2 17.55 2.45 18 3 18 L 3.0507812 18 C 3.2958204 19.692046 4.7411114 21 6.5 21 C 8.2588886 21 9.7041796 19.692046 9.9492188 18 L 14.050781 18 C 14.29582 19.692046 15.741111 21 17.5 21 C 19.258889 21 20.70418 19.692046 20.949219 18 L 22 18 C 22.55 18 23 17.55 23 17 L 23 12.310547 C 23 11.750547 22.850781 11.210469 22.550781 10.730469 L 20.519531 7.4296875 C 19.969531 6.5496875 19.000937 6 17.960938 6 L 14 6 L 14 4 C 14 3.45 13.55 3 13 3 L 3 3 z M 14 8 L 17.960938 8 C 18.310938 8 18.630547 8.1804687 18.810547 8.4804688 L 20.849609 11.779297 C 20.949609 11.939297 21 12.120547 21 12.310547 L 21 16 L 20.648438 16 C 20.084435 14.821937 18.891001 14 17.5 14 C 16.108999 14 14.915565 14.821937 14.351562 16 L 14 16 L 14 8 z M 6.5 16 C 7.327 16 8 16.673 8 17.5 C 8 18.327 7.327 19 6.5 19 C 5.673 19 5 18.327 5 17.5 C 5 16.673 5.673 16 6.5 16 z M 17.5 16 C 18.327 16 19 16.673 19 17.5 C 19 18.327 18.327 19 17.5 19 C 16.673 19 16 18.327 16 17.5 C 16 16.673 16.673 16 17.5 16 z"
      fill="white"
    />
  </svg>
);

export const Sort = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_208_1464)">
      <path
        d="M3 18H9V16H3V18ZM3 6V8H21V6H3ZM3 13H15V11H3V13Z"
        fill="#F07023"
      />
    </g>
    <defs>
      <clipPath id="clip0_208_1464">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Search = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_208_1354)">
      <path
        d="M15.5 14.5H14.71L14.43 14.23C15.41 13.09 16 11.61 16 10C16 6.41 13.09 3.5 9.5 3.5C5.91 3.5 3 6.41 3 10C3 13.59 5.91 16.5 9.5 16.5C11.11 16.5 12.59 15.91 13.73 14.93L14 15.21V16L19 20.99L20.49 19.5L15.5 14.5ZM9.5 14.5C7.01 14.5 5 12.49 5 10C5 7.51 7.01 5.5 9.5 5.5C11.99 5.5 14 7.51 14 10C14 12.49 11.99 14.5 9.5 14.5Z"
        fill="#F07023"
      />
    </g>
    <defs>
      <clipPath id="clip0_208_1354">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const InfoOutLine = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_208_1454)">
      <path
        d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_208_1454">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const menu = (
  <svg
    className="h-[1.5em] w-[1.5em]"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13H43"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9 26H43"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9 39H43"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export const Map = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 0L17.34 0.03L12 2.1L6 0L0.36 1.9C0.15 1.97 0 2.15 0 2.38V17.5C0 17.78 0.22 18 0.5 18L0.66 17.97L6 15.9L12 18L17.64 16.1C17.85 16.03 18 15.85 18 15.62V0.5C18 0.22 17.78 0 17.5 0ZM7 2.47L11 3.87V15.53L7 14.13V2.47ZM2 3.46L5 2.45V14.15L2 15.31V3.46ZM16 14.54L13 15.55V3.86L16 2.7V14.54Z"
      fill="#F07023"
    />
  </svg>
);
export const Vehicle = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.58 0 0 0.5 0 4V14C0 14.88 0.39 15.67 1 16.22V18C1 18.55 1.45 19 2 19H3C3.55 19 4 18.55 4 18V17H12V18C12 18.55 12.45 19 13 19H14C14.55 19 15 18.55 15 18V16.22C15.61 15.67 16 14.88 16 14V4C16 0.5 12.42 0 8 0ZM13.66 2.99H2.34C2.89 2.46 4.31 2 8 2C11.69 2 13.11 2.46 13.66 2.99ZM14 4.99V8H2V4.99H14ZM13.66 14.73L13.37 15H2.63L2.34 14.73C2.21 14.62 2 14.37 2 14V10H14V14C14 14.37 13.79 14.62 13.66 14.73Z"
      fill="#F5F5F5"
    />
  </svg>
);

export const Dasboard = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_475_992)">
      <path
        d="M19 5.5V7.5H15V5.5H19ZM9 5.5V11.5H5V5.5H9ZM19 13.5V19.5H15V13.5H19ZM9 17.5V19.5H5V17.5H9ZM21 3.5H13V9.5H21V3.5ZM11 3.5H3V13.5H11V3.5ZM21 11.5H13V21.5H21V11.5ZM11 15.5H3V21.5H11V15.5Z"
        fill="#1A1A1A"
      />
    </g>
    <defs>
      <clipPath id="clip0_475_992">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const DasboardActive = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_475_982)">
      <path
        d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z"
        fill="#F07023"
      />
    </g>
    <defs>
      <clipPath id="clip0_475_982">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const Home = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_475_1002)">
      <path
        d="M12 6.19L17 10.69V18.5H15V12.5H9V18.5H7V10.69L12 6.19ZM12 3.5L2 12.5H5V20.5H11V14.5H13V20.5H19V12.5H22L12 3.5Z"
        fill="#1A1A1A"
      />
    </g>
    <defs>
      <clipPath id="clip0_475_1002">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const HomeActive = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_475_1010)">
      <path
        d="M10 20.5V14.5H14V20.5H19V12.5H22L12 3.5L2 12.5H5V20.5H10Z"
        fill="#F07023"
      />
    </g>
    <defs>
      <clipPath id="clip0_475_1010">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Settings = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66602 2.5L9.17578 5.02344C8.35161 5.33425 7.59479 5.76993 6.93164 6.31445L4.50781 5.47852L2.17188 9.52148L4.11328 11.209C4.03865 11.6672 4 12.0918 4 12.5C4 12.9088 4.03981 13.3326 4.11328 13.791V13.793L2.17188 15.4805L4.50781 19.5215L6.92969 18.6875C7.5929 19.2323 8.35143 19.6656 9.17578 19.9766L9.66602 22.5H14.334L14.8242 19.9766C15.6489 19.6655 16.4049 19.2306 17.0684 18.6855L19.4922 19.5215L21.8262 15.4805L19.8867 13.791C19.9614 13.3328 20 12.9082 20 12.5C20 12.0925 19.9611 11.6684 19.8867 11.2109V11.209L21.8281 9.51953L19.4922 5.47852L17.0703 6.3125C16.4071 5.761023 15.6486 5.33443 14.8242 5.02344L14.334 2.5H9.66602ZM11.3145 4.5H12.6855L13.0742 6.5L14.1172 6.89453C14.7459 7.13147 15.3107 7.45675 15.8008 7.85938L16.6641 8.56641L18.5859 7.90625L19.2715 9.0918L17.7363 10.4277L17.9121 11.5273V11.5293C17.9733 11.9042 18 12.2188 18 12.5C18 12.7812 17.9733 13.0957 17.9121 13.4707L17.7344 14.5703L19.2695 15.9062L18.584 17.0938L16.6641 16.4316L15.7988 17.1406C15.3087 17.5432 14.7459 17.8685 14.1172 18.1055H14.1152L13.0723 18.5L12.6836 20.5H11.3145L10.9258 18.5L9.88281 18.1055C9.25415 17.8685 8.68933 17.5432 8.19922 17.1406L7.33594 16.4336L5.41406 17.0938L4.72852 15.9082L6.26562 14.5703L6.08789 13.4746V13.4727C6.02762 13.0961 6 12.7807 6 12.5C6 12.2188 6.02674 11.9043 6.08789 11.5293L6.26562 10.4297L4.72852 9.09375L5.41406 7.90625L7.33594 8.56836L8.19922 7.85938C8.68933 7.45675 9.25415 7.13147 9.88281 6.89453L10.9258 6.5L11.3145 4.5ZM12 8.5C9.80348 8.5 8 10.3035 8 12.5C8 14.6965 9.80348 16.5 12 16.5C14.1965 16.5 16 14.6965 16 12.5C16 10.3035 14.1965 8.5 12 8.5ZM12 10.5C13.1115 10.5 14 11.3885 14 12.5C14 13.6115 13.1115 14.5 12 14.5C10.8885 14.5 10 13.6115 10 12.5C10 11.3885 10.8885 10.5 12 10.5Z"
      fill="#1A1A1A"
    />
  </svg>
);
export const SettingsActive = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_475_998)">
      <path
        d="M19.1401 13.4399C19.1801 13.1399 19.2001 12.8299 19.2001 12.4999C19.2001 12.1799 19.1801 11.8599 19.1301 11.5599L21.1601 9.9799C21.3401 9.8399 21.3901 9.5699 21.2801 9.3699L19.3601 6.0499C19.2401 5.8299 18.9901 5.7599 18.7701 5.8299L16.3801 6.7899C15.8801 6.4099 15.3501 6.0899 14.7601 5.8499L14.4001 3.3099C14.3601 3.0699 14.1601 2.8999 13.9201 2.8999H10.0801C9.84011 2.8999 9.65011 3.0699 9.61011 3.3099L9.25011 5.8499C8.66011 6.0899 8.12011 6.4199 7.63011 6.7899L5.24011 5.8299C5.02011 5.7499 4.77011 5.8299 4.65011 6.0499L2.74011 9.3699C2.62011 9.5799 2.66011 9.8399 2.86011 9.9799L4.89011 11.5599C4.84011 11.8599 4.80011 12.1899 4.80011 12.4999C4.80011 12.8099 4.82011 13.1399 4.87011 13.4399L2.84011 15.0199C2.66011 15.1599 2.61011 15.4299 2.72011 15.6299L4.64011 18.9499C4.76011 19.1699 5.01011 19.2399 5.23011 19.1699L7.62011 18.2099C8.12011 18.5899 8.65011 18.9099 9.24011 19.1499L9.60011 21.6899C9.65011 21.9299 9.84011 22.0999 10.0801 22.0999H13.9201C14.1601 22.0999 14.3601 21.9299 14.3901 21.6899L14.7501 19.1499C15.3401 18.9099 15.8801 18.5899 16.3701 18.2099L18.7601 19.1699C18.9801 19.2499 19.2301 19.1699 19.3501 18.9499L21.2701 15.6299C21.3901 15.4099 21.3401 15.1599 21.1501 15.0199L19.1401 13.4399ZM12.0001 16.0999C10.0201 16.0999 8.40011 14.4799 8.40011 12.4999C8.40011 10.5199 10.0201 8.8999 12.0001 8.8999C13.9801 8.8999 15.6001 10.5199 15.6001 12.4999C15.6001 14.4799 13.9801 16.0999 12.0001 16.0999Z"
        fill="#F07023"
      />
    </g>
    <defs>
      <clipPath id="clip0_475_998">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const SignOut = (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.25 5.75L17.4875 7.5125L20.7125 10.75H8V13.25H20.7125L17.4875 16.475L19.25 18.25L25.5 12L19.25 5.75ZM3 3.25H13V0.75H3C1.625 0.75 0.5 1.875 0.5 3.25V20.75C0.5 22.125 1.625 23.25 3 23.25H13V20.75H3V3.25Z"
      fill="#808080"
    />
  </svg>
);
export const RightCaretGray = (
  <svg
    width="11"
    height="17"
    viewBox="0 0 11 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.420441 14.764L6.73509 8.43555L0.420441 2.10712L2.36447 0.163086L10.6369 8.43555L2.36447 16.708L0.420441 14.764Z"
      fill="#D9D9D9"
    />
  </svg>
);
export const Security = (
  <svg
    width="34"
    height="20"
    viewBox="0 0 34 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.0135 19.8771H22.7411V14.3622H19.046C17.4743 17.6987 14.0826 19.8771 10.3324 19.8771C5.01041 19.8771 0.681152 15.5479 0.681152 10.2259C0.681152 4.90397 5.01041 0.574707 10.3324 0.574707C14.0826 0.574707 17.4605 2.75312 19.046 6.08969H33.771V14.3622H31.0135V19.8771ZM25.4986 17.1196H28.256V11.6047H31.0135V8.84718H17.1434L16.8263 7.92342C15.8611 5.17971 13.2415 3.3322 10.3324 3.3322C6.52703 3.3322 3.43864 6.42058 3.43864 10.2259C3.43864 14.0313 6.52703 17.1196 10.3324 17.1196C13.2415 17.1196 15.8611 15.2721 16.8263 12.5284L17.1434 11.6047H25.4986V17.1196ZM10.3324 14.3622C8.05744 14.3622 6.19613 12.5008 6.19613 10.2259C6.19613 7.95099 8.05744 6.08969 10.3324 6.08969C12.6073 6.08969 14.4686 7.95099 14.4686 10.2259C14.4686 12.5008 12.6073 14.3622 10.3324 14.3622ZM10.3324 8.84718C9.57405 8.84718 8.95362 9.46761 8.95362 10.2259C8.95362 10.9842 9.57405 11.6047 10.3324 11.6047C11.0907 11.6047 11.7111 10.9842 11.7111 10.2259C11.7111 9.46761 11.0907 8.84718 10.3324 8.84718Z"
      fill="#808080"
    />
  </svg>
);
export const SecurityActive = (
  <svg
    width="34"
    height="20"
    viewBox="0 0 34 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.0135 19.8771H22.7411V14.3622H19.046C17.4743 17.6987 14.0826 19.8771 10.3324 19.8771C5.01041 19.8771 0.681152 15.5479 0.681152 10.2259C0.681152 4.90397 5.01041 0.574707 10.3324 0.574707C14.0826 0.574707 17.4605 2.75312 19.046 6.08969H33.771V14.3622H31.0135V19.8771ZM25.4986 17.1196H28.256V11.6047H31.0135V8.84718H17.1434L16.8263 7.92342C15.8611 5.17971 13.2415 3.3322 10.3324 3.3322C6.52703 3.3322 3.43864 6.42058 3.43864 10.2259C3.43864 14.0313 6.52703 17.1196 10.3324 17.1196C13.2415 17.1196 15.8611 15.2721 16.8263 12.5284L17.1434 11.6047H25.4986V17.1196ZM10.3324 14.3622C8.05744 14.3622 6.19613 12.5008 6.19613 10.2259C6.19613 7.95099 8.05744 6.08969 10.3324 6.08969C12.6073 6.08969 14.4686 7.95099 14.4686 10.2259C14.4686 12.5008 12.6073 14.3622 10.3324 14.3622ZM10.3324 8.84718C9.57405 8.84718 8.95362 9.46761 8.95362 10.2259C8.95362 10.9842 9.57405 11.6047 10.3324 11.6047C11.0907 11.6047 11.7111 10.9842 11.7111 10.2259C11.7111 9.46761 11.0907 8.84718 10.3324 8.84718Z"
      fill="#F07023"
    />
  </svg>
);
export const Language = (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2123 0.647949C6.60165 0.647949 0.43866 6.82472 0.43866 14.4354C0.43866 22.0461 6.60165 28.2228 14.2123 28.2228C21.8368 28.2228 28.0135 22.0461 28.0135 14.4354C28.0135 6.82472 21.8368 0.647949 14.2123 0.647949ZM23.767 8.92042H19.6997C19.2585 7.19699 18.6243 5.54249 17.7971 4.01209C20.3339 4.88069 22.4434 6.64549 23.767 8.92042ZM14.2261 3.46059C15.3705 5.11508 16.2666 6.94881 16.8595 8.92042H11.5927C12.1856 6.94881 13.0817 5.11508 14.2261 3.46059ZM3.55462 17.1929C3.33402 16.3105 3.19615 15.3867 3.19615 14.4354C3.19615 13.4841 3.33402 12.5603 3.55462 11.6779H8.21478C8.10448 12.5879 8.02175 13.4978 8.02175 14.4354C8.02175 15.3729 8.10448 16.2829 8.21478 17.1929H3.55462ZM4.68519 19.9504H8.75249C9.19369 21.6738 9.82791 23.3283 10.6552 24.8587C8.11827 23.9901 6.00879 22.2391 4.68519 19.9504ZM8.75249 8.92042H4.68519C6.00879 6.6317 8.11827 4.88069 10.6552 4.01209C9.82791 5.54249 9.19369 7.19699 8.75249 8.92042ZM14.2261 25.4102C13.0817 23.7557 12.1856 21.922 11.5927 19.9504H16.8595C16.2666 21.922 15.3705 23.7557 14.2261 25.4102ZM17.4524 17.1929H10.9998C10.8758 16.2829 10.7792 15.3729 10.7792 14.4354C10.7792 13.4978 10.8758 12.5741 10.9998 11.6779H17.4524C17.5765 12.5741 17.673 13.4978 17.673 14.4354C17.673 15.3729 17.5765 16.2829 17.4524 17.1929ZM17.7971 24.8587C18.6243 23.3283 19.2585 21.6738 19.6997 19.9504H23.767C22.4434 22.2253 20.3339 23.9901 17.7971 24.8587ZM20.2374 17.1929C20.3477 16.2829 20.4305 15.3729 20.4305 14.4354C20.4305 13.4978 20.3477 12.5879 20.2374 11.6779H24.8976C25.1182 12.5603 25.2561 13.4841 25.2561 14.4354C25.2561 15.3867 25.1182 16.3105 24.8976 17.1929H20.2374Z"
      fill="#808080"
    />
  </svg>
);
export const LanguageActive = (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2123 0.647949C6.60165 0.647949 0.43866 6.82472 0.43866 14.4354C0.43866 22.0461 6.60165 28.2228 14.2123 28.2228C21.8368 28.2228 28.0135 22.0461 28.0135 14.4354C28.0135 6.82472 21.8368 0.647949 14.2123 0.647949ZM23.767 8.92042H19.6997C19.2585 7.19699 18.6243 5.54249 17.7971 4.01209C20.3339 4.88069 22.4434 6.64549 23.767 8.92042ZM14.2261 3.46059C15.3705 5.11508 16.2666 6.94881 16.8595 8.92042H11.5927C12.1856 6.94881 13.0817 5.11508 14.2261 3.46059ZM3.55462 17.1929C3.33402 16.3105 3.19615 15.3867 3.19615 14.4354C3.19615 13.4841 3.33402 12.5603 3.55462 11.6779H8.21478C8.10448 12.5879 8.02175 13.4978 8.02175 14.4354C8.02175 15.3729 8.10448 16.2829 8.21478 17.1929H3.55462ZM4.68519 19.9504H8.75249C9.19369 21.6738 9.82791 23.3283 10.6552 24.8587C8.11827 23.9901 6.00879 22.2391 4.68519 19.9504ZM8.75249 8.92042H4.68519C6.00879 6.6317 8.11827 4.88069 10.6552 4.01209C9.82791 5.54249 9.19369 7.19699 8.75249 8.92042ZM14.2261 25.4102C13.0817 23.7557 12.1856 21.922 11.5927 19.9504H16.8595C16.2666 21.922 15.3705 23.7557 14.2261 25.4102ZM17.4524 17.1929H10.9998C10.8758 16.2829 10.7792 15.3729 10.7792 14.4354C10.7792 13.4978 10.8758 12.5741 10.9998 11.6779H17.4524C17.5765 12.5741 17.673 13.4978 17.673 14.4354C17.673 15.3729 17.5765 16.2829 17.4524 17.1929ZM17.7971 24.8587C18.6243 23.3283 19.2585 21.6738 19.6997 19.9504H23.767C22.4434 22.2253 20.3339 23.9901 17.7971 24.8587ZM20.2374 17.1929C20.3477 16.2829 20.4305 15.3729 20.4305 14.4354C20.4305 13.4978 20.3477 12.5879 20.2374 11.6779H24.8976C25.1182 12.5603 25.2561 13.4841 25.2561 14.4354C25.2561 15.3867 25.1182 16.3105 24.8976 17.1929H20.2374Z"
      fill="#F07023"
    />
  </svg>
);
export const Notifications = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_475_1007)">
      <path
        d="M12 22.5C13.1 22.5 14 21.6 14 20.5H10C10 21.6 10.9 22.5 12 22.5ZM18 16.5V11.5C18 8.43 16.37 5.86 13.5 5.18V4.5C13.5 3.67 12.83 3 12 3C11.17 3 10.5 3.67 10.5 4.5V5.18C7.64 5.86 6 8.42 6 11.5V16.5L4 18.5V19.5H20V18.5L18 16.5ZM16 17.5H8V11.5C8 9.02 9.51 7 12 7C14.49 7 16 9.02 16 11.5V17.5Z"
        fill="#1A1A1A"
      />
    </g>
    <defs>
      <clipPath id="clip0_475_1007">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const NotificationsActive = (
  <svg
    className="h-[1.8em] w-[1.8em]"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22.5C13.1 22.5 14 21.6 14 20.5H10C10 21.6 10.89 22.5 12 22.5ZM18 16.5V11.5C18 8.43 16.36 5.86 13.5 5.18V4.5C13.5 3.67 12.83 3 12 3C11.17 3 10.5 3.67 10.5 4.5V5.18C7.63 5.86 6 8.42 6 11.5V16.5L4 18.5V19.5H20V18.5L18 16.5Z"
      fill="#F07023"
    />
  </svg>
);
export const SetTarget = (
  <svg
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.552 14.4764C26.9178 8.72705 22.3542 4.1634 16.6048 3.52918V0.688965H13.8473V3.52918C8.09796 4.1634 3.53431 8.72705 2.90009 14.4764H0.0598755V17.2339H2.90009C3.53431 22.9833 8.09796 27.5469 13.8473 28.1811V31.0213H16.6048V28.1811C22.3542 27.5469 26.9178 22.9833 27.552 17.2339H30.3923V14.4764H27.552ZM15.2261 25.5064C9.89032 25.5064 5.57485 21.1909 5.57485 15.8552C5.57485 10.5194 9.89032 6.20394 15.2261 6.20394C20.5618 6.20394 24.8773 10.5194 24.8773 15.8552C24.8773 21.1909 20.5618 25.5064 15.2261 25.5064Z"
      fill="#808080"
    />
  </svg>
);
export const SetTargetActive = (
  <svg
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.552 14.4764C26.9178 8.72705 22.3542 4.1634 16.6048 3.52918V0.688965H13.8473V3.52918C8.09796 4.1634 3.53431 8.72705 2.90009 14.4764H0.0598755V17.2339H2.90009C3.53431 22.9833 8.09796 27.5469 13.8473 28.1811V31.0213H16.6048V28.1811C22.3542 27.5469 26.9178 22.9833 27.552 17.2339H30.3923V14.4764H27.552ZM15.2261 25.5064C9.89032 25.5064 5.57485 21.1909 5.57485 15.8552C5.57485 10.5194 9.89032 6.20394 15.2261 6.20394C20.5618 6.20394 24.8773 10.5194 24.8773 15.8552C24.8773 21.1909 20.5618 25.5064 15.2261 25.5064Z"
      fill="#F07023"
    />
  </svg>
);
export const VehicleStatus = (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 19.1 0.4875 20.0875 1.25 20.775V23C1.25 23.6875 1.8125 24.25 2.5 24.25H3.75C4.4375 24.25 5 23.6875 5 23V21.75H15V23C15 23.6875 15.5625 24.25 16.25 24.25H17.5C18.1875 24.25 18.75 23.6875 18.75 23V20.775C19.5125 20.0875 20 19.1 20 18V5.5C20 1.125 15.525 0.5 10 0.5C4.475 0.5 0 1.125 0 5.5V18ZM4.375 19.25C3.3375 19.25 2.5 18.4125 2.5 17.375C2.5 16.3375 3.3375 15.5 4.375 15.5C5.4125 15.5 6.25 16.3375 6.25 17.375C6.25 18.4125 5.4125 19.25 4.375 19.25ZM15.625 19.25C14.5875 19.25 13.75 18.4125 13.75 17.375C13.75 16.3375 14.5875 15.5 15.625 15.5C16.6625 15.5 17.5 16.3375 17.5 17.375C17.5 18.4125 16.6625 19.25 15.625 19.25ZM17.5 11.75H2.5V5.5H17.5V11.75Z"
      fill="#808080"
    />
  </svg>
);
export const VehicleStatusActive = (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 19.1 0.4875 20.0875 1.25 20.775V23C1.25 23.6875 1.8125 24.25 2.5 24.25H3.75C4.4375 24.25 5 23.6875 5 23V21.75H15V23C15 23.6875 15.5625 24.25 16.25 24.25H17.5C18.1875 24.25 18.75 23.6875 18.75 23V20.775C19.5125 20.0875 20 19.1 20 18V5.5C20 1.125 15.525 0.5 10 0.5C4.475 0.5 0 1.125 0 5.5V18ZM4.375 19.25C3.3375 19.25 2.5 18.4125 2.5 17.375C2.5 16.3375 3.3375 15.5 4.375 15.5C5.4125 15.5 6.25 16.3375 6.25 17.375C6.25 18.4125 5.4125 19.25 4.375 19.25ZM15.625 19.25C14.5875 19.25 13.75 18.4125 13.75 17.375C13.75 16.3375 14.5875 15.5 15.625 15.5C16.6625 15.5 17.5 16.3375 17.5 17.375C17.5 18.4125 16.6625 19.25 15.625 19.25ZM17.5 11.75H2.5V5.5H17.5V11.75Z"
      fill="#F07023"
    />
  </svg>
);
export const Customization = (
  <svg
    width="27"
    height="23"
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4985 0.325195H16.741V11.2173L19.4985 8.45979V0.325195Z"
      fill="#808080"
    />
    <path
      d="M2.9536 0.325195H0.196106V22.3851H2.9536V0.325195Z"
      fill="#808080"
    />
    <path
      d="M11.2261 0.325195H8.46857V5.84017H11.2261V0.325195Z"
      fill="#808080"
    />
    <path
      d="M11.2261 8.59766H8.46857V14.1126H11.2261V8.59766Z"
      fill="#808080"
    />
    <path
      d="M11.2261 16.8701H8.46857V22.3851H11.2261V16.8701Z"
      fill="#808080"
    />
    <path
      d="M25.7856 12.1688L24.2001 10.5832C23.3866 9.76978 22.01023 9.76978 21.2771 10.5832L13.9836 17.81023V22.3853H18.4921L25.7856 15.0917C26.5991 14.2783 26.5991 12.9685 25.7856 12.1688ZM17.5407 20.2482H16.1206V18.8281L20.8773 14.0715L22.2974 15.4916L17.5407 20.2482Z"
      fill="#808080"
    />
  </svg>
);
export const CustomizationActive = (
  <svg
    width="27"
    height="23"
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4985 0.325195H16.741V11.2173L19.4985 8.45979V0.325195Z"
      fill="#F07023"
    />
    <path
      d="M2.9536 0.325195H0.196106V22.3851H2.9536V0.325195Z"
      fill="#F07023"
    />
    <path
      d="M11.2261 0.325195H8.46857V5.84017H11.2261V0.325195Z"
      fill="#F07023"
    />
    <path
      d="M11.2261 8.59766H8.46857V14.1126H11.2261V8.59766Z"
      fill="#F07023"
    />
    <path
      d="M11.2261 16.8701H8.46857V22.3851H11.2261V16.8701Z"
      fill="#F07023"
    />
    <path
      d="M25.7856 12.1688L24.2001 10.5832C23.3866 9.76978 22.01023 9.76978 21.2771 10.5832L13.9836 17.81023V22.3853H18.4921L25.7856 15.0917C26.5991 14.2783 26.5991 12.9685 25.7856 12.1688ZM17.5407 20.2482H16.1206V18.8281L20.8773 14.0715L22.2974 15.4916L17.5407 20.2482Z"
      fill="#F07023"
    />
  </svg>
);
export const RightCaret = (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.420441 15.5545L6.73509 9.22608L0.420441 2.89764L2.36447 0.953613L10.6369 9.22608L2.36447 17.4985L0.420441 15.5545Z"
      fill="#F07023"
    />
  </svg>
);
