//TODO: we'll need an api endpoint for features key:description
// pairs, to make this whole thing dynamic.

const Features = {
  // Rangr Basic features - available by default:
  "live_location": "Location",
  "live_status": "Current status",
  "route_tracking": "Route tracking",
  "basic_status_updates": "Status updates via web & push notification",
  "route_history": "Vehicle route",
  "notification_history": "Notifications received",
  
  // Rangr Fuel Premium features
  "live_fuel": "Current fuel present in vehicle tank",
  "fuel_events": "Fuel fill & drain notifications with volume, location and time details",
  "additional_status_updates": "Additional device status updates via web & push notifications",
  "nearest_vehicle": "Search nearest vehicle",
  "fuel_summary_report": "Fuel consumption summary",
  "fuel_event_history": "Fuel event history with time and location on Google map",
  "device_status_report": "Device status report",
  "fuel_consumption_drilldown": "Interactive fuel consumption summary with vehicle & day/week/month wise details",
  "fleet_fuel_report": "Fuel consumption analytics & insights across vehicles",
  "advanced_fuel_analysis": "Configurable fuel consumption analysis engine to fit your specific needs and scenarios",
  "auto_reports": "Scheduled daily, weekly, monthly in-depth fuel consumption reports delivered via email for fuel audits",
  
  // User level features:
  "engine_cut": "engineCut",
}

const FeatureFlags = {
  includes: (key, description) => {
    return (key && description && Features[key] && description.toLowerCase().includes(Features[key].toLowerCase()));
  }
}


export default FeatureFlags;
