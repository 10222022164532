import { useState } from "react";

const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    setValues({
      ...values,
      [name]: value === "on" || value === "off" ? checked : value,
    });
  };

  const resetForm = () => {
    setValues(initialValues);
  };

  return {
    values,
    setValues,
    handleChange,
    resetForm,
  };
};

export default useForm;
