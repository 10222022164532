import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import { Stack, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MapSettings from "../../Pages/Home/Components/MapSettings";
import { Id } from "../../Pages/Reports/components/Formatters";
import ReportsSidebar from "../../Pages/Reports/components/ReportsSidebar";
import RoundedButton from "../../common/common/RoundedButton";
import useWindowSize from "../../common/hooks/useWindowSize";
import CommonLayout from "./CommonLayout";
import PersistentDrawerRight from "./PersistentDrawerRight";
import TopFixedButtonsSection from "./TopFixedButtonsSection";

const HasDeviceListLayout = ({ children }) => {
  const { pathname } = useLocation();

  const selectedId = useSelector(
    (state) => state.devices.selectedId,
    shallowEqual
  );

  const id = Id(pathname);

  const { width } = useWindowSize();

  const ids =
    id &&
    id !== "fleetSummary" &&
    id !== "fleetStatus" &&
    id !== "map" &&
    id !== "undefined" &&
    JSON.parse(id);

  const [open, setOpen] = useState(true);

  useEffect(() => {
    width < 768 && ids && setOpen(false);
  }, [ids]);

  useEffect(() => {
    width < 768 &&
      selectedId !== "null" &&
      selectedId !== undefined &&
      selectedId &&
      setOpen(false);
  }, [selectedId]);

  return (
    <PersistentDrawerRight setOpen={setOpen} open={open}>
      <TopFixedButtonsSection position="absolute">
        <div className="flex gap-3 ">
          {((pathname.includes("/events") && width > 1023) ||
            pathname.includes("/tracking") ||
            pathname.includes("map") ||
            pathname === "/") && <MapSettings />}
          {!pathname.includes("map") && pathname !== "/" && <ReportsSidebar />}
        </div>

        {!open && (
          <RoundedButton onClick={() => setOpen(true)}>
            <DirectionsBusFilledIcon sx={{ color: "white" }} />
          </RoundedButton>
        )}
      </TopFixedButtonsSection>

      <CommonLayout
        isMap={
          pathname.includes("tracking") ||
          pathname.includes("events") ||
          pathname.includes("map") ||
          pathname === "/"
        }
        className="h-full"
      >
        {children}
      </CommonLayout>

      {((!pathname.includes("/reports/fleetSummary") && id === "null") ||
        (pathname.includes("/advancedAnalytics/VehicleVSVehicle") &&
          (ids?.length < 2 || ids?.length > 5))) && (
        <Stack
          position="absolute"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
          backgroundColor="rgba(0,0,0,0.3)"
          top="0"
          left="0"
          color="white"
        >
          <Typography>
            {pathname.includes("/advancedAnalytics/VehicleVSVehicle")
              ? t("Please select 2 to 5 vehicles to compare analytics")
              : t("Please select vehicle to see data")}
          </Typography>
        </Stack>
      )}
    </PersistentDrawerRight>
  );
};

export default HasDeviceListLayout;
