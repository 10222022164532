import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import "moment/min/locales.min";
import {
  EndOfTheDay,
  StartOfTheDay,
  endTimeOfYesterday,
} from "../common/util/time";

const { reducer, actions } = createSlice({
  name: "selectors",
  initialState: {
    data: {},
    filteredEvents: [],
    items: {
      startDate: StartOfTheDay,
      endDate: EndOfTheDay,
    },
    period: {
      startDate: moment().subtract(14, "days").startOf("day").toString(),
      endDate: moment().subtract(8, "days").endOf("day").toString(),
    },
    dates: {
      startDate: moment().subtract(7, "days").startOf("day").toString(),
      endDate: endTimeOfYesterday,
    },
    summaryData: [],
    periodData: [],
    graphData: [],
    tankCapacity: {},
    values: {
      id: null,
      loading: false,
      error: "",
      graphLoading: false,
      grapherror: "",
      goButton: false,
      eventType: null,
      eventStartTime: null,
      eventEndTime: null,
    },
    analysisEventSelected: null,
    prevAnalysisEventSelect: null,
    graphDataValues: "",
    filterEvents: "all",
    isLastFill: false,
    snapToRoad: false,
    sidebarsState: {},
    loading: {},
    range: "day",
    booleanValue: {},
    activeDuration: "",
  },

  reducers: {
    reset(state, action) {
      state.data = {};
      state.summaryData = [];
      state.graphData = [];
      state.tankCapacity = {};
      state.values = {
        id: null,
        loading: false,
        error: "",
        graphLoading: false,
        grapherror: "",
        goButton: false,
      };
      state.analysisEventSelected = null;
      state.prevAnalysisEventSelect = null;
      state.analysisRowIndexSelect = null;
      state.graphDataValues = "";
      state.filterEvents = "All";
      state.isLastFill = false;
      state.sidebarsState = {};
    },

    setEventsReset(state, action) {
      state.data = {};
      state.filteredEvents = [];
      state.values.analysisEventSelected = null;
      state.prevAnalysisEventSelect = null;
      state.analysisRowIndexSelect = null;
    },
    analysisEventSelect(state, action) {
      state.analysisEventSelected = action.payload;
    },
    setPrevAnalysisEventId(state, action) {
      state.prevAnalysisEventSelect = action.payload;
    },
    setAnalysisRowIndexSelect(state, action) {
      state.analysisRowIndexSelect = action.payload;
    },
    setFilteredEvents(state, action) {
      state.filteredEvents = [];
      state.filteredEvents = action.payload;
    },

    setData(state, action) {
      if (action.payload.key === "events") {
        if (Array.isArray(action.payload)) {
          return;
        }
        action.payload.data?.forEach((item) => {
          state.data[
            item.positionId + "_" + item.type + "_" + item.attributes.startTime
          ] = {
            time: item.deviceTime,
            id:
              item.positionId +
              "_" +
              item.type +
              "_" +
              item.attributes.startTime,
            type: item.type,
            deviceId: item.deviceId,
            volume: item.attributes?.volume,
            lat: item.attributes?.startLat,
            lng: item.attributes?.startLong,
            geofenceId: item.geofenceId,
          };
        });
      }
      if (action.payload.key === "stoppage") {
        if (Array.isArray(action.payload)) {
          return;
        }
        action.payload.data?.forEach((item) => {
          const diff = moment.duration(
            item.endOrdinates.fixTimeMillis - item.startOrdinates.fixTimeMillis
          );
          const parts = ["d", "h", "m", "s"];
          const values = [
            Math.floor(diff.asDays()),
            Math.floor(diff.asHours()) % 24,
            Math.floor(diff.asMinutes()) % 60,
            Math.floor(diff.asSeconds()) % 60,
          ];

          const durationStr = [];
          for (let i = 0; i < parts.length; i++) {
            if (values[i] > 0) {
              durationStr.push([values[i], parts[i]].join(""));
            }
          }

          state.data[
            item.positionid +
              "_" +
              item.type +
              "_" +
              item.startOrdinates.fixTimeMillis
          ] = {
            id:
              item.positionid +
              "_" +
              item.type +
              "_" +
              item.startOrdinates.fixTimeMillis,
            type: item.type,
            time: item.startOrdinates.fixTimeMillis,
            deviceId: item.rangrDeviceId,
            lat: item.startOrdinates.latitude,
            lng: item.startOrdinates.longitude,
            geofenceId: item.geofenceId,
            duration: durationStr.join(" "),
          };
        });
      }
    },

    selectSummaryData(state, action) {
      state.summaryData = action.payload;
    },
    setPeriodData(state, action) {
      state.periodData = action.payload;
    },
    selectGraphData(state, action) {
      if (action.payload?.length > 0) {
        state.graphData = action.payload;
      }
    },
    selectTankCapacity(state, action) {
      state.tankCapacity = action.payload;
    },
    selectGraphDataValues(state, action) {
      state.graphDataValues = action.payload;
    },

    resetReportState(state, action) {
      state.data = {};
      state.summaryData = [];
      state.graphData = [];
      state.tankCapacity = {};
      state.periodData = [];
    },
    selectStartDate(state, action) {
      if (action.payload?.advancReport === "true") {
        if (
          moment(state.items.endDate).valueOf() -
            moment(action.payload.startDate).valueOf() >
          1000 * 60 * 60 * 24 * 31
        ) {
          state.items.startDate = moment(action.payload.startDate).locale("en");
          state.items.endDate = moment(action.payload.startDate)
            .add(31, "days")
            .endOf("day")
            .locale("en")
            .toString();
        } else {
          state.items.startDate = moment(action.payload.startDate).locale("en");
        }
      } else {
        if (
          moment(state.items.endDate).valueOf() -
            moment(action.payload.startDate).valueOf() >
          1000 * 60 * 60 * 24 * 7
        ) {
          state.items.startDate = moment(action.payload.startDate).locale("en");
          state.items.endDate = moment(action.payload.startDate)
            .add(7, "days")
            .endOf("day")
            .locale("en")
            .toString();
        } else {
          state.items.startDate = moment(action.payload.startDate).locale("en");
        }
      }
    },
    selectEndDate(state, action) {
      if (action.payload?.advancReport === "true") {
        if (
          moment(action.payload.endDate).valueOf() -
            moment(state.items.startDate).valueOf() >
          1000 * 60 * 60 * 24 * 31
        ) {
          state.items.endDate = moment(action.payload.endDate).locale("en");
          state.items.startDate = moment(action.payload.endDate)
            .subtract(31, "days")
            .startOf("day")
            .locale("en")
            .toString();
        } else {
          state.items.endDate = moment(action.payload.endDate).locale("en");
        }
      } else {
        if (
          moment(action.payload.endDate).valueOf() -
            moment(state.items.startDate).valueOf() >
          1000 * 60 * 60 * 24 * 7
        ) {
          state.items.endDate = moment(action.payload.endDate).locale("en");
          state.items.startDate = moment(action.payload.endDate)
            .subtract(7, "days")
            .startOf("day")
            .locale("en")
            .toString();
        } else {
          state.items.endDate = moment(action.payload.endDate).locale("en");
        }
      }
    },

    selectIsLastFill(state, action) {
      state.isLastFill = action.payload;
    },
    snapToRoad(state, action) {
      state.snapToRoad = action.payload;
    },
    setFilterEvents(state, action) {
      state.filterEvents = action.payload;
    },
    isOpen(state, action) {
      state.isOpen = action.payload;
    },

    setError(state, action) {
      state.values.error = action.payload;
    },
    setGraphLoading(state, action) {
      state.values.graphLoading = action.payload;
    },
    setGraphError(state, action) {
      state.values.graphLoading = action.payload;
    },
    setGoButton(state, action) {
      state.values.goButton = action.payload;
    },
    selectId(state, action) {
      state.values.id = action.payload;
    },
    selectEventTimes(state, action) {
      if (action.payload && action.payload.eventStartTime >= 0) {
        state.values.eventStartTime = action.payload.eventStartTime;
      }
      if (action.payload && action.payload.eventEndTime >= 0) {
        state.values.eventEndTime = action.payload.eventEndTime;
      }
      if (action.payload && action.payload.eventType) {
        state.values.eventType = action.payload.eventType;
      }
    },
    clearEventTimes(state, action) {
      state.values.eventStartTime = null;
      state.values.eventEndTime = null;
    },
    setSidebarsState(state, action) {
      state.sidebarsState[action.payload?.name] = action.payload?.value;
    },
    setLoading(state, action) {
      state.loading[action.payload?.name] = action.payload?.value;
    },
    setBooleanValue(state, action) {
      state.booleanValue[action.payload?.key] = action.payload?.value;
    },
    setRange(state, action) {
      state.range = action.payload;
    },

    setActiveDuration(state, action) {
      state.activeDuration = action.payload;
    },

    setStartDate(state, action) {
      if (action.payload?.advancReport === "true") {
        if (
          moment(
            action.payload?.period ? state.period.endDate : state.dates.endDate
          ).valueOf() -
            moment(action.payload.startDate).valueOf() >
          1000 * 60 * 60 * 24 * 31
        ) {
          if (action.payload?.isPeriod === "true") {
            state.period.startDate = moment(action.payload.startDate).locale(
              "en"
            );
          } else {
            state.dates.startDate = moment(action.payload.startDate).locale(
              "en"
            );
          }

          if (action.payload?.isPeriod === "true") {
            state.period.endDate = moment(action.payload.startDate)
              .add(31, "days")
              .endOf("day")
              .locale("en")
              .toString();
          } else {
            state.dates.endDate = moment(action.payload.startDate)
              .add(31, "days")
              .endOf("day")
              .locale("en")
              .toString();
          }
        } else {
          if (action.payload?.isPeriod === "true") {
            state.period.startDate = moment(action.payload.startDate).locale(
              "en"
            );
          } else {
            state.dates.startDate = moment(action.payload.startDate).locale(
              "en"
            );
          }
        }
      } else {
        if (
          moment(
            action.payload?.period ? state.period.endDate : state.dates.endDate
          ).valueOf() -
            moment(action.payload.startDate).valueOf() >
          1000 * 60 * 60 * 24 * 7
        ) {
          if (action.payload?.isPeriod === "true") {
            state.period.startDate = moment(action.payload.startDate).locale(
              "en"
            );
          } else {
            state.dates.startDate = moment(action.payload.startDate).locale(
              "en"
            );
          }

          if (action.payload?.isPeriod === "true") {
            state.period.endDate = moment(action.payload.startDate)
              .add(7, "days")
              .endOf("day")
              .locale("en")
              .toString();
          } else {
            state.dates.endDate = moment(action.payload.startDate)
              .add(7, "days")
              .endOf("day")
              .locale("en")
              .toString();
          }
        } else {
          if (action.payload?.isPeriod === "true") {
            state.period.startDate = moment(action.payload.startDate).locale(
              "en"
            );
          } else {
            state.dates.startDate = moment(action.payload.startDate).locale(
              "en"
            );
          }
        }
      }
    },
    setEndDate(state, action) {
      if (action.payload?.advancReport === "true") {
        if (
          moment(action.payload.endDate).valueOf() -
            moment(
              action.payload?.period
                ? state.period.startDate
                : state.dates.startDate
            ).valueOf() >
          1000 * 60 * 60 * 24 * 31
        ) {
          if (action.payload?.isPeriod === "true") {
            state.period.endDate = moment(action.payload.endDate).locale("en");
          } else {
            state.dates.endDate = moment(action.payload.endDate).locale("en");
          }

          if (action.payload?.isPeriod === "true") {
            state.period.startDate = moment(action.payload.endDate)
              .subtract(31, "days")
              .startOf("day")
              .locale("en")
              .toString();
          } else {
            state.dates.startDate = moment(action.payload.endDate)
              .subtract(31, "days")
              .startOf("day")
              .locale("en")
              .toString();
          }
        } else {
          if (action.payload?.isPeriod === "true") {
            state.period.endDate = moment(action.payload.endDate).locale("en");
          } else {
            state.dates.endDate = moment(action.payload.endDate).locale("en");
          }
        }
      } else {
        if (
          moment(action.payload.endDate).valueOf() -
            moment(
              action.payload?.period
                ? state.period.startDate
                : state.dates.startDate
            ).valueOf() >
          1000 * 60 * 60 * 24 * 7
        ) {
          if (action.payload?.isPeriod === "true") {
            state.period.endDate = moment(action.payload.endDate).locale("en");
          } else {
            state.dates.endDate = moment(action.payload.endDate).locale("en");
          }

          if (action.payload?.isPeriod === "true") {
            state.period.startDate = moment(action.payload.endDate)
              .subtract(7, "days")
              .startOf("day")
              .locale("en")
              .toString();
          } else {
            state.dates.startDate = moment(action.payload.endDate)
              .subtract(7, "days")
              .startOf("day")
              .locale("en")
              .toString();
          }
        } else {
          if (action.payload?.isPeriod === "true") {
            state.period.endDate = moment(action.payload.endDate).locale("en");
          } else {
            state.dates.endDate = moment(action.payload.endDate).locale("en");
          }
        }
      }
    },
  },
});

export { actions as selectorActions, reducer as selectorReducer };
