import { Close } from "@mui/icons-material";
import { Drawer as Dra } from "@mui/material";
import { Fragment, useState } from "react";
import RoundedButton from "./RoundedButton";
import CommonLayout from "../../layout/Components/CommonLayout";

function Drawer({
  children,
  icon,
  position = "right",
  Rounded = true,
  className,
  isMap,
  classTour,
}) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      {Rounded ? (
        <div className={`${classTour} h-fit w-fit`}>
          <RoundedButton className={classTour} onClick={toggleDrawer}>
            {icon}
          </RoundedButton>
        </div>
      ) : (
        <span
          onClick={toggleDrawer}
          className={`${classTour} cursor-pointer z-[9999]`}
        >
          {icon}
        </span>
      )}

      <Dra variant="temporary" anchor={position} open={open}>
        <div
          // onClick={toggleDrawer}
          className={`${className} w-full lg:w-fit min-w-[350px]`}
        >
          <CommonLayout isMap={isMap}>
            <span
              onClick={toggleDrawer}
              className="cursor-pointer w-full flex justify-end"
            >
              <Close />
            </span>
            {children}
          </CommonLayout>
        </div>
      </Dra>
    </Fragment>
  );
}

export default Drawer;
