import TextField from "@mui/material/TextField";
import React, { useRef } from "react";

const InputMUI = ({
  value,
  min,
  max,
  type,
  step,
  name,
  onChange,
  label,
  disabled,
  readOnly,
  required,
  error,
  textTransform,
  helperText,
  defaultValue,
  ref,
  autoComplete,
  className,
  fullWidth,
  id,
  InputLabelProps,
  endAdornment,
  multiline,
  maxRows,
  onKeyPress,
  maxLength,
  minLength,
}) => {
  const inputRef = useRef();
  return (
    <TextField
      id={id}
      className={className}
      ref={ref}
      defaultValue={defaultValue}
      error={error}
      required={required}
      onKeyPress={onKeyPress}
      label={label}
      disabled={disabled}
      value={value}
      helperText={helperText}
      type={type}
      name={name}
      fullWidth={fullWidth}
      onChange={onChange}
      InputProps={{
        endAdornment: endAdornment,
        readOnly: readOnly,
        inputProps: {
          step: step,
          max: max,
          min: min,
          style: {
            textTransform: textTransform,
          },
          maxLength: maxLength,
          minLength: minLength,
        },
      }}
      multiline={multiline}
      maxRows={maxRows}
      autoComplete={autoComplete}
      size="small"
      variant="filled"
      InputLabelProps={InputLabelProps}
      inputRef={inputRef}
      onClick={() => {
        if (!disabled && !readOnly) {
          inputRef?.current?.showPicker();
        }
      }}
    />
  );
};

export default InputMUI;
