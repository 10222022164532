import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "settings",
  initialState: {
    items: {},
    devices: [],
    mainDevices: [],
    allDevices: null,
    switchValue: {
      fuelEvents: false,
      batteryStatus: false,
      fuelSensorStatus: false,
      ignitionStatus: false,
      maintenanceEvents: false,
      documentExpiryEvents: false,
      driverBehaviour: false,
    },
    districtName: null,
    districtPrice: null,
    manualPrice: null
  },
  reducers: {
    reset(state, action) {
      state.items = {};
      state.devices = [];
      state.mainDevices = [];
      state.allDevices = false;
      state.switchValue = {};
      state.switchValue["fuelEvents"] = false;
      state.switchValue["batteryStatus"] = false;
      state.switchValue["fuelSensorStatus"] = false;
      state.switchValue["ignitionStatus"] = false;
      state.switchValue["maintenanceEvents"] = false;
      state.switchValue["documentExpiryEvents"] = false;
      state.switchValue["driverBehaviour"] = false;
      state.switchValue["geofenceEvents"] = false;
      state.districtName = null;
      state.districtPrice = null;
      state.manualPrice = null;
    },
    setEvents(state, action) {
      state.items = action.payload;
    },
    setDevices(state, action) {
      state.devices = action.payload;

      if (state.mainDevices?.length === state.devices?.length) {
        state.allDevices = true;
      } else {
        state.allDevices = false;
      }

      if (!action.payload.length) {
        state.switchValue["fuelEvents"] = false;
        state.switchValue["batteryStatus"] = false;
        state.switchValue["fuelSensorStatus"] = false;
        state.switchValue["ignitionStatus"] = false;
        state.switchValue["maintenanceEvents"] = false;
        state.switchValue["documentExpiryEvents"] = false;
        state.switchValue["driverBehaviour"] = false;
        state.switchValue["geofenceEvents"] = false;
      } else {
        const filtered = Object.keys(state.items).filter(
          (key) =>
            !action.payload.length || action.payload.includes(parseInt(key))
        );

        const fuelEvents = Object.values(filtered).filter(
          (event) => state.items[event].fuelEvents === false
        );
        state.switchValue["fuelEvents"] = fuelEvents?.length > 0 ? false : true;

        const batteryStatus = Object.values(filtered).filter(
          (event) => state.items[event].batteryStatus === false
        );
        state.switchValue["batteryStatus"] =
          batteryStatus?.length > 0 ? false : true;

        const fuelSensorStatus = Object.values(filtered).filter(
          (event) => state.items[event].fuelSensorStatus === false
        );
        state.switchValue["fuelSensorStatus"] =
          fuelSensorStatus?.length > 0 ? false : true;

        const ignitionStatus = Object.values(filtered).filter(
          (event) => state.items[event].ignitionStatus === false
        );
        state.switchValue["ignitionStatus"] =
          ignitionStatus?.length > 0 ? false : true;

        const maintenanceEvents = Object.values(filtered).filter(
          (event) => state.items[event].maintenanceEvents === false
        );

        state.switchValue["maintenanceEvents"] =
          maintenanceEvents?.length > 0 ? false : true;

        const documentExpiryEvents = Object.values(filtered).filter(
          (event) => state.items[event].documentExpiryEvents === false
        );
        state.switchValue["documentExpiryEvents"] =
          documentExpiryEvents?.length > 0 ? false : true;

        const driverBehaviour = Object.values(filtered).filter(
          (event) => state.items[event].driverBehaviour === false
        );
        state.switchValue["driverBehaviour"] =
          driverBehaviour?.length > 0 ? false : true;

        const geofenceEvents = Object.values(filtered).filter(
          (event) => state.items[event].geofenceEvents === false
        );
        state.switchValue["geofenceEvents"] =
          geofenceEvents?.length > 0 ? false : true;
      }
    },
    setToggle(state, action) {
      state.allDevices = Boolean(action.payload.value);
      if (action.payload.value && action.payload.keys) {
        state.devices = action.payload.keys;
        state.mainDevices = action.payload.keys;
      } else {
        state.devices = [];
      }
    },
    setEdit(state, action) {
      state.switchValue[action.payload.name] = Boolean(action.payload.value);
    },
    setDistrictName(state, action) {
      state.districtName = action.payload;
    },
    setDistrictPrice(state, action) {
      state.districtPrice = action.payload;
    },
    setManualPrice(state, action) {
      state.manualPrice = action.payload;
    },
    setPriceToUse(state, action) {
      state.priceToUse = action.payload;
    }
  },
});

export { actions as settingsActions };
export { reducer as settingsReducer };
