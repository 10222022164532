import { t } from "i18next";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SummaryItem from "../Components/Common/SummaryItem";
import DownloadPdf from "../Pages/Reports/components/DownloadPdf";
import DownloadReport from "../Pages/Reports/components/DownloadReport";
import CustomeBackdrop from "../common/common/Backdrop";

const CurrentDayFleetSummaryCards = () => {
  const loading = useSelector((state) => state.data.loading, shallowEqual);
  const data = useSelector((state) => state.selectors.items, shallowEqual);
  const currentDayFleetTotals = useSelector(
    (state) => state.data.currentDayFleetTotals,
    shallowEqual
  );
  const currentDayFleetData = useSelector(
    (state) => state.data.currentDayFleetData,
    shallowEqual
  );
  const devices = useSelector(
    (state) => state.devices.mainDevicesMap,
    shallowEqual
  );
  const districtPrice = useSelector(
    (state) => state.settings.districtPrice,
    shallowEqual
  );
  const manualPrice = useSelector(
    (state) => state.settings.manualPrice,
    shallowEqual
  );

  const priceToUse = manualPrice > 0 ? manualPrice : districtPrice;

  return (
    <div className="flex flex-col relative px-2 gap-y-3">
      <CustomeBackdrop position="absolute" open={loading} />

      <h6>{t("currentDayFleetSummary")}</h6>

      <DownloadReport
        fullWidth
        data={{
          summary: currentDayFleetTotals,
          fleetSummaryData: currentDayFleetData,
        }}
        report="fleet_summary"
        devices={devices}
        startDate={data.startDate}
        endDate={data.endDate}
      />
      <DownloadPdf
        fullWidth
        devices={devices}
        data={{
          summary: currentDayFleetTotals,
          fleetSummaryData: currentDayFleetData,
        }}
        report="fleet_summary"
        endDate={data.endDate}
        fromDate={data.startDate}
      />

      <Link
        to="/reports/fleetSummary"
        className="flex flex-col py-3 gap-y-3 relative flex-1 cursor-pointer "
      >
        <SummaryItem
          title={t("fuelFilled")}
          value={currentDayFleetTotals?.fuelFilled}
          units="L"
          cost={`₹ ${(
            (currentDayFleetTotals?.fuelFilled || 0) * (priceToUse || 0)
          ).toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
        />

        <SummaryItem
          title={t("fuelDrained")}
          value={currentDayFleetTotals?.fuelDrained}
          units="L"
          cost={`₹ ${(
            (currentDayFleetTotals?.fuelDrained || 0) * (priceToUse || 0)
          ).toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
        />

        <SummaryItem
          title={t("fuelConsumed")}
          value={currentDayFleetTotals?.fuelConsumed}
          units="L"
          cost={`₹ ${(
            (currentDayFleetTotals?.fuelConsumed || 0) * (priceToUse || 0)
          ).toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
        />

        <SummaryItem
          title={t("kmsCovered")}
          value={currentDayFleetTotals?.kmsCovered}
          units="KM"
          cost={`${
            (
              ((currentDayFleetTotals?.fuelConsumed || 0) * (priceToUse || 0)) /
                (currentDayFleetTotals?.kmsCovered || 0) || 0
            ).toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0
          } ₹/KM`}
        />

        <SummaryItem
          title={t("hoursRun")}
          value={currentDayFleetTotals?.hoursRun}
          units="H"
          cost={`${(
            ((currentDayFleetTotals?.fuelConsumed || 0) * (priceToUse || 0)) /
              (currentDayFleetTotals?.hoursRun || 0) || 0
          ).toLocaleString(undefined, { maximumFractionDigits: 2 })} ₹/H`}
        />
      </Link>
    </div>
  );
};

export default CurrentDayFleetSummaryCards;
