import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BottomMenu from "../../Components/Common/BottomMenu";
import Footer from "../../Components/Common/Footer";
import FeatureFlags from "../../Components/FeatureFlags/FeatureFlags";
import SettingsLayout from "../../Pages/Settings/components/SettingsLayout";
import LeftMenu from "../../common/components/LeftMenu";
import useWindowSize from "../../common/hooks/useWindowSize";
import HasDeviceListLayout from "./HasDeviceListLayout";

const PageLayout = ({ children }) => {
  const { width } = useWindowSize();

  const { pathname } = useLocation();

  const user = useSelector((state) => state.session.user, shallowEqual);
  const plans = useSelector((state) => state.subscriptions.plans, shallowEqual);
  const subscriptions = useSelector(
    (state) => state.subscriptions.items,
    shallowEqual
  );

  let fleetLevelDetails = user && user.administrator === true;
  let advancedAnalysis = user && user.administrator === true;
  let hasFuel = user && user.administrator === true;

  if (!fleetLevelDetails || !advancedAnalysis) {
    fleetLevelDetails =
      user?.attributes?.analytics === true ||
      user?.attributes?.analytics === "true";
    advancedAnalysis =
      user?.attributes?.analytics === true ||
      user?.attributes?.analytics === "true";

    Object.keys(subscriptions).forEach((subsId) => {
      if (!fleetLevelDetails) {
        fleetLevelDetails = FeatureFlags.includes(
          "fleet_fuel_report",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }

      if (!advancedAnalysis) {
        advancedAnalysis = FeatureFlags.includes(
          "advanced_fuel_analysis",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }

      if (!hasFuel) {
        hasFuel = FeatureFlags.includes(
          "fuel_events",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }
    });
  }

  const Layout = () => {
    if (pathname.includes("settings")) {
      return <SettingsLayout>{children}</SettingsLayout>;
    } else if (
      pathname.includes("advancedAnalytics") ||
      pathname.includes("reports") ||
      pathname.includes("map") ||
      (!hasFuel && !fleetLevelDetails && !advancedAnalysis && pathname === "/")
    ) {
      return <HasDeviceListLayout>{children}</HasDeviceListLayout>;
    } else {
      return children;
    }
  };

  return (
    <div className="h-screen w-screen flex flex-col lg:flex-row">
      {width > 1023 && <LeftMenu />}

      <div className="flex flex-col flex-1 h-full overflow-auto">
        <main className="flex-1 overflow-auto">{Layout()}</main> <Footer />
      </div>

      {width < 1024 && <BottomMenu />}
    </div>
  );
};

export default PageLayout;
