import { CircularProgress } from "@mui/material";
import React from "react";

const Spinner = () => {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <CircularProgress sx={{ color: "#F07023" }} />
    </div>
  );
};

export default Spinner;
