import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "errors",
  initialState: {
    errors: [],
    isLoading: false,
  },
  reducers: {
    push(state, action) {
      state.errors.push(action.payload);
    },
    pop(state) {
      if (state.errors.length) {
        state.errors.shift();
      }
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export { actions as errorsActions };
export { reducer as errorsReducer };
