import { createSlice } from "@reduxjs/toolkit";
import { Capacitor } from "@capacitor/core";

const { reducer, actions } = createSlice({
  name: "drivers",
  initialState: {
    items: {},
    platform: Capacitor.getPlatform(),
    modelName: "iPhone 14 pro",
  },
  reducers: {
    update(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload?.forEach((item) => (state.items[item.id] = item));
      }
    },
    setmodelName(state, action) {
      state.modelName = action.payload;
    },
  },
});

export { actions as driversActions };
export { reducer as driversReducer };
