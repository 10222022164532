import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "geofences",
  initialState: {
    items: {},
    showSatelliteView: null, // TODO: rebase on the other states branch, and then move this into it's own state/store obj
    active: true,
    setByAllButton: true, // Initial state = all devices must be visible
    visibleitems: {},
    geofenceId: null,
    geofence: {},
  },
  reducers: {
    reset(state, action) {
      state.items = {};
      state.selectedLocation = null;
      state.distance = {};
    },
    refresh(state, action) {
      state.items = {};
      if (!Array.isArray(action.payload)) {
        return;
      }
      action.payload?.forEach((item) => {
        let geofence = {};

        const polygonString = item?.area;

        if (polygonString?.includes("CIRCLE")) {
          const match = polygonString.match(
            /CIRCLE \(([^ ]+) ([^ ]+), ([^ ]+)\)/
          );
          if (match) {
            const latitude = parseFloat(match[1]);
            const longitude = parseFloat(match[2]);
            const radius = parseFloat(match[3]);

            geofence["type"] = "circle";
            geofence["center"] = { lat: latitude, lng: longitude };
            geofence["radius"] = radius;
            geofence["id"] = item?.id;
            geofence["name"] = item?.name;
            geofence["color"] = item?.attributes?.color;
          } else {
            throw new Error("Invalid CIRCLE data format");
          }
        } else {
          const coordinates = polygonString?.match(/\d+.\d+/g);

          let latLng = [];

          for (let i = 0; i < coordinates?.length; i += 2) {
            latLng.push({
              lat: parseFloat(coordinates[i]),
              lng: parseFloat(coordinates[i + 1]),
            });
          }

          geofence["type"] = "polygon";
          geofence["polygon"] = latLng;
          geofence["id"] = item?.id;
          geofence["name"] = item?.name;
          geofence["color"] = item?.attributes?.color;
        }

        state.items[item.id] = geofence;

        state.visibleitems[item.id] = true;
      });
    },
    update(state, action) {
      if (!Array.isArray(action.payload)) {
        return;
      }
      action.payload?.forEach((item) => {
        let geofence = {};

        const polygonString = item?.area;

        if (polygonString?.includes("CIRCLE")) {
          const match = polygonString.match(
            /CIRCLE \(([^ ]+) ([^ ]+), ([^ ]+)\)/
          );
          if (match) {
            const latitude = parseFloat(match[1]);
            const longitude = parseFloat(match[2]);
            const radius = parseFloat(match[3]);

            geofence["type"] = "circle";
            geofence["center"] = { lat: latitude, lng: longitude };
            geofence["radius"] = radius;
            geofence["id"] = item?.id;
            geofence["name"] = item?.name;
            geofence["color"] = item?.attributes?.color;
          } else {
            throw new Error("Invalid CIRCLE data format");
          }
        } else {
          const coordinates = polygonString?.match(/\d+.\d+/g);

          let latLng = [];

          for (let i = 0; i < coordinates?.length; i += 2) {
            latLng.push({
              lat: parseFloat(coordinates[i]),
              lng: parseFloat(coordinates[i + 1]),
            });
          }

          geofence["type"] = "polygon";
          geofence["polygon"] = latLng;
          geofence["id"] = item?.id;
          geofence["name"] = item?.name;
          geofence["color"] = item?.attributes?.color;
        }

        state.items[item.id] = geofence;
        if (state.visibleitems[item.id] === undefined) {
          // If a new device shows up, make it visible by default.
          state.visibleitems[item.id] = true;
        }
      });
    },

    setVisibility(state, action) {
      if (state.visibleitems[action.payload.deviceId] === undefined) {
        state.visibleitems[action.payload.deviceId] = action.payload;
      } else {
        state.visibleitems[action.payload.deviceId] = action.payload.visible;
      }

      if (action.payload.visible === false) {
        state.active = action.payload = false;
      }

      state.setByAllButton = false;

      var hides = 0;
      for (var deviceId in state.visibleitems) {
        if (state.visibleitems[deviceId] === false) {
          hides++;
        }
      }

      if (hides === 0) {
        state.active = true;
        state.setByAllButton = true;
      }
    },
    setShowAll(state, action) {
      state.active = action.payload;

      state.setByAllButton = true;

      for (var deviceId in state.visibleitems) {
        state.visibleitems[deviceId] = action.payload;
      }
    },
    setShowSatelliteView(state, action) {
      state.showSatelliteView = action.payload;
    },
    selectGeofenceId(state, action) {
      state.geofenceId = action.payload;
    },

    deleteGeofence(state, action) {
      delete state.items[action.payload];
    },

    setGeofence(state, action) {
      if (action.payload?.includes("CIRCLE")) {
        const match = action.payload.match(
          /CIRCLE \(([^ ]+) ([^ ]+), ([^ ]+)\)/
        );
        if (match) {
          const latitude = parseFloat(match[1]);
          const longitude = parseFloat(match[2]);
          const radius = parseFloat(match[3]);

          state.geofence["type"] = "circle";
          state.geofence["center"] = { lat: latitude, lng: longitude };
          state.geofence["radius"] = radius;
          state.geofence["area"] = action.payload;
          state.geofence["polygon"] = [
            { lat: 21.14631, lng: 79.08491 },
            { lat: 21.14631, lng: 79.08491 },
          ];
        } else {
          throw new Error("Invalid CIRCLE data format");
        }
      } else {
        const coordinates = action.payload?.match(/\d+.\d+/g);

        let latLng = [];

        for (let i = 0; i < coordinates?.length; i += 2) {
          latLng.push({
            lat: parseFloat(coordinates[i]),
            lng: parseFloat(coordinates[i + 1]),
          });
        }

        state.geofence["type"] = "polygon";
        state.geofence["polygon"] = latLng;
        state.geofence["area"] = action.payload;
        state.geofence["center"] = null;
        state.geofence["radius"] = 0;
      }
    },
  },
});

export { actions as geofencesActions };
export { reducer as geofencesReducer };
