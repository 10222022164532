import moment from "moment";
import "moment/min/locales.min";
import { useParams } from "react-router-dom";

export const fuelFormatter = (fuel) => {
  return fuel.toFixed(1) + "Liters";
};

export const deviceId = () => {
  const { id } = useParams();
  return id;
};

export const Id = (pathname) => {
  const segment_array = pathname.split("/");

  return segment_array.pop();
};

export const removeDeviceIdFromPath = (pathname) => {
  const segment_array = pathname?.split("/");
  segment_array?.pop();
  segment_array?.push("null");
  return segment_array?.join("/");
};

export const dateTimeFormatter = (date) =>
  moment(date, "YYYY-MM-DDTHH:mm:ss").locale("en").format("DD/MM/YY HH:mm:a");

export const startDate = (date) => {
  const fromDate = moment(date).locale("en").utc().format();
  return fromDate;
};

export const endDate = (date) => {
  const toDate = moment(date).locale("en").utc().format();
  return toDate;
};

export const UTCFormat = (date) => {
  const toDate = moment(date).locale("en").utc().format();
  return toDate;
};

export const path = (path, id) => {
  if (path?.includes("reports/info")) {
    return `/reports/info/${id}`;
  } else if (path?.includes("reports/advanced_fuel")) {
    return `/reports/advanced_fuel/${id}`;
  } else if (path?.includes("reports/basicFuel")) {
    return `/reports/basicFuel/${id}`;
  } else if (path?.includes("reports/events")) {
    return `/reports/events/${id}`;
  } else if (path?.includes("reports/user_Manual_Entries")) {
    return `/reports/user_Manual_Entries/${id}`;
  } else if (path?.includes("reports/fuelAnalysis")) {
    return `/reports/fuelAnalysis/${id}`;
  } else if (path?.includes("/reports/tracking_live")) {
    return `/reports/tracking_live/${id}`;
  } else if (path?.includes("/reports/fleetSummary")) {
    return `/reports/basicFuel/${id}`;
  } else if (path?.includes("/reports/tracking_old")) {
    return `/reports/tracking_old/${id}`;
  } else if (path?.includes("reports/configuration")) {
    return `/reports/configuration/${id}`;
  } else if (path?.includes("/dailyFuelAnalytics")) {
    return `/advancedAnalytics/dailyFuelAnalytics/${id}`;
  } else if (path?.includes("/periodOverPeriod")) {
    return `/advancedAnalytics/periodOverPeriod/${id}`;
  } else if (path?.includes("/VehicleVSVehicle")) {
    return `/advancedAnalytics/VehicleVSVehicle/${id}`;
  } else {
    return `/`;
  }
};
