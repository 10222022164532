import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import React, { memo, useEffect, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Spinner from "../components/Spinner";

const libraries = ["places", "geometry", "drawing"];

const GoogleMapWrapper = ({ children, mapRef, onClick }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDXhcBGPRr2Grol6oYM_BzuNjSzzxnuts4",
    libraries: libraries,
  });

  const showSatelliteView = useSelector(
    (state) => state.geofences.showSatelliteView,
    shallowEqual
  );

  const geofenceId = useSelector(
    (state) => state.geofences.geofenceId,
    shallowEqual
  );
  const geofences = useSelector(
    (state) => state.geofences.items[geofenceId],
    shallowEqual
  );

  const onMapLoad = (map) => {
    mapRef.current = map;
  };

  const calculateCenter = (path) => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();

      if (path.type === "circle") {
        mapRef.current.panTo(path?.center);
      } else {
        path?.polygon?.forEach((point) => bounds.extend(point));

        mapRef.current.panTo(bounds?.getCenter());
      }

      mapRef.current.setZoom(14);
    }
  };

  useEffect(() => {
    calculateCenter(geofences);
  }, [geofenceId]);

  /* map Type functionality(road map view or satellite view) start*/

  const stickyValue = window.localStorage.getItem("mapTypeId");

  const mapType = Boolean(stickyValue) ? "hybrid" : "roadmap";
  const MapTypeId = (type) => {
    if (mapRef.current) {
      mapRef.current.setMapTypeId(type);
    }
  };
  useEffect(() => {
    const type = showSatelliteView ? "hybrid" : "roadmap";
    MapTypeId(type);
  }, [showSatelliteView]);

  /* map Type functionality(road map view or satellite view) end*/

  const GoogleMapMemo = useMemo(
    () => (
      <GoogleMap
        clickableIcons={false}
        ref={mapRef}
        onLoad={onMapLoad}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        options={{
          mapTypeId: mapType,
          minZoom: 2.4,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControl: true,
          askUserLocation: false,
          restriction: {
            latLngBounds: { north: 83.8, south: -57, west: -180, east: 180 },
          },
        }}
        center={{ lat: 21.1458, lng: 79.0882 }}
        zoom={14}
        onClick={onClick}
      >
        {children}
      </GoogleMap>
    ),
    []
  );

  if (loadError) {
    return (
      <div className="flex justify-center h-screen w-screen items-center text-orange-500">
        <span>Map cannot be loaded right now, sorry.</span>
      </div>
    );
  }
  if (!isLoaded) {
    return <Spinner />;
  }

  return GoogleMapMemo;
};

export default memo(GoogleMapWrapper);
