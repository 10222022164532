import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const CustomeBackdrop = ({ open, position }) => {
  return (
    <Backdrop sx={{ position: position, zIndex: 2 }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CustomeBackdrop;
