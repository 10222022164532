import { t } from "i18next";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APIWrapper from "./APIWrapper/APIWrapper";
import Footer from "./Components/Common/Footer";
import { RangrLogo } from "./assets/images";
import LinearProgress from "./common/components/LinearProgress";
import { useEffectAsync } from "./reactHelper";
import { sessionActions } from "./store";

const ServerProvider = ({ children }) => {
  const dispatch = useDispatch();

  const initialized = useSelector((state) => !!state.session.server);
  const [error, setError] = useState(null);

  useEffectAsync(async () => {
    if (!error) {
      try {
        const server = await APIWrapper.HttpModule().get("/api/server");
        dispatch(sessionActions.updateServer(server));
      } catch (error) {
        setError(t("somethingWentWrong"));
      }
    }
  }, [error]);

  if (error) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-between">
        <div className="xl:w-1/2 flex-1 flex flex-col items-center justify-center text-center px-4 lg:px-0">
          <img src={RangrLogo} style={{ width: "64px", height: "55px" }} />
          <p className="text-4xl font-bold text-[#F07023] capitalize tracking-wide mt-2">
            Rangr
          </p>
          <p className="text-4xl font-bold text-gray-700 capitalize tracking-wide mt-8">
            is currently under maintenance
          </p>
          <p className="text-xl text-gray-700 uppercase mt-4">
            We'll be back soon
          </p>
        </div>
        <div className="absolute bottom-0  left-0 bg-white w-full flex justify-center">
          <Footer />
        </div>
      </div>
    );
  }

  if (!initialized) {
    return <LinearProgress />;
  }
  return children;
};

export default ServerProvider;
