import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "subscriptions",
  initialState: {
    items: {},
    plans: {},
    devicesEnabled: {},
    filterBy: "",
    totalEnabled: 0
  },
  reducers: {
    reset(state, action) {
      state.items = {};
      state.plans = {};
      state.active = null;
      state.devicesEnabled = {};
      state.totalEnabled = 0;
    },
    refresh(state, action) {
      if (action.payload) {
        state.items = {};
        state.items[action.payload.id] = action.payload;
      }
    },
    refreshPlans(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.plans = {};
        action.payload.forEach((planItem) => {
          state.plans[planItem.id] = planItem;
        })
        
      }
    },
    refreshRazorpaySubscriptions(state, action) {
      state.items = {};
      if (action.payload && Object.keys(action.payload).length > 0) {
        if (action.payload.count && action.payload.count > 0 && action.payload.items) {
          action.payload.items.forEach((subsItem) => {
            state.items[subsItem.id] = subsItem;
          });
        }
        else if (action.payload.id && action.payload.plan_id) {
          state.items[action.payload.id] = action.payload;
        }
      }
    },
    refreshPlansRazorpayPlans(state, action) {
      state.plans = {};
      if (action.payload && Object.keys(action.payload).length > 0) {
        if (action.payload.count && action.payload.count > 0 && action.payload.items) {
          action.payload.items.forEach((planItem) => {
            state.plans[planItem.id] = planItem;
          });
        }
      }
    },
    
    refreshDeviceActivationStates(state, action) {
      const devices = action.payload;
      state.totalEnabled = 0;
      devices.forEach((device) => {
        if (device.disabled === false) {
          state.devicesEnabled[device.id] = true;
          state.totalEnabled += 1;
        }
      });
    },
    
    setActive(state, action) {
      state.active = action.payload;
    },
    
    toggleDeviceActiveState(state, action) {
      const deviceId = action.payload;
      if (deviceId) {
        if (state.devicesEnabled[deviceId] === true) {
          delete state.devicesEnabled[deviceId];
          state.totalEnabled -= 1;
        } else {
          state.devicesEnabled[deviceId] = true;
          state.totalEnabled += 1;
        }
      }
    },
    
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    }
  }
});

export { actions as subscriptionActions };
export { reducer as subscriptionReducer };
