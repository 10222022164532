import {
  Insights,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Map,
} from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import { Badge, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import FeatureFlags from "../../Components/FeatureFlags/FeatureFlags";
import {
  Dasboard,
  DasboardActive,
  Home,
  HomeActive,
  Notifications,
  NotificationsActive,
  Settings,
  SettingsActive,
} from "../../assets/svgs";
import { devicesActions, selectorActions } from "../../store";

const LeftMenu = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const dispatch = useDispatch();

  const events = useSelector((state) => state.events.items, shallowEqual);
  const readId = useSelector((state) => state.events.readId, shallowEqual);
  const user = useSelector((state) => state.session.user, shallowEqual);
  const plans = useSelector((state) => state.subscriptions.plans, shallowEqual);
  const subscriptionsActive = useSelector(
    (state) => !!state.subscriptions.active,
    shallowEqual
  );
  const selectedDeviceId = useSelector(
    (state) => state.devices.selectedId,
    shallowEqual
  );
  const subscriptions = useSelector(
    (state) => state.subscriptions.items,
    shallowEqual
  );

  const eventsCount = Object.keys(events).length;
  const readCount = Object.keys(readId).length;
  const unread = eventsCount - readCount;

  let fleetLevelDetails = user && user.administrator === true;
  let advancedAnalysis = user && user.administrator === true;
  let hasFuel = user && user.administrator === true;

  if (!fleetLevelDetails || !advancedAnalysis) {
    fleetLevelDetails =
      user?.attributes?.analytics === true ||
      user?.attributes?.analytics === "true";
    advancedAnalysis =
      user?.attributes?.analytics === true ||
      user?.attributes?.analytics === "true";

    Object.keys(subscriptions).forEach((subsId) => {
      if (!fleetLevelDetails) {
        fleetLevelDetails = FeatureFlags.includes(
          "fleet_fuel_report",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }

      if (!advancedAnalysis) {
        advancedAnalysis = FeatureFlags.includes(
          "advanced_fuel_analysis",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }

      if (!hasFuel) {
        hasFuel = FeatureFlags.includes(
          "fuel_events",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }
    });
  }

  const [isOpen, setIsOpen] = useState(true);
  const [isFleetOpen, setIsFleetOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const NavItem = ({ className, active, hidden, onClick, children, label }) => {
    return (
      <Tooltip disableHoverListener={isOpen} title={label} placement="right">
        <li
          className={`flex items-center mt-6 cursor-pointer transition-all duration-300 ease-in-out
           ${hidden ? "hidden" : ""} ${className}`}
          onClick={onClick}
        >
          <span className="w-6 flex justify-center">{children}</span>
          <span
            className={`whitespace-nowrap pl-2 transition-all duration-1000 ease-in ${
              isOpen ? "opacity-100" : "opacity-0"
            } ${active ? "text-[#F07023]" : "text-black"}`}
          >
            {isOpen && label}
          </span>
        </li>
      </Tooltip>
    );
  };

  return (
    <div
      className={`hidden z-[9] relative lg:flex transition-all duration-100 ease-in-out z-1
       bg-gray-100 shadow-lg h-screen px-[14px]
        ${isOpen ? "w-48" : "w-14"}`}
    >
      <nav className="w-full">
        <ul className="flex flex-col">
          <NavItem onClick={toggleSidebar}>
            {isOpen ? (
              <KeyboardDoubleArrowLeft />
            ) : (
              <KeyboardDoubleArrowRight />
            )}
          </NavItem>

          <Link to="/">
            <NavItem
              active={location.pathname === "/"}
              label={t("dashboard")}
              hidden={!subscriptionsActive}
              onClick={() => {
                if (subscriptionsActive) {
                  dispatch(devicesActions.select(null));
                }
              }}
            >
              {location.pathname === "/" ? HomeActive : Home}
            </NavItem>
          </Link>

          {(fleetLevelDetails || advancedAnalysis || hasFuel) && (
            <Link to="/map">
              <NavItem
                active={location.pathname === "/map"}
                label={t("map")}
                hidden={!subscriptionsActive}
                onClick={() => {
                  if (subscriptionsActive) {
                    dispatch(devicesActions.select(null));
                  }
                }}
              >
                <Map
                  sx={{
                    color: location.pathname === "/map" ? "#F07023" : "black",
                  }}
                />
              </NavItem>
            </Link>
          )}

          {/* {fleetLevelDetails && subscriptionsActive && (
            <>
              <NavItem
                active={isFleetOpen}
                label="fleetSummary"
                hidden={!subscriptionsActive}
                onClick={() => setIsFleetOpen(true)}
              >
                <LocalGasStationRoundedIcon
                  sx={{ color: isFleetOpen ? "#F07023" : "black" }}
                />
              </NavItem>

              {isFleetOpen && (
                <div
                  className="absolute h-screen left-full bg-white shadow-md
                 overflow-hidden transition-all duration-300 ease-in-out  overflow-y-auto"
                >
                  <IconButton
                    sx={{
                      zIndex: 999999,
                      marginLeft: "80%",
                    }}
                    onClick={() => setIsFleetOpen(false)}
                  >
                    <KeyboardDoubleArrowLeft />
                  </IconButton>

                  <CurrentDayFleetSummaryCards />
                </div>
              )}
            </>
          )} */}

          <Link
            to={
              subscriptionsActive && fleetLevelDetails
                ? "/reports/fleetSummary"
                : hasFuel
                ? `/reports/basicFuel/${
                    selectedDeviceId ? selectedDeviceId : "null"
                  }`
                : `/reports/events/${
                    selectedDeviceId ? selectedDeviceId : "null"
                  }`
            }
          >
            <NavItem
              active={location.pathname.includes("/reports")}
              label={t("reports")}
              hidden={!subscriptionsActive}
            >
              {location.pathname.includes("/reports")
                ? DasboardActive
                : Dasboard}
            </NavItem>
          </Link>

          {subscriptionsActive && advancedAnalysis && (
            <Link to={`/advancedAnalytics/dailyFuelAnalytics/${null}`}>
              <NavItem
                active={location.pathname.includes("/advancedAnalytics")}
                label={t("analytics")}
                onClick={() => {
                  if (subscriptionsActive && advancedAnalysis) {
                    dispatch(selectorActions.resetReportState({}));
                  }
                }}
              >
                {location.pathname.includes("/advancedAnalytics") ? (
                  <Insights sx={{ color: "#F07023" }} />
                ) : (
                  <InsightsOutlinedIcon />
                )}
              </NavItem>
            </Link>
          )}

          <Link to="/notifications">
            <NavItem
              className="relative"
              active={location.pathname.includes("/notifications")}
              label={t("notifications")}
              hidden={!subscriptionsActive}
            >
              <Badge badgeContent={unread} color="error">
                {location.pathname.includes("/notifications")
                  ? NotificationsActive
                  : Notifications}
              </Badge>
            </NavItem>
          </Link>

          <Link to="https://docs.orangecells.com/" target="_blank">
            <NavItem label={t("docs")} hidden={!subscriptionsActive}>
              <HelpOutlineIcon />
            </NavItem>
          </Link>

          <Link to="/settings/my_account">
            <NavItem
              active={location.pathname.includes("/settings")}
              label={t("settings")}
              hidden={!subscriptionsActive}
            >
              {location.pathname.includes("/settings")
                ? SettingsActive
                : Settings}
            </NavItem>
          </Link>
        </ul>
      </nav>
    </div>
  );
};

export default LeftMenu;
