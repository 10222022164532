import {Capacitor} from "@capacitor/core";
import Native from "./NativeHTTP";
import Web from "./WebHTTP";

const APIWrapper = {
  "hostName": "traccar.orangecells.com",
  "wsProtocol": "wss",
  "httpProtocol": "https",
  "httpCall": {
    "native": Native,
    "web": Web
  }
};

APIWrapper.HttpModule = () => {
  switch (Capacitor.getPlatform()) {
    case "web":
      return APIWrapper.httpCall.web;
    case "android":
    default:
      return APIWrapper.httpCall.native;
  }
}

export default APIWrapper;
