import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { IPhoneModels } from "../../assets/IPhoneModels";

const TopFixedButtonsSection = ({
  children,
  className = "right-3 top-4",
  position = "fixed",
}) => {
  const platform = useSelector((state) => state.drivers.platform, shallowEqual);
  const modelName = useSelector(
    (state) => state.drivers.modelName,
    shallowEqual
  );

  const styles =
    platform === "ios"
      ? IPhoneModels?.includes(modelName) ||
        modelName?.toLowerCase()?.includes("ipad")
        ? "right-3 top-10"
        : "right-3 top-20"
      : className;
  return (
    <div className={`${position} flex gap-3 z-[2] ${styles}`}>{children}</div>
  );
};

export default TopFixedButtonsSection;
