import React from "react";
import CommonLayout from "../../layout/Components/CommonLayout";

const Sidebar = ({
  children,
  isOpen,
  isMap,
  className = "h-screen",
  position = "fixed",
}) => {
  return (
    <div
      className={`${position} bg-white  shadow-[0px_8.0px_8.0px_rgba(0,0,0,0.38)] ${
        isOpen ? "sidebarActive" : "sidebarCollapsed"
      } ${className} duration-[1s] `}
    >
      <CommonLayout isMap={isMap}>{children}</CommonLayout>
    </div>
  );
};

export default Sidebar;
