import { Alert } from "@mui/lab";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import APIWrapper from "../APIWrapper/APIWrapper";
import LinearProgress from "../common/components/LinearProgress";
import {
  devicesActions,
  geofencesActions,
  groupsActions,
  positionsActions,
  selectorActions,
  sessionActions,
  subscriptionActions,
} from "../store";
import PageLayout from "./Components/PageLayout";

function Layout({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authenticated = useSelector((state) => !!state.session.user);

  const subscriptionsActive = true; // TODO: use below selector when payment gateway issues are resolved.
  //   useSelector(
  //   (state) => state.subscriptions.active
  // );
  const user = useSelector((state) => state.session.user);

  const logout = async () => {
    if (authenticated) {
      dispatch(sessionActions.updateUser(null));
      dispatch(positionsActions.reset([]));
      dispatch(devicesActions.reset([]));
      dispatch(groupsActions.reset([]));
      dispatch(geofencesActions.reset([]));
      dispatch(selectorActions.reset({}));
      dispatch(subscriptionActions.reset({}));
      window.localStorage.removeItem("rangr_token");
      window.localStorage.removeItem("mapTypeId", false);
      window.localStorage.clear();

      await APIWrapper.HttpModule().delete("/api/session");
      await APIWrapper.HttpModule().WebsocketClose();
    }

    navigate("/login");
  };

  const CurrentLayout = () => {
    if (authenticated === false) {
      return <div>{children}</div>;
    } else if (
      authenticated === true &&
      (subscriptionsActive === undefined || subscriptionsActive === null)
    ) {
      return (
        <div>
          <LinearProgress />
        </div>
      );
    } else if (authenticated === true && subscriptionsActive === true) {
      return (
        <Fragment>
          <PageLayout>{children}</PageLayout>
        </Fragment>
      );
    } else if (
      authenticated === true &&
      (subscriptionsActive === false ||
        subscriptionsActive === undefined ||
        subscriptionsActive === null) &&
      (user.attributes?.customerOwner === true ||
        user.attributes?.customerOwner === "true")
    ) {
      return (
        <div>
          <Alert variant="filled" severity="error" onClose={logout}>
            Your account does not have an active Rangr subscription. Please
            purchase or activate subscription to log into your account.
          </Alert>
          <div>{children}</div>
        </div>
      );
    } else if (
      authenticated === true &&
      subscriptionsActive === false &&
      (!user.attributes ||
        !user.attributes?.customerOwner ||
        user.attributes?.customerOwner === false ||
        user.attributes?.customerOwner === "false")
    ) {
      return (
        <div>
          <Alert variant="filled" severity="error" onClose={logout}>
            Your account does not have an active Rangr subscription. Please
            contact your account owner or{" "}
            <u>
              <a target="_blank" href="https://orangecells.com/#contact-us">
                Rangr team
              </a>
            </u>{" "}
            to activate your account.
          </Alert>
          <div>{children}</div>
        </div>
      );
    }

    // Catch all case.
    return <div>{children}</div>;
  };

  return <Fragment>{CurrentLayout()}</Fragment>;
}

export default Layout;
