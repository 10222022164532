import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const { reducer, actions } = createSlice({
  name: "events",
  initialState: {
    selectedEvent: { id: null, isOpen: false },
    readId: {},
    items: {},
    startDate: moment().subtract(1, "days").startOf("day").toString(),
    endDate: moment().subtract(1, "days").endOf("day").toString(),
    filterItem: "all",
    isLastFill: false,
    selectedEventId: null,
  },
  reducers: {
    reset(state, action) {
      state.items = {};
      state.readId = {};
    },
    add(state, action) {
      if (!Array.isArray(action.payload)) {
        return;
      }

      action.payload?.forEach((item) => {
        if (item.id > 0) {
          state.items[item.id] = item;
        } else {
          const id =
            item.deviceId + "_" + item.serverTime + "_" + item.positionId;
          const itemToAdd = {};
          for (let prop in item) {
            itemToAdd[prop] = item[prop];
          }
          itemToAdd.id = id;
          state.items[id] = itemToAdd;
        }
      });
    },
    select(state, action) {
      state.selectedEvent.isOpen = action.payload?.isOpen;
      state.selectedEvent.id = action.payload?.id;

      if (action.payload?.isReadCount) {
        if (state.readId[action.payload.id] === undefined) {
          state.readId[action.payload.id] = true;
        }
      }
    },
    delete(state, action) {
      delete state.items[action.payload];
      if (state.readId[action.payload] !== undefined) {
        delete state.readId[action.payload];
      }
    },
    deleteAll(state) {
      state.items = {};
      state.readId = {};
    },
    selectStartDate(state, action) {
      state.startDate = action.payload;
    },

    selectEndDate(state, action) {
      state.endDate = action.payload;
    },
    selectIsLastFill(state, action) {
      state.isLastFill = action.payload;
    },
    selectFilterItem(state, action) {
      state.filterItem = action.payload;
    },
    selectEventId(state, action) {
      if (action.payload) {
        state.selectedEventId = action.payload;
      }
    },
  },
});

export { actions as eventsActions };
export { reducer as eventsReducer };
