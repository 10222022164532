import AltRouteIcon from "@mui/icons-material/AltRoute";
import EventNoteIcon from "@mui/icons-material/EventNote";
import InfoIcon from "@mui/icons-material/Info";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import React from "react";
import { t } from "i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { devicesActions, positionsActions } from "../../../../store";
import FeatureFlags from "../../../../Components/FeatureFlags/FeatureFlags";

const LinksSection = ({ id }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.session.user, shallowEqual);
  const subscriptions = useSelector(
    (state) => state.subscriptions.items,
    shallowEqual
  );
  const plans = useSelector((state) => state.subscriptions.plans, shallowEqual);

  let hasFuel = user && user.administrator === true;

  if (!hasFuel) {
    Object.keys(subscriptions).forEach((subsId) => {
      if (!hasFuel) {
        hasFuel = FeatureFlags.includes(
          "fuel_events",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }
    });
  }
  return (
    <div className="flex justify-between font-medium items-center step-13">
      {hasFuel && (
        <Link to={`/reports/basicFuel/${id}`}>
          <span
            title={t("linkToFuelReportPage")}
            className="hover:text-[#F07023] flex items-center"
            onClick={() => {
              dispatch(devicesActions.select({ deviceId: id }));
              dispatch(devicesActions.selectMultipleIds(id));
            }}
          >
            <LocalGasStationIcon className="text-[16px]" />
            <span>{t("fuel")}</span>
          </span>
        </Link>
      )}
      <Link to={`/reports/events/${id}`}>
        <span
          title={t("linkToEventsPage")}
          className="hover:text-[#F07023] flex items-center"
        >
          <EventNoteIcon className="text-[16px]" />
          <span> {t("events")}</span>
        </span>
      </Link>
      <Link to={`/reports/info/${id}`}>
        <span
          title={t("linkToInfoPage")}
          className="hover:text-[#F07023] flex items-center"
        >
          <InfoIcon className="text-[16px]" />
          <span> {t("info")}</span>
        </span>
      </Link>
      <Link
        to={`/reports/tracking_live/${id}`}
        onClick={() => dispatch(positionsActions.setLiveRoutePath([]))}
      >
        <span
          title={t("linkToTrackingPage")}
          className="hover:text-[#F07023] flex items-center"
        >
          <AltRouteIcon className="text-[16px]" />
          <span>{t("tracking")}</span>
        </span>
      </Link>
    </div>
  );
};

export default LinksSection;
