import { Button } from "@mui/material";
import { orange } from "@mui/material/colors";
import { styled } from "@mui/styles";
import React from "react";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(orange[500]),
  backgroundColor: orange[500],
  padding: "2px 4px 2px 4px",
  minWidth: "20px",

  "&:hover": {
    backgroundColor: "#F07023",
    color: "white",
  },
}));

const ButtonMUI = ({
  children,
  fullWidth,
  type,
  onClick,
  disabled,
  size = "large",
  className,
  id,
}) => {
  return (
    <ColorButton
      id={id}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
      type={type}
      size={size}
      variant="contained"
    >
      <span className={`${className} h-full w-full`}>{children}</span>
    </ColorButton>
  );
};

export default ButtonMUI;
