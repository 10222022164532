export const IPhoneModels = [
  "iPhone",

  "iPhone 3G",

  "iPhone 3GS",

  "iPhone 4",

  "iPhone 4S",

  "iPhone 5",

  "iPhone 5c",

  "iPhone 5s",

  "iPhone SE (1st generation)",
  
  "iPhone SE (2nd generation)",
  
  "iPhone SE (3rd generation)",

  "iPhone 6",

  "iPhone 6 Plus",

  "iPhone 6s",

  "iPhone 6s Plus",

  "iPhone 7",

  "iPhone 7 Plus",

  "iPhone 8",

  "iPhone 8 Plus",
];
