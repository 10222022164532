import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import APIWrapper from "../../../../APIWrapper/APIWrapper";
import { Before5Mins, TimeConverter } from "../../../../common/util/time";
import { filterActions } from "../../../../store";
import { endDate, startDate } from "../../../Reports/components/Formatters";

const FilteredDevices = () => {
  const dispatch = useDispatch();

  const devices = useSelector((state) => state.devices.items, shallowEqual);
  const positions = useSelector((state) => state.positions.items, shallowEqual);
  const status = useSelector((state) => state.filters.status, shallowEqual);
  const data = useSelector((state) => state.selectors.items, shallowEqual);
  
  const fleetSummaryTypeOption = useSelector(
    (state) => state.reports.radioValue,
    shallowEqual
  );
  
  const filterKeyword = useSelector(
    (state) => state.filters.filterKeyword,
    shallowEqual
  );
  const filterKey = useSelector(
    (state) => state.filters.filterKey,
    shallowEqual
  );
  const filterGroups = useSelector(
    (state) => state.filters.filterGroups,
    shallowEqual
  );
  const filterActive = useSelector(
    (state) => state.filters.filterActive,
    shallowEqual
  );
  const loadfilter = useSelector(
    (state) => state.filters.loadfilter,
    shallowEqual
  );
  const loadCapacity = useSelector(
    (state) => state.filters.loadCapacity,
    shallowEqual
  );
  const geofencesFilter = useSelector(
    (state) => state.filters.geofencesFilter,
    shallowEqual
  );

  const filterMap = useSelector(
    (state) => state.filters.filterMap,
    shallowEqual
  );
  const selectedLocation = useSelector(
    (state) => state.filters.selectedLocation,
    shallowEqual
  );

  const fromDate = startDate(data?.startDate);
  const toDate = endDate(data?.endDate);

  const getFilteredDevices = () => {
    let statusFiltered = {};

    let filteredDevices = Object.values(devices);

    filteredDevices = filteredDevices.filter((device) => {
      const isOnline =
        status.includes("online") &&
        TimeConverter(positions[device?.id]?.fixTime) >= Before5Mins;
      const isOffline =
        status.includes("offline") &&
        TimeConverter(device?.lastUpdate) <= Before5Mins;
      const isMoving =
        status.includes("moving") &&
        TimeConverter(positions[device?.id]?.fixTime) >= Before5Mins &&
        positions[device?.id]?.attributes.ignition &&
        positions[device?.id]?.speed > 0;
      const isIgnitionOn =
        status.includes("ignitionOn") &&
        TimeConverter(positions[device?.id]?.fixTime) >= Before5Mins &&
        positions[device?.id]?.attributes.ignition &&
        positions[device?.id]?.speed === 0;
      const isIgnitionOff =
        status.includes("ignitionOff") &&
        TimeConverter(positions[device?.id]?.fixTime) >= Before5Mins &&
        TimeConverter(device?.lastUpdate) >= Before5Mins &&
        !positions[device?.id]?.attributes.ignition;

      const documentExpiry =
        status.includes("documentExpiry") &&
        Object.keys(device?.attributes)?.includes("documentsExpiring") &&
        device?.attributes?.documentsExpiring?.length > 0;

      const maintenance =
        status.includes("maintenance") &&
        Object.keys(device?.attributes)?.includes("maintenanceRequired") &&
        device?.attributes?.maintenanceRequired?.length > 0;

      const total = status.includes("total");

      return (
        !status?.length ||
        isOnline ||
        isOffline ||
        isMoving ||
        isIgnitionOn ||
        isIgnitionOff ||
        documentExpiry ||
        maintenance ||
        total
      );
    });

    filteredDevices.forEach((d) => (statusFiltered[d?.id] = d));

    const filtered = Object.values(statusFiltered)
      .filter(
        (device) =>
          !filterGroups.length || filterGroups.includes(device?.groupId)
      )
      .filter(
        (device) =>
          !filterActive.length ||
          filterActive.includes(device?.attributes?.active)
      )
      .filter(
        (device) =>
          !loadfilter.length || loadfilter.includes(device?.attributes?.loaded)
      )
      .filter(
        (device) =>
          !loadCapacity.length ||
          loadCapacity.includes(device?.attributes?.loadCapacity)
      )
      .filter(
        (device) => {
          // We need to preserve the functinality where we allowed "insideGeofence" summary for multiple geofences,
          // where devices can be assigned to any 1 of the selected geofences.
          if (fleetSummaryTypeOption === "tripsBetweenGeofence") {
            return !geofencesFilter.length || geofencesFilter.every((id) => device?.assignedGeofenceIds?.includes(id))
          }
  
          return !geofencesFilter.length || device?.assignedGeofenceIds?.some((id) => geofencesFilter.includes(id))
        }
      )

      .filter((device) => {
        const keyword = filterKeyword.toLowerCase();
        return [device?.registrationNumber].some(
          (s) => s && s.toLowerCase().includes(keyword)
        );
      });

    return filtered;
  };

  const fetchSortedIds = async (fromDate, toDate, eventType, metricName) => {
    const sortedIdsFromAPI = await APIWrapper.HttpModule().get(
      `/api/reports/sortByFuelEventMetric?from=${fromDate}&to=${toDate}&fuelEventType=${eventType}&metricName=${metricName}`
    );

    const mappedResponse = {};
    sortedIdsFromAPI.forEach((deviceEventMetric, index) => {
      const deviceId = deviceEventMetric.deviceId;
      mappedResponse[deviceId] = {
        volume: deviceEventMetric.deviceEventVolume,
        count: deviceEventMetric.eventCount,
        index: index,
      };
    });
    filterList(mappedResponse);
  };

  const filterList = (mappedResponse) => {
    const filtered = getFilteredDevices();

    const isFuelSort =
      filterKey === "fuelFillCount" ||
      "fuelDrainCount" ||
      "fuelFillVolume" ||
      "fuelDrainVolume";

    if (isFuelSort && filtered && Object.keys(mappedResponse).length > 0) {
      filtered?.sort((device1, device2) => {
        const indexD1 = mappedResponse[device1.id]?.index;
        const indexD2 = mappedResponse[device2.id]?.index;

        if (indexD1 == undefined || indexD2 == undefined) {
        }

        if (indexD1 > indexD2) {
          return 1;
        } else if (indexD1 < indexD2) {
          return -1;
        } else {
          // both are equal
          return 0;
        }
      });
    }

    dispatch(filterActions.setMappedResponse(mappedResponse));
    dispatch(filterActions.setFilteredDevices(filtered));
  };

  useEffect(() => {
    const filtered = getFilteredDevices();

    switch (filterKey) {
      case "registrationNumber":
        filtered.sort((device1, device2) =>
          device1?.registrationNumber.localeCompare(device2?.registrationNumber)
        );
        dispatch(filterActions.setFilteredDevices(filtered));
        break;
      case "lastUpdate":
        filtered.sort((device1, device2) => {
          const time1 = device1?.lastUpdate
            ? TimeConverter(device1.lastUpdate)
            : 0;
          const time2 = device2?.lastUpdate
            ? TimeConverter(device2?.lastUpdate)
            : 0;
          return time2 - time1;
        });
        dispatch(filterActions.setFilteredDevices(filtered));
        break;

      case "DistanceFromSelectedLocation":
        let idToDistance = {};

        if (selectedLocation) {
          let sortPositions = {};
          const devicePositions = filtered.map(
            (device) => positions[device?.id]
          );
          for (let id in devicePositions) {
            sortPositions[devicePositions[id]?.deviceId] = devicePositions[id];
          }

          for (let id in devices) {
            let devicePosition = sortPositions[id];

            if (devicePosition === undefined) {
              idToDistance[id] = Number.MAX_VALUE;
            } else {
              let lat = parseFloat(devicePosition?.latitude);
              let long = parseFloat(devicePosition?.longitude);

              let deviceLatLong = new google.maps.LatLng(lat, long);

              idToDistance[id] =
                google.maps.geometry.spherical.computeDistanceBetween(
                  selectedLocation?.length > 0
                    ? selectedLocation[0]
                    : selectedLocation,
                  deviceLatLong
                ); // in meters
            }
          }

          filtered.sort((device1, device2) => {
            let distanceD1 = idToDistance[device1.id];
            let distanceD2 = idToDistance[device2.id];

            if (distanceD1 > distanceD2) {
              return 1;
            } else if (distanceD1 < distanceD2) {
              return -1;
            } else {
              return 0;
            }
          });
        }

        dispatch(filterActions.setFilteredDevices(filtered));
        dispatch(filterActions.setMainDeviceDistance(idToDistance));

        break;
    }

    dispatch(
      filterActions.setFilteredPositions(
        filterMap
          ? filtered.map((device) => positions[device.id]).filter(Boolean)
          : Object.values(positions)
      )
    );
  }, [
    devices,
    positions,
    filterKey,
    filterKeyword,
    selectedLocation,
    filterGroups,
    loadCapacity,
    loadfilter,
    // wheels,
    filterActive,
    filterMap,
    status,
    geofencesFilter,
  ]);

  useEffect(() => {
    switch (filterKey) {
      case "fuelFillCount":
        fetchSortedIds(fromDate, toDate, "fuelFill", "Count");
        break;
      case "fuelDrainCount":
        fetchSortedIds(fromDate, toDate, "fuelDrain", "Count");
        break;
      case "fuelFillVolume":
        fetchSortedIds(fromDate, toDate, "fuelFill", "Volume");
        break;
      case "fuelDrainVolume":
        fetchSortedIds(fromDate, toDate, "fuelDrain", "Volume");
        break;
      default:
        break;
    }
  }, [filterKey]);
};

export default FilteredDevices;
