import React from "react";

const SwitchTogle = ({
  checked,
  onChange,
  readOnly,
  refInfo,
  onClick,
  disabled,
  name,
  testId,
}) => {
  return (
    <div className="flex items-center">
      <label className="inline-flex relative items-center cursor-pointer">
        <input
          data-testid={testId}
          name={name}
          disabled={disabled}
          ref={refInfo}
          onChange={onChange}
          type="checkbox"
          className="sr-only peer"
          checked={checked}
          onClick={onClick}
          readOnly={readOnly}
        />
        <div
          className={`${
            disabled ? "peer-checked:bg-gray-500" : "peer-checked:bg-[#F07023]"
          } w-9 h-5 bg-gray-400 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white  after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all`}
        ></div>
        <span className="ml-2 text-sm font-medium text-gray-900"></span>
      </label>
    </div>
  );
};

export default SwitchTogle;
