import { makeStyles } from "@mui/styles";
import { StyleSheet } from "@react-pdf/renderer";

export const useStyles = makeStyles((theme) => ({
  sidebar: {
    paddingBottom: "10vw",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 555,
    height: "100%",
    width: theme.dimensions?.drawerWidthDesktop,
    bottom: theme.dimensions?.bottomBarHeight,
    transition: "transform 0.5s ease",
    backgroundColor: "white",
    [theme.breakpoints?.down("sm")]: {
      width: "100%",
      zIndex: 555,
      margin: 0,
    },
  },
  sidebarCollapsed: {
    zIndex: 555,
    transform: `translateX(${theme.dimensions?.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints?.down("sm")]: {
      zIndex: 555,
      transform: "translateX(-100vw)",
    },
  },
  filterPanel: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    gap: "8px",
    width: theme.dimensions?.drawerWidthTablet,
  },
}));

export const PdfStyles = StyleSheet.create({
  tabelHeader: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  leftPart: {
    width: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    borderStyle: "solid",
    borderWidth: 1,
    textAlign: "center",
    borderColor: "black",
  },
  rightPart: {
    width: "90%",
  },
  body: {
    padding: "10px",
  },
  header: {
    borderStyle: "solid",
    borderWidth: 1,
    textAlign: "center",
    borderColor: "black",
    fontSize: "14px",
  },
  table: {
    display: "table",
    fontSize: "7px",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    textAlign: "center",
    borderColor: "black",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "black",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    paddingBottom: "5px",
    paddingTop: "5px",
  },
});
