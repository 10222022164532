import { t } from "i18next";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import ButtonMUI from "../../../common/common/ButtonMUI";
import { eventTypes } from "../../../common/dropdownStrings";
import { NumberFormat } from "../../../common/util/formatter";
import { LocalTime } from "../../../common/util/time";

const fuelEventTypes = ["fuelFill", "fuelDrain"];

const DownloadReport = ({
  fullWidth,
  data,
  report,
  devices,
  startDate,
  endDate,
}) => {
  const platform = useSelector((state) => state.drivers.platform, shallowEqual);
  const dateFormat = "DD-MM-YY h:mm a";

  const converters = {
    fileNamePrefix: () => {
      if (!devices) {
        return "";
      }
      return [
        devices.registrationNumber,
        ":",
        t(report),
        ":",
        moment(startDate).format(dateFormat),
        "-",
        moment(endDate).format(dateFormat),
      ].join(" ");
    },
    dataToExport: {
      Events: () => {
        return data?.map((event) => {
          return {
            "Reg Number": devices.registrationNumber
              ? devices.registrationNumber
              : t("na"),
            "Start Time": LocalTime(startDate),
            "End time": LocalTime(endDate),
            "Event time": LocalTime(event?.time),
            Type: eventTypes[event?.type]
              ? eventTypes[event?.type]
              : event?.type,
            "Volume (L)/Duration ": fuelEventTypes.includes(event?.type)
              ? event?.volume?.toFixed(1)
              : event?.duration,
            Location: event?.lat
              ? {
                  v: "Click here",
                  l: {
                    Target:
                      "https://maps.google.com/maps?q=" +
                      [event?.lat, event?.lng].join(","),
                    Tooltip: "Click here to view on map",
                  },
                  s: {
                    color: { rgb: "0563C1" }, // Set the link color
                    underline: true, // Add underline to the link
                  },
                }
              : null,
          };
        });
      },
      // ...

      fleet_summary: () => {
        const fleetSummaryRows = {
          "#": "",
          "Start Time": moment(startDate).format(dateFormat),
          "End Time": moment(endDate).format(dateFormat),
          "Fuel filled(L)": data?.summary?.fuelFilled,
          "Fuel drained(L)": data?.summary?.fuelDrained,
          "Fuel consumed(L)": data?.summary?.fuelConsumed,
          "KMs covered(KMs)": data?.summary?.kmsCovered,
          "Hours run(H)": data?.summary?.hoursRun,
        };

        const individual = data?.fleetSummaryData?.map((row, index) => ({
          "#": index + 1,
          "Start Time": moment(row?.fromDate || startDate).format(dateFormat),
          "End Time": moment(row?.toDate || endDate).format(dateFormat),
          "Reg Number": devices[row?.deviceId].registrationNumber,
          "Fuel filled(L)": row?.fuelFilled,
          "Fuel drained(L)": row?.fuelDrained,
          "Fuel consumed(L)": row?.fuelConsumed,
          "KMs covered(KMs)": row?.kmsCovered,
          "Mileage(KM/L)": row?.mileage,
          "Hours run(H)": row?.hoursRun,
          "Hourly consumption(L/H)": row?.litersPerHour,
        }));
        const fleet_Summary = { "": "Fleet summary" };
        const Breakdown_by_device = { "": "Breakdown per device" };

        return [
          fleet_Summary,
          fleetSummaryRows,
          [],
          Breakdown_by_device,
          ...individual,
        ];
      },

      // ...

      Basic_Summary: () => {
        return [
          {
            "REGISTRATION NUMBER": devices.registrationNumber
              ? devices.registrationNumber
              : t("na"),
            "START TIME": moment(startDate).format(dateFormat),
            "END TIME": moment(endDate).format(dateFormat),
            OPENING: data.openingFuel ? data.openingFuel.toFixed(1) : 0.0,
            FILL: data.fuelFilled ? data.fuelFilled.toFixed(1) : 0.0,
            DRAIN: data.fuelDrained ? data.fuelDrained.toFixed(1) : 0.0,
            CLOSING: data.closingFuel ? data.closingFuel.toFixed(1) : 0.0,
            CONSUMED: data.fuelConsumed ? data.fuelConsumed.toFixed(1) : 0.0,
            KMS: data.kmsCovered ? data.kmsCovered.toFixed(1) : 0.0,
            "KM / LITER": data.kmsPerLiter ? data.kmsPerLiter.toFixed(1) : 0.0,
            HOURS: data.hoursRun ? data.hoursRun.toFixed(1) : 0.0,
            "LITERS / HOUR": data.litersPerHour
              ? data.litersPerHour.toFixed(1)
              : 0.0,
          },
        ];
      },

      Advanced_Summary: () => {
        return data?.map((row) => {
          return {
            "Registration Number": row.registrationNumber,
            "Start Time": row.reportStartTime,
            "End Time": row.reportEndTime,
            "Event Time": row.eventEndTimestamp
              ? LocalTime(row.eventTimestamp)
              : "",
            "Opening Fuel(L)": NumberFormat(row?.openingFuelStock, 2, ""),
            "Event Type": row.type,
            "Event Volume(L)": row?.eventVolume
              ? NumberFormat(row?.eventVolume, 2, "")
              : "",
            "Closing Fuel(L)": NumberFormat(row?.closingFuelStock, 2, ""),
            "Fuel Consumed(L)": NumberFormat(row?.actualFuelConsumed, 2, ""),
            "Kms Covered(KM)": NumberFormat(row?.totalKMS, 2, ""),
            "Mileage(KM/L)": NumberFormat(row?.mileage, 2, ""),
            "Hours Run": NumberFormat(row?.totalHoursRun, 2, ""),
            "Idle Hours": NumberFormat(row?.totalHoursIdle, 2, ""),
            "Liters Per Hour(L/H)": NumberFormat(row?.hourlyConsumption, 2, ""),
          };
        });
      },
    },
  };

  const downloadExcel = () => {
    if (!devices) {
      return;
    }
    const dataForExcel = converters.dataToExport[report]();
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, report);

    const fileName = [converters.fileNamePrefix(), ".xlsx"].join("");
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <>
      {platform === "web" && (
        <ButtonMUI
          fullWidth={fullWidth}
          type="button"
          size="small"
          onClick={() => downloadExcel()}
        >
          {t("downloadExcel")}
        </ButtonMUI>
      )}
    </>
  );
};

export default DownloadReport;
