// i18n.js
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "../../locales/en/translation"; //English translation
import translationHI from "../../locales/hi/translation"; // Hindi translation
import translationTE from "../../locales/te/translation"; // Telugu translation
import translationMR from "../../locales/mr/translation"; // Marathi translation

const resources = {
  en: {
    translation: translationEN,
  },
  hi: {
    translation: translationHI,
  },
  te: {
    translation: translationTE,
  },
  mr: {
    translation: translationMR
  }
};

i18n.use(LanguageDetector).init({
  resources,
  debug: true,
  fallbackLng: "en", // Fallback language if a translation is missing
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
});

export default i18n;
