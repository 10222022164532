import LanguageIcon from "@mui/icons-material/Language";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export const SettingsChildRoutes = [
  {
    pathname: "/settings/my_account",
    icon: <PersonIcon />,
    title: "myAccount",
    show: ["true", true, false, "false"],
    selected: "/settings/my_account",
  },
  {
    pathname: "/settings/subscriptions",
    icon: <ShoppingCartIcon />,
    title: "subscription",
    show: ["true", true],
    selected: "/settings/subscriptions",
  },
  {
    pathname: "/settings/usersAndGroups/groups",
    title: "usersAndGroups",
    icon: <PeopleIcon />,
    show: ["true", true],
    selected: "/settings/usersAndGroups",
  },
  {
    pathname: "/settings/devicesAndSensors/devices",
    title: "devicesAndSensors",
    icon: <GpsFixedIcon />,
    show: ["true", true],
    selected: "/settings/devicesAndSensors",
  },
  
  {
    pathname: "/settings/notificationSettings",
    icon: <NotificationsIcon />,
    title: "notifications",
    show: ["true", true, false, "false"],
    selected: "/settings/notificationSettings",
  },
  {
    pathname: "/settings/languageSettings",
    title: "language",
    icon: <LanguageIcon />,
    show: ["true", true, false, "false"],
    selected: "/settings/languageSettings",
  },
];
