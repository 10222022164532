import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "data",
  initialState: {
    fleetData: [],
    fleetMetricsTotals: {},
    fleetTotalables: [
      "kmsCovered",
      "hoursRun",
      "fuelFilled",
      "fuelDrained",
      "fuelConsumed",
    ],
    fleetSummaryData: [],
    fleetSummaryTotals: {},
    loading: false,
  },
  reducers: {
    clear(state, action) {
      state.fleetData = [];
      state.fleetMetricsTotals = {};
      state.fleetSummaryData = [];
      state.fleetSummaryTotals = {};
      state.loading = false;
    },

    setFleetData(state, action) {
      let data = action.payload;

      if (data && data.length > 0) {
        data.forEach((item) => {
          state.fleetData.push(item);

          Object.keys(item).forEach((key) => {
            if (state.fleetTotalables.includes(key)) {
              if (
                state.fleetMetricsTotals[key] === undefined ||
                state.fleetMetricsTotals[key] === null
              ) {
                state.fleetMetricsTotals[key] = item[key];
              } else {
                state.fleetMetricsTotals[key] += item[key];
              }
            }
          });
        });
      }
    },
    setFleetSummaryData(state, action) {
      let data = action.payload;

      if (data && data.length > 0) {
        data.forEach((item) => {
          state.fleetSummaryData.push(item);

          Object.keys(item).forEach((key) => {
            if (state.fleetTotalables.includes(key)) {
              if (
                state.fleetSummaryTotals[key] === undefined ||
                state.fleetSummaryTotals[key] === null
              ) {
                state.fleetSummaryTotals[key] = item[key];
              } else {
                state.fleetSummaryTotals[key] += item[key];
              }
            }
          });
        });
      }
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export { actions as dataActions };
export { reducer as dataReducer };
