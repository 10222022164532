export const wheels = {
  4: 4,
  6: 6,
  8: 8,
  10: 10,
  12: 12,
  14: 14,
  16: 16,
  18: 18,
  20: 20,
  22: 22,
};
export const loads = {
  select: "select",
  "10MT": "10MT",
  "15MT": "15MT",
  "20MT": "20MT",
  "25MT": "25MT",
  "30MT": "30MT",
  "35MT": "35MT",
  "40MT": "40MT",
  "45MT": "45MT",
  "50MT": "50MT",
  "55MT": "55MT",
  "60MT": "60MT",
};
export const statuses = {
  online: "Online",
  offline: "Offline",
  moving: "Moving",
  ignitionOn: "Ignition on",
  ignitionOff: "Ignition off",
  documentExpiry: "Documents expiring",
  maintenance: "Maintenance required",
};

export const eventTypes = {
  all: "all",
  fuelFill: "fuelFill",
  fuelDrain: "fuelDrain",
  INTERNAL_BATTERY_LOW: "INTERNAL_BATTERY_LOW",
  EXTERNAL_BATTERY_DISCONNECTED: "EXTERNAL_BATTERY_DISCONNECTED",
  FUEL_SENSOR_DISCONNECTED: "FUEL_SENSOR_DISCONNECTED",
  geofenceEnter: "geofenceEnter",
  geofenceExit: "geofenceExit",
  manualfuelFill: "manualfuelFill",
  manualfuelDrain: "manualfuelDrain",
  STOPPED: "STOPPED",
  ignitionOn: "ignitionOn",
  ignitionOff: "ignitionOff",
};

export const featureEventTypes = (hasFuel) => {
  if (hasFuel) {
    return eventTypes;
  }
  
  const filteredTypes = {};
  for (const key in eventTypes) {
    if (!key.toLowerCase().includes('fuel')
      && !key.toLowerCase().includes('sensor')) {
      filteredTypes[key] = eventTypes[key];
    }
  }
  return filteredTypes;
}

export const eventOptions = {
  kmsCovered: "kmsCovered",
  hoursRun: "hoursRun",
  fuelFilled: "fuelFilled",
  fuelDrained: "fuelDrained",
  fuelConsumed: "fuelConsumed",
  litersPerHour: "litersPerHour",
  kmsPerLiter: "kmsPerLiter",
};

export const Units = {
  kmsCovered: "KMs",
  hoursRun: "KMs",
  fuelFilled: "Liters",
  fuelDrained: "Liters",
  fuelConsumed: "Liters",
  litersPerHour: "LitersPerHour",
  kmsPerLiter: "KMsPerLiter",
};

export const StateNames = {
  select: "select",
  NA: "National",
  NE: "Nepal",
  AN: "Andaman and Nicobar Islands",
  AP: "Andhra Pradesh",
  AR: "Arunachal Pradesh",
  AS: "Assam",
  BR: "Bihar",
  CG: "Chandigarh",
  CH: "Chhattisgarh",
  DH: "Dadra and Nagar Haveli",
  DD: "Daman and Diu",
  DL: "Delhi",
  GA: "Goa",
  GJ: "Gujarat",
  HR: "Haryana",
  HP: "Himachal Pradesh",
  JK: "Jammu and Kashmir",
  JH: "Jharkhand",
  KA: "Karnataka",
  KL: "Kerala",
  LD: "Lakshadweep",
  MP: "Madhya Pradesh",
  MH: "Maharashtra",
  MN: "Manipur",
  ML: "Meghalaya",
  MZ: "Mizoram",
  NL: "Nagaland",
  OR: "Odisha",
  PY: "Puducherry",
  PB: "Punjab",
  RJ: "Rajasthan",
  SK: "Sikkim",
  TN: "Tamil Nadu",
  TS: "Telangana",
  TR: "Tripura",
  UP: "Uttar Pradesh",
  UK: "Uttarakhand",
  WB: "West Bengal",
};
