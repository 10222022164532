import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "session",
  initialState: {
    server: null,
    user: null,
    socket: null,
    socketTimeoutID: null,
  },
  reducers: {
    reset(state, action) {
      state.user = null;
      state.socket = null;
      state.socketTimeoutID = null;
    },
    updateServer(state, action) {
      state.server = action.payload;
    },
    updateUser(state, action) {
      state.user = action.payload;
    },
    updateSocket(state, action) {
      state.socket = action.payload;
      if (state.socketTimeoutID) {
        clearTimeout(state.socketTimeoutID);
      }
    },
  },
});

export { actions as sessionActions };
export { reducer as sessionReducer };
